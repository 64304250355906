import { galleryImage } from './../gallery-images-grid/gallery-images-grid.component';
import { Component, Input, OnInit } from '@angular/core';
import { GalleryImagesSource } from 'src/app/library/temporary/gallery-images-source';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gallery-images',
  templateUrl: './gallery-images.component.html',
  styleUrls: ['./gallery-images.component.scss']
})

export class GalleryImagesComponent implements OnInit {

  // @Input() location = "all";
  @Input() location: "jonkershoek" | "garden-route" | "lowveld" | "all" = "all";



  images:galleryImage[];
  allImages:galleryImage[] = 
  [...GalleryImagesSource.jonkersHoek, ...GalleryImagesSource.gardenRoute, ...GalleryImagesSource.lowveld ]

  constructor(private route : ActivatedRoute) {  

  }

  ngOnInit(): void {
    this.route.params.subscribe((p)=>{
      this.activate('all');

      if(p.site){
        this.location = p.site;
        this.getImages();  
      }

    
  })
  }

  public activate(subject : "jonkershoek" | "garden-route" | "lowveld" | "all") {
    this.location = subject;
    this.getImages();
  }

  getImages(){
    this.images = [...this.allImages]
    if(this.location == "jonkershoek") this.images = [...GalleryImagesSource.jonkersHoek]
    if(this.location == "garden-route") this.images = [...GalleryImagesSource.gardenRoute]
    if(this.location == "lowveld") this.images = [...GalleryImagesSource.lowveld]
  }
  
}

