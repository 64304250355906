import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private metaService: Meta, private title: Title, private router : Router) { }

  ngOnInit(): void {
    this.metaData();
  }

  public openLink(link: string, newTab: boolean = true) {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      this.router.navigateByUrl(link);
    }
  }

  private metaData() {
    this.metaService.updateTag({ name: 'title', 'content': `MyMTO About Us` }, "name='title'");
    this.metaService.updateTag({
      name: 'description', 'content': `MTO strives to be the leader in sustainable, world class adventure
    destinations whilst conserving the environment and uplifting the local community.`}, "name='description'")

    //facebook
    this.metaService.updateTag({ name: 'og:url', 'content': `https://dev.mtotrails.com/about` }, "property='og:url'");
    this.metaService.updateTag({ name: 'og:title', 'content': `MyMTO About Us` }, "property='og:title'");
    this.metaService.updateTag({ name: 'og:description', 'content': `MTO strives to be the leader in sustainable, world class adventure
    destinations whilst conserving the environment and uplifting the local community.` }, "property='og:description'");
    this.metaService.updateTag({ name: 'og:image', 'content': `https://dev.mtotrails.com/assets/images/about/1.jpg` }, "property='og:description'");

    //twitter
    this.metaService.updateTag({ name: 'twitter:url', 'content': `https://dev.mtotrails.com/about` }, "property='twitter:url'");
    this.metaService.updateTag({ name: 'twitter:title', 'content': `MyMTO About Us` }, "property='twitter:title'");
    this.metaService.updateTag({ name: 'twitter:description', 'content': `MTO strives to be the leader in sustainable, world class adventure
    destinations whilst conserving the environment and uplifting the local community.` }, "property='twitter:description'");
    this.metaService.updateTag({ name: 'twitter:image', 'content': `https://dev.mtotrails.com/assets/images/about/1.jpg` }, "property='twitter:description'");
  }



}
