import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Wordpress } from 'src/app/domains/content/wordpress';
import { News } from 'src/app/library/interfaces/resources/news/news';

@Component({
  selector: 'news-article-card',
  templateUrl: './news-article-card.component.html',
  styleUrls: ['./news-article-card.component.scss']
})
export class NewsArticleCardComponent {

  @Input() public article: Wordpress;


}
