<div *ngIf="{activities: activities$ | async} as templateObs">
    <div class="container">
        <div class="content-contain">
            <div class="sidebar">
                <mat-accordion style="min-width: 200px;">
                    <!-- 6 Day Hiking Trails -->
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="cat-title">
                                6 Day
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-panel-description>
                            <mat-list class="sidebar-list">
                                <mat-list-item class="buy-button-bottom" mat-button (click)="selectActivity(activity)" [ngClass]="{'active': selectedActivity?.id === activity.id }" *ngFor="let activity of sixDays">
                                    {{activity.name}} </mat-list-item>
                            </mat-list>
                        </mat-panel-description>
                    </mat-expansion-panel>


                </mat-accordion>
            </div>
            <div class="content-detail">
                <div class="content-details-custom">

                    <div class="top-info-bar">
                        <div class="info-bar-item">
                            <img src="assets/images/icons/flag.svg" alt="" width="18" />
                            <h4>
                                Distance
                            </h4>
                            <h5><strong>{{selectedActivity?.distance}} km</strong></h5>
                        </div>
                        <div class="info-bar-item">
                            <img src="assets/images/clock.svg" alt="" width="22" />
                            <h4>
                                Duration
                            </h4>
                            <h5><strong>{{selectedActivity?.days}} day(s)</strong></h5>
                        </div>
                    </div>
                </div>
                <div class="content-detail-wrap">
                    <div class="content-detail-body">
                        <div class="content-tags">
                            <div class="tag">
                                <img src="{{selectedActivity?.activityType.iconUrl}}" alt="" width="33" />
                                <span class="tag-name">{{selectedActivity?.activityType.name}}</span>
                            </div>
                            <div class="tag">
                                <img src="{{selectedActivity?.difficulty.iconUrl}}" alt="" width="33" />
                                <span class="tag-name">{{selectedActivity?.difficulty.name}}</span>
                            </div>
                        </div>
                        <h2>{{selectedActivity?.name}}</h2>
                        <p>
                            {{selectedActivity?.description | stripHtml}}
                        </p>
                        <p *ngIf="selectedActivity?.requirements"><strong>Requirements</strong></p>
                        <div innerHtml="{{selectedActivity?.requirements}}">
                        </div>
                        <div class="link-to">
                            <a href="">Download full info pack</a>
                        </div>
                    </div>
                </div>
                <div *ngIf="site | async as _site" class="price-holder">
                    <p>{{_site?.pricing}}</p>
                    <button [routerLink]="['/checkout',selectedActivity.id,'booking']" mat-button>Start here</button>
                </div>
            </div>
        </div>
    </div>
</div>