<section>
    <divider [direction]="'down'" [underNavigation]="'yes'"></divider>
</section>
<section class="testimony-section section">
    <hiking-trails-faqs-trails-info></hiking-trails-faqs-trails-info>
</section>
<section class="reservation-details-section section">
    <hiking-trails-faqs-reservation-details></hiking-trails-faqs-reservation-details>
</section>
<!-- <divider [direction]="'down'" [underNavigation]="'no'"></divider>
<section class="faqs-testimony section">
    <hiking-trails-faqs-testimony></hiking-trails-faqs-testimony>
</section> -->