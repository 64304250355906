import { Component, Input, OnInit } from '@angular/core';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { StateService } from 'src/app/state/state.service';

declare const google; 


@Component({
  selector: "hiking-trails-overview",
  templateUrl: "./hiking-trails-overview.component.html",
  styleUrls: ["./hiking-trails-overview.component.scss"],
})
export class HikingTrailsOverviewComponent implements OnInit {
  siteTrail;

  @Input() site;
  localSite;

  tsitsikammaKmls = [
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FBloukrans%202%20Day%20Hike.kml?alt=media&token=87f4f606-4aad-4ab9-97a4-53678e0aa51f',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FBloukrans%203%20Day%20Hike.kml?alt=media&token=6c2d5c49-5186-4132-b0c4-f142ed3bee13',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FBloukrans%204%20Day%20Hike.kml?alt=media&token=631430a5-79e7-44cc-827d-a797d29f53d6',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FBloukrans%205%20Day%20Hike.kml?alt=media&token=3afa2065-181a-4c22-8783-cb9fc8ec1ecb',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FHeuningbos%202%20Day%20Hike.kml?alt=media&token=0ef24feb-c256-4b45-8b19-cfca6d2bbac1',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FHeuningbos%203%20Day%20Hike.kml?alt=media&token=75324876-7566-4453-a879-f7728e40ba73',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FKalander%202%20Day%20Hike.kml?alt=media&token=63f58892-3d46-4541-902b-a6dabf50793f',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FKalander%203%20Day%20Hike.kml?alt=media&token=fa472124-5b23-47cf-87f2-80aa011a0969',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FKalander%204%20Day%20Hike.kml?alt=media&token=567cb938-e3b3-43f3-8e6e-3bf52f91cf72',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FKalander%205%20Day%20Hike.kml?alt=media&token=badfef94-3830-4f17-b7e2-5f89adc79ecd',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FKeurbos%202%20Day%20Hike.kml?alt=media&token=d72be6f3-264f-4fbd-a5b5-a7e6c8cc7a9e',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FKeurbos%203%20Day%20Hike.kml?alt=media&token=055d96d8-b498-42ab-bd94-e0e7107b7f58',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FKeurbos%204%20Day%20Hike.kml?alt=media&token=3b448d5f-c0ee-4c43-a02d-e6cb2fa88fc3',
    //   'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FSleepkloof%202%20Day%20Hike.kml?alt=media&token=89fc5ac0-65e6-468d-b51d-cf11b92ab239',
    "https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Ftsitsikamma%2FTsitsikamma%206%20Day%20Hike.kml?alt=media&token=1ac1a5e5-4ab6-4d88-9738-d02bdc40b7fe",
  ];

  map;
  showKMLContent: boolean = false;

  constructor(
    private stateService: StateService,
    private ecoService: EcoService
  ) {}

  ngOnInit(): void {
    this.stateService.sites$.subscribe((site) => {
      this.siteTrail = site.filter((site) => site.id == 5)[0];

      console.log(this.siteTrail);
    });

    this.ecoService.hikingTrails$.subscribe((data) => {
      console.log(data);
    });

    this.initMap();
  }

  initMap() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      // center: new google.maps.LatLng(-27.384622, 26.648436),
      zoom: 2,
      mapTypeId: "satellite",
      gestureHandling: "cooperative",
    });

    this.tsitsikammaKmls.forEach((kml) => {
      var kmlLayer = new google.maps.KmlLayer(kml, {
        // suppressInfoWindows: true,
        preserveViewport: false,
        map: this.map,
      });

      kmlLayer.addListener("click", function (event) {
        // alert('kml clicked');
        this.showKMLContent = true;
        var content = event.featureData.infoWindowHtml;
        var testimonial = document.getElementById("capture");
        testimonial.innerHTML = content;
      });
    });
  }

  openLink() {
    let date = new Date();
    window.open(
      "https://mtotrails.activitar.com/services/13664?adults=1&children=0&date=" +
      date.toISOString().substring(0, 10),
      "_self"
    );
  }

  openLink_2() {
    window.open(
      "https://www.mtotrails.com/customize",
      "_self"
    );
  }
}
