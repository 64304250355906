import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Activities } from 'src/app/domains/activities';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { StateService } from 'src/app/state/state.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


import * as moment from 'moment';
import { PackageType } from 'src/app/domains/activities/package-type';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { BookingAvailibility } from 'src/app/domains/activities/booking-availibility';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { EcentricService } from 'src/app/library/services/payments_and_orders/ecentric.service';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { CartService } from 'src/app/library/services/payments_and_orders/cart.service';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';
import { decrement, increment } from 'src/app/library/utilities/forms/form';




@Component({
  selector: 'app-booking-checkout',
  templateUrl: './booking-checkout.component.html',
  styleUrls: ['./booking-checkout.component.scss']
})
export class BookingCheckoutComponent implements OnInit {

  public bookingCheckoutForm: FormGroup;
  public submitted = false;
  public checkoutActivity$: Observable<Activities>;
  public minBookingDate;
  public corePackage: PackageType;
  private _addons = new BehaviorSubject(null as addonItem[]);
  public validSessions: BookingAvailibility[];

  selectedPackage;

  public activityId;
  public dateSelectionDisabled = true;
  public dateSelectionLoading = false;

  public dateFilter = (date: Date): boolean => { return true; }

  //totals
  public _packageTotal = new BehaviorSubject(0 as number);
  public _bookingTotal = new BehaviorSubject(0 as number);

  constructor(public stateService: StateService, private route: ActivatedRoute, public ecoService: EcoService, private formBuilder: FormBuilder,
    private router: Router, public ecentricService: EcentricService,
    public cartService: CartService, private cdRef: ChangeDetectorRef, private alertService: UialertsService) {
    this.activityId = this.route.snapshot.paramMap.get('activityId');
    this.checkoutActivity$ = this.ecoService.getActivity(this.activityId);
  }

  ngOnInit() {
    this.bookingCheckoutForm = this.formBuilder.group({
      selectedDate: new FormControl(null, Validators.required),
      selectedAdventurers: new FormControl(0, [Validators.required, Validators.min(1)]),
    });

    this.setMinDate();
    this.loadDefaultValues();
    this.formChangesListener();
    this.bookingTotalListener();
  }

  async formChangesListener() {
    this.bookingCheckoutForm.get('selectedAdventurers').valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (adventurers: number) => {
      this.packageTotal = this.calculateTotal(adventurers, this.corePackage.baseCost, this.corePackage.extraCost);
      //reset selected date
      this.bookingCheckoutForm.patchValue({
        selectedDate: null
      });
      this.disableDatesAdventurerCount(adventurers);
    });
  }

  locationBack() {
    // let locationStore = this.stateService.locationStore;
    // if (locationStore) {
    //   if (locationStore.previous.includes('account')) {
    //     this.router.navigateByUrl('/eco-tourism');
    //   } else {
    //     this.router.navigate(['/eco-tourism']);
    //   }
    // } else {
    //   this.router.navigate(['/eco-tourism']);
    // }
  }


  private async disableDatesAdventurerCount(adventurers) {
    this.dateSelectionDisabled = true;
    //filter dates based on adventurers
    let validAdventurers = adventurers && adventurers > 0 ? true : false;
    if (validAdventurers) {
      this.dateSelectionLoading = true;
      let startD = moment(this.minBookingDate).format('YYYY-MM-DD');
      let endD = moment(this.minBookingDate).add(1, 'year').format('YYYY-MM-DD');
      await this.ecoService.getAvailibility(this.activityId, adventurers, startD, endD)
        .then(data => {
          this.validSessions = data;
          this.dateFilter = (date: Date): boolean => {
            let receivedD = moment(date).format('YYYY-MM-DD');
            return data.some(e => moment(e.date).format('YYYY-MM-DD') == receivedD);
          };
          this.dateSelectionLoading = false;
          this.dateSelectionDisabled = false;
        });
    } else {
      this.dateSelectionLoading = false;
      this.dateSelectionDisabled = true;
    }
  }

  async loadDefaultValues() {
    this.checkoutActivity$.subscribe(data => {
      if (data && data.packageTypes) {
        this.corePackage = data.packageTypes.find(e => e.core);
        //addons
        let addonData = data.packageTypes.filter(e => !e.core);
        if (addonData) {
          let addonItems = [];
          addonData.forEach(e => {
            addonItems.push({
              adventurerCount: 0,
              baseCost: e.baseCost,
              extraCost: e.extraCost,
              totalCost: 0,
              packageTypeId: e.id,
              packageName: e.name
            })
          });
          this.addons = addonItems;
        }
      }
    });
  }


  async ecentricPayment() {
    this.submitted = true;
    if (this.bookingCheckoutForm.invalid) {
      this.bookingCheckoutForm.markAllAsTouched();
      return;
    }
    this.stateService.user$.subscribe(async user => {
      if (user && user.uid) {
        this.router.navigate(['/cart']).then(async () => {
          this.alertService.openSnackBar({ duration: 5, message: "Adding booking to cart and contacting payment portal...", mode: UIAlertType.info });
          //get session
          let selectedStartDate = moment(this.selectedDate).format('YYYY-MM-DD');
          let validDate = this.validSessions.find(e => moment(e.date).format('YYYY-MM-DD') == selectedStartDate);
          let validSession = validDate.sessions[0];
          //add core package booking
          await this.cartService.addPackageItemToCart({
            startDate: moment(this.selectedDate).format('YYYY-MM-DD'),
            bookingId: null,
            pax: this.selectedAdventurers,
            packageTypeId: this.corePackage.id,
            sessionTypeId: validSession.id,
            adventurerId: null,
            adventurerUid: user.uid
          }).then(bookingId => {
            this.addons$.subscribe(data => {
              let addonPromises = [];
              data.filter(e => e.adventurerCount > 0).forEach(addon => {
                addonPromises.push(
                  this.cartService.addPackageItemToCart({
                    adventurerId: null,
                    adventurerUid: user.uid,
                    sessionTypeId: validSession.id,
                    packageTypeId: addon.packageTypeId,
                    pax: addon.adventurerCount,
                    bookingId: bookingId,
                    startDate: moment(this.selectedDate).format('YYYY-MM-DD')
                  }));
              });

              Promise.all(addonPromises)
                .then(async () => {
                  await this.cartService.loadActiveCart(user.uid);
                  this.ecentricService.openPaymentWindow();
                }).catch(() => {
                  this.alertService.openSnackBar({ duration: 10, message: "Error Adding Booking To Cart, Please Try Again", mode: UIAlertType.error });
                });
            });
          });
        });
      }
    });
  }

  async addBookingToCart() {
    this.submitted = true;
    if (this.bookingCheckoutForm.invalid) {
      this.bookingCheckoutForm.markAllAsTouched();
      return;
    }
    this.stateService.user$.subscribe(async user => {
      if (user && user.uid) {
        //get session
        let selectedStartDate = moment(this.selectedDate).format('YYYY-MM-DD');
        let validDate = this.validSessions.find(e => moment(e.date).format('YYYY-MM-DD') == selectedStartDate);
        let validSession = validDate.sessions[0];
        //add core package booking
        await this.cartService.addPackageItemToCart({
          startDate: moment(this.selectedDate).format('YYYY-MM-DD'),
          bookingId: null,
          pax: this.selectedAdventurers,
          packageTypeId: this.corePackage.id,
          sessionTypeId: validSession.id,
          adventurerId: null,
          adventurerUid: user.uid
        }).then(bookingId => {
          this.addons$.subscribe(data => {
            let addonPromises = [];
            data.filter(e => e.adventurerCount > 0).forEach(addon => {
              addonPromises.push(
                this.cartService.addPackageItemToCart({
                  adventurerId: null,
                  adventurerUid: user.uid,
                  sessionTypeId: validSession.id,
                  packageTypeId: addon.packageTypeId,
                  pax: addon.adventurerCount,
                  bookingId: bookingId,
                  startDate: moment(this.selectedDate).format('YYYY-MM-DD')
                }));
            });

            Promise.all(addonPromises)
              .then(() => {
                this.cartService.loadActiveCart(user.uid);
                this.alertService.openSnackBar({ duration: 5, message: "Successfully Added Booking To Cart", mode: UIAlertType.success});
              }).catch(() => {
                this.alertService.openSnackBar({ duration: 10, message: "Error Adding Booking To Cart, Please Try Again", mode: UIAlertType.error });
              });
          });
        });
        this.router.navigate(['']);
      }
    });
  }


  updateAddonTotal(value: number, addon: addonItem, addons: addonItem[]) {
    addon.totalCost = this.calculateTotal(value, addon.baseCost, addon.extraCost);
    addon.adventurerCount = value;
    this.updateAddon(addon, addons);
  }

  calculateTotal(count: number, baseCost: number, extraCost: number) {
    let total = 0;
    if (count) {
      total = count > 1 ? (baseCost * 1 + (extraCost * (count - 1))) : (baseCost * 1);
    }
    return total;
  }

  bookingTotalListener() {
    combineLatest(
      this.packageTotal$,
      this.addons$
    ).subscribe(([packageTotal, addons]) => {
      let addonTotal = addons && addons.length > 0 ? addons.map(e => e.totalCost).reduce((i, total) => i + total) : 0;
      let bookingTotal = 0;
      packageTotal = packageTotal ? packageTotal : 0;
      this.bookingTotal = addonTotal + packageTotal;
    })
  }

  incrementAddonCount(addon: addonItem, addons: addonItem[]) {
    addon.adventurerCount = increment(addon.adventurerCount, 1);
    addon.totalCost = this.calculateTotal(addon.adventurerCount, addon.baseCost, addon.extraCost);
    this.updateAddon(addon, addons);
  }
  updateAddon(addon: addonItem, addons: addonItem[]) {
    let foundIndex = addons.findIndex(e => e.packageTypeId == addon.packageTypeId);
    if (foundIndex > -1) {
      addons[foundIndex] = addon;
    }
    this.addons = addons;
  }
  decrementAddonCount(addon: addonItem, addons: addonItem[]) {
    addon.adventurerCount = decrement(addon.adventurerCount, 1);
    addon.totalCost = this.calculateTotal(addon.adventurerCount, addon.baseCost, addon.extraCost);
    this.updateAddon(addon, addons);
  }

  setMinDate() {
    let tomorrow = moment().add(1, 'days');
    this.minBookingDate = tomorrow.toDate();
  }

  incrementAdventurers() {
    this.bookingCheckoutForm.patchValue({
      selectedAdventurers: increment(this.selectedAdventurers, 1)
    });
  }
  decrementAdventurers() {
    this.bookingCheckoutForm.patchValue({
      selectedAdventurers: decrement(this.selectedAdventurers, 1)
    });
  }

  hasPortage(addons: addonItem[]) {
    return addons.some(e => e.adventurerCount > 0);
  }

  get bookingCheckoutFormControls() {
    return this.bookingCheckoutForm.controls;
  }

  get packageDisplayDuration$() {

    let temp = new BehaviorSubject(null as { start: Date, end: Date });
    let selectedD = this.selectedDate;
    if (selectedD) {
      this.checkoutActivity$.subscribe(data => {
        if (data) {
          temp.next(
            {
              start: selectedD,
              end: moment(selectedD).add(data.days - 1, 'days').toDate()
            }
          );
        }
      });
    }
    return temp.asObservable();
  }

  get selectedDate() {
    return this.bookingCheckoutForm.get('selectedDate').value;
  }
  get selectedAdventurers(): number {
    return this.bookingCheckoutForm.get('selectedAdventurers').value;
  }

  set addons(addons: addonItem[]) {
    this._addons.next(addons);
  }
  get addons$() {
    return this._addons.asObservable();
  }

  set bookingTotal(val: number) {
    this._bookingTotal.next(val);
  }
  get bookingTotal$() {
    return this._bookingTotal.asObservable();
  }
  set packageTotal(val: number) {
    this._packageTotal.next(val);
  }
  get packageTotal$() {
    return this._packageTotal.asObservable();
  }
}


export interface addonItem {
  packageTypeId: number,
  packageName: string,
  adventurerCount: number,
  baseCost: number,
  extraCost: number,
  totalCost: number
}
