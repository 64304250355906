<div *ngIf="{activities: activities$ | async} as templateObs">
    <div class="container">
        <div class="content-contain">

            <div class="sidebar">

                <mat-accordion style="min-width: 200px;" multi="true">
                    <!-- Mountain Biking Events -->
                    <mat-expansion-panel *ngIf="mountainBiking?.length > 0" expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="cat-title">
                                Mountain Biking
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-panel-description>
                            <mat-list class="sidebar-list">
                                <mat-list-item class="buy-button-bottom" mat-button (click)="selectActivity(activity)" [ngClass]="{'active': selectedActivity?.id === activity.id }" *ngFor="let activity of mountainBiking">
                                    {{activity.name}} </mat-list-item>
                            </mat-list>
                        </mat-panel-description>
                    </mat-expansion-panel>

                    <!-- Hiking and Teail Running Events -->
                    <mat-expansion-panel *ngIf="hiking?.length > 0" expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="cat-title">
                                Hiking
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-panel-description>
                            <mat-list class="sidebar-list">
                                <mat-list-item class="buy-button-bottom" mat-button (click)="selectActivity(activity)" [ngClass]="{'active': selectedActivity?.id === activity.id }" *ngFor="let activity of hiking">
                                    {{activity.name}} </mat-list-item>
                            </mat-list>
                        </mat-panel-description>
                    </mat-expansion-panel>

                    <!-- Hicking and Teail Running Events -->
                    <mat-expansion-panel *ngIf="trailRunning?.length > 0" expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="cat-title">
                                Trail Running
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-panel-description>
                            <mat-list class="sidebar-list">
                                <mat-list-item class="buy-button-bottom" mat-button (click)="selectActivity(activity)" [ngClass]="{'active': selectedActivity?.id === activity.id }" *ngFor="let activity of trailRunning">
                                    {{activity.name}} </mat-list-item>
                            </mat-list>
                        </mat-panel-description>
                    </mat-expansion-panel>

                </mat-accordion>

                <!-- <h2>
                    <img src="/assets/images/icons/parks.svg" width="42"
                        alt="" />Trails<span>{{templateObs?.activities?.length}}
                        Results</span>
                </h2> -->

            </div>

            <div class="content-detail">
                <div class="content-details-custom">
                    <div class="top-info-bar">
                        <div class="info-bar-item">
                            <img src="assets/images/icons/flag.svg" alt="" width="18" />
                            <h4>
                                Distance
                            </h4>
                            <h5><strong>{{selectedActivity?.distance}} km</strong></h5>
                        </div>
                        <div class="info-bar-item">
                            <img src="assets/images/clock.svg" alt="" width="22" />
                            <h4>
                                Duration
                            </h4>
                            <h5><strong>{{selectedActivity?.days}} day(s)</strong></h5>
                        </div>
                    </div>
                </div>
                <div class="content-detail-wrap">
                    <div class="content-detail-body">
                        <div class="content-tags">
                            <div class="tag">
                                <img src="{{selectedActivity?.activityType.iconUrl}}" alt="" width="33" />
                                <span class="tag-name">{{selectedActivity?.activityType.name}}</span>
                            </div>
                            <div class="tag">
                                <img src="{{selectedActivity?.difficulty.iconUrl}}" alt="" width="33" />
                                <span class="tag-name">{{selectedActivity?.difficulty.name}}</span>
                            </div>
                        </div>
                        <h2>{{selectedActivity?.name}}</h2>
                        <p>
                            {{selectedActivity?.description | stripHtml}}
                        </p>
                        <p *ngIf="selectedActivity?.requirements"><strong>Requirements</strong></p>
                        <div style="overflow: hidden;" innerHtml="{{selectedActivity?.requirements}}">
                        </div>
                    </div>
                </div>
                <div *ngIf="site | async as _site" class="price-holder">
                    <p>{{_site?.pricing}}</p>
                    <button [routerLink]="['/checkout',_site.name,_site.id,0,'permit']" mat-button>Purchase
                        Permit</button>
                </div>
            </div>

        </div>
    </div>
</div>