import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'discover-splash',
  templateUrl: './discover-splash.component.html',
  styleUrls: ['./discover-splash.component.scss']
})
export class DiscoverSplashComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public openLink(link: string, newTab: boolean = false){
    if(newTab){
        window.open(link, "_blank");
    }else{
        this.router.navigateByUrl(link);
    }
  }

}
