import { galleryImage } from "src/app/components/pages/gallery/gallery-images-grid/gallery-images-grid.component";

export namespace GalleryImagesSource {
    export const galleryImagePlaceHolder = "../../../assets/images/gallery/gallery-image-placeholder.jpg"
    export const jonkersHoek:galleryImage[] = [
        {Source: "../../../assets/images/gallery/jonkershoek/1.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/2.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/3.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/4.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/5.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/6.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/7.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/8.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/9.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/10.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/11.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/12.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/13.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/14.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/15.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/16.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/17.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/18.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/19.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/20.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/21.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/22.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/23.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/24.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/25.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/26.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/27.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/28.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/29.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/30.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/31.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/32.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/33.jpg", location: 'jonkershoek'},
        {Source: "../../../assets/images/gallery/jonkershoek/34.jpg", location: 'jonkershoek'},
    ]
    export const gardenRoute:galleryImage[] = [
        {Source: "../../../assets/images/gallery/garden-route/1.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/2.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/3.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/4.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/5.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/6.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/7.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/8.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/9.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/10.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/11.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/12.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/13.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/14.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/15.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/16.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/17.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/18.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/19.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/20.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/21.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/22.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/23.jpg", location:'garden-route'},
        {Source: "../../../assets/images/gallery/garden-route/24.jpg", location:'garden-route'}
    ]
    export const lowveld:galleryImage[] = [
        {Source: "../../../assets/images/gallery/lowveld/1.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/2.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/3.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/4.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/5.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/6.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/7.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/8.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/9.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/10.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/11.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/12.jpg", location:'lowveld'},
        {Source: "../../../assets/images/gallery/lowveld/13.jpg", location:'lowveld'}
    ]
}
