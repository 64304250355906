import { Injectable } from "@angular/core";

import * as moment from "moment";
import * as _ from "lodash";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { Activities, ActivityInfo, ActivityDifficulty, ActivityCategory } from 'src/app/domains/activities';
import { Site } from 'src/app/domains/site';
import { loadActivityTypeAsset, loadDifficultyAsset, loadActivityCategoryAsset, loadActivityCategoryDisplayName, loadActivityCategoryCssSelector, loadDifficultyCssSelector, loadActivityTypeCssSelector } from 'src/app/library/utilities/assets';
import { BookingAvailibility } from 'src/app/domains/activities/booking-availibility';
import { Weather } from 'src/app/domains/weather';
import { skip } from 'rxjs/operators';
import { StateService } from "src/app/state/state.service";
import { loadSeoFriendlyString } from "../../../utilities/string-manipulation/seo-friendly";
import { ActivityCategoryType } from "../../../utilities/enums/global-enums";
import { HttpService } from "../../networking/http.service";
import { GlobalConfig } from "../../../configuration/global.config";

@Injectable()
export class EcoService {
  //internal state
  headers: HttpHeaders;

  public activitiesReady: boolean = false;
  public errorState: boolean = false;

  private readonly _trailParks = new BehaviorSubject<Array<Activities>>(new Array<Activities>(0));
  private readonly _events = new BehaviorSubject<Array<Activities>>(new Array<Activities>(0));
  private readonly _accommodation = new BehaviorSubject<Array<Activities>>(new Array<Activities>(0));
  private readonly _hikingTrails = new BehaviorSubject<Array<Activities>>(new Array<Activities>(0));

  private readonly _trailParkSiteList = new BehaviorSubject<Array<any>>(new Array<any>(0));
  private readonly _hikingTrailSiteList = new BehaviorSubject<Array<any>>(new Array<any>(0));


  constructor(
    private http: HttpClient,
    private state: StateService,
    private HttpService: HttpService
  ) {
    this.initialize();
  }

  async initialize() {
    this.loadSites();
    this._initAdventuresData();
  }

  public async getAvailibility(activityId: number, adventurerCount, startDate: string, endDate: string): Promise<BookingAvailibility[]> {
    return this.HttpService.get(`${GlobalConfig.APIS.OLDAPI}eco/booking/availability?activityId=${activityId}&pax=${adventurerCount}&from=${startDate}&to=${endDate}`);
  }

  public async getPermitTypes(siteId): Promise<any> {
    return this.HttpService.getExternal(`https://core.mto.group/eco/sites/${siteId}/permittypes`);
  }

  public async getPermitType(id): Promise<any> {
    return this.HttpService.getExternal(`https://core.mto.group/eco/permits/${id}`);
  }

  public getActivity(id: string): Observable<Activities> {
    let singleActivity = new BehaviorSubject(null);
    this.state.activities$.subscribe(data => {
      singleActivity.next(data.find(e => e.id == id));
    });
    return singleActivity.asObservable();
  }


  public async getSiteWeather(coordinates: { lat: number, long: number }) {
    // this.headers = this.HttpService.getHeaders();
    // const data = await this.http
    //   .get<Weather[]>(this.HttpService.getBaseUrl() + "weather?latitude=" + coordinates.lat + "&longitude=" + coordinates.long, { headers: this.headers })
    //   .toPromise();
    // return data.map(data => { data.icon = `${Config.endpoints.CDN}/eco/weather/${data.iconId}.svg`; return data; });
  }
  public async getSite(siteId) {
    return await this.HttpService.get(`${GlobalConfig.APIS.OLDAPI}eco/sites/` + siteId)
      .then((data: Site) => {
        data.seoFriendlyName = loadSeoFriendlyString(data.name);
        return data;
      });
  }

  public async loadSites(distanceCoords?: any) {
    await this.HttpService.get(`${GlobalConfig.APIS.OLDAPI}eco/sites?distanceInKm=${100000}&latitude=${18.8602}&longitude=${-33.9321}`)   //default coords city set to Stellenbosch
      .then((data: Site[]) => {
        data = data.map(e => {
          e.seoFriendlyName = loadSeoFriendlyString(e.name);
          return e;
        })
        this.categorizeSites(data);
        this.state.sites = data.sort(
          (a, b) => a.distanceFromLocation - b.distanceFromLocation
        );
      });
  }

  private async _initAdventuresData() {
    this.headers = this.HttpService.getHeaders();
    this.organiseActivities();
    forkJoin({
      activityTypesResultObj: this.http.get<any>(this.HttpService.getCoreUrl() + "eco/activitytypes", { headers: this.headers }),
      activityDifficultiesResultObj: this.http.get<any>(this.HttpService.getCoreUrl() + "eco/difficulty", { headers: this.headers }),
      activityCategoriesResultObj: this.http.get<any>(this.HttpService.getCoreUrl() + "eco/activities/categories", { headers: this.headers })
    }).subscribe(async (batch) => {
      this.state.activityCategories = await Promise.all(batch.activityCategoriesResultObj.value.map((aC: ActivityCategory) => {
        aC.displayName = loadActivityCategoryDisplayName(aC.id);
        aC.iconUrl = loadActivityCategoryAsset(aC.id);
        aC.cssSelector = loadActivityCategoryCssSelector(aC.id);
        return aC;
      }));
      this.state.activityTypes = await Promise.all(batch.activityTypesResultObj.value.map((aT: ActivityInfo) => {
        aT.iconUrl = loadActivityTypeAsset(aT.name);
        aT.cssSelector = loadActivityTypeCssSelector(aT.name);
        return aT;
      }));
      this.state.activityDifficulties = await Promise.all(batch.activityDifficultiesResultObj.value.map((aD: ActivityDifficulty) => {
        aD.iconUrl = loadDifficultyAsset(aD.name);
        aD.cssSelector = loadDifficultyCssSelector(aD.name);
        return aD;
      }));
    });
  }

  private async organiseActivities() {

    this.state.activities$
      .pipe(
        skip(1)
      )
      .subscribe((adventures: Activities[]) => {
        if (adventures.length > 0) {
          this._events.next(adventures.filter(
            activity => activity.activityCategory.id == ActivityCategoryType.EVENTS.toString()
          ));
          this._accommodation.next(adventures.filter(
            activity => activity.activityCategory.id == ActivityCategoryType.ACCOMODATION.toString()
          ));
          this._trailParks.next(adventures.filter(
            activity => activity.activityCategory.id == ActivityCategoryType.TRAIL_PARKS.toString()
          ));
          this._hikingTrails.next(adventures.filter(
            activity => activity.activityCategory.id == ActivityCategoryType.HIKING_TRAILS.toString()
          ));


          this.activitiesReady = true;
        }
      });
  }

  private categorizeSites(sites) {
    this.hikingTrails$.subscribe(hTrails => {
      this._hikingTrailSiteList.next(sites.filter(site => {
        if (hTrails.map(e => e.site.id).includes(site.id)) {
          return site;
        }
      }));
    });
    this.trailParks$.subscribe(trailParks => {
      this._trailParkSiteList.next(sites.filter(site => {
        if (trailParks.map(e => e.site.id).includes(site.id)) {
          return site;
        }
      }));
    });
  }


  //GETTERS
  get accommodation$(): Observable<Array<Activities>> {
    return this._accommodation.asObservable();
  }
  get hikingTrails$(): Observable<Array<Activities>> {
    return this._hikingTrails.asObservable();
  }
  get events$(): Observable<Array<Activities>> {
    return this._events.asObservable();
  }
  get trailParks$(): Observable<Array<Activities>> {
    return this._trailParks.asObservable();
  }
  get trailParksSiteList$(): Observable<Array<any>> {
    return this._trailParkSiteList.asObservable();
  }
  get hikingTrailsSiteList$(): Observable<Array<any>> {
    return this._hikingTrailSiteList.asObservable();
  }
}
