import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  @Input()site:any;
  constructor(private _router:Router) { 

  }

  ngOnInit(): void {

  }

  public openLink(link: string, newTab: boolean = false) {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      this._router.navigateByUrl(link);
    }
  }
}
