<div class="register-container container">
    <div class="wrapper">
        <div class="header" [routerLink]="['/']">
            <img src="../../../../../assets/images/logo/logo.svg" />
            <!-- <span>MTO TRAILS</span>A -->
        </div>
        <div class="inner">
            <register-form></register-form>
            <div class="register-wrapper">
                <span>Already have an accoount? </span> <span class="important" [routerLink]="['/account/login']">Login</span>
            </div>
        </div>
    </div>
</div>