import { GalleryImagesSource } from 'src/app/library/temporary/gallery-images-source';
import { Component, OnInit } from '@angular/core';
import { galleryImage } from '../gallery/gallery-images-grid/gallery-images-grid.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { SITES_STATIC } from 'src/app/library/static/sites';

@Component({
  selector: 'cape-nature',
  templateUrl: './cape-nature.html',
  styleUrls: ['./cape-nature.component.scss']
})
export class CapeNatureComponent implements OnInit {

  site; 
  name;

  images:galleryImage[];;
  constructor(public route : ActivatedRoute, private ecoService : EcoService, private router : Router) { }

  ngOnInit(): void {
    this.getSite(this.route.snapshot.paramMap.get('id'));
    this.name = (this.route.snapshot.paramMap.get('site'));
    this.images = ((SITES_STATIC.filter( x => x.id == parseInt(this.route.snapshot.paramMap.get('id'))))[0].gallery).splice(0,6);
  }

  
  private async getSite(id : any) { 
    try {
      this.site = (await this.ecoService.getSite(id));
    } catch (error) {
      
    }
  }

  public openLink(link: string, newTab: boolean = false) {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      this.router.navigateByUrl(link);
    }
  }

}
