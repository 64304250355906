<div class="wrapper">
    <div class="inner container">
        <div class="heading">
            <span class="material-icons">circle</span>
            <p>Accommodation</p>
        </div>
        <h2 *ngIf="accommodation?.length < 1" class="events-text">No accommodation right now.</h2>
        <div class="accommodations container">
            <!-- Location 1 -->
            <div *ngFor="let accomm of accommodation" class="location">
                <div class="location-details" style="background-color: white;">
                    <div class="info">
                        <h1>
                            {{accomm?.name}}
                        </h1>
                        <h4>
                            {{accomm.site.name}}<br>
                        </h4>
                        <div class="des" [innerHtml]="accomm.description"></div>
                        <div [routerLink]="['accommodation',accomm.name,accomm.id]" class="action" style="margin-top:1rem;">
                            <span class="material-icons-outlined">arrow_circle_right</span>
                            <p class="sm-action-text">
                                read more
                            </p>
                            <div class="action-decoration d-right"></div>
                        </div>
                    </div>
                </div>
                <div class="location-img" [style.background]="'url('+accomm.featuredImage+')'">
                </div>
            </div>
        </div>
    </div>
</div>