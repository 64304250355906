<div class="top-contain">
    <div class="container">
        <!-- <a (click)="locationBack()" class="back-link">
            <span class="material-icons">
        keyboard_arrow_left
      </span> Back
        </a> -->
        <h2>
            <mat-icon>shopping_cart</mat-icon>Cart Checkout
        </h2>
    </div>
</div>
<div class="checkout-contain">

    <div class="container">
        <div class="checkout-detail">
            <!-- Permit start -->
            <div class="checkout-wrap">
                <h3>{{site?.name}}</h3>
            </div>
            <hr />
            <form [formGroup]="permitCheckoutForm">
                <div class="checkout-wrap">
                    <div class="field">
                        <label>Permit type</label>
                        <div class="field-contain">
                            <mat-select class="form-control" formControlName="selectedPermit" [ngClass]="selectedPermit">
                                <mat-option [value]="permitType" *ngFor="let permitType of sitePermitsTypes">
                                    {{permitType.name}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="field">
                        <label>Select a valid permit date</label>
                        <div class="field-contain">
                            <div class="">
                                <mat-form-field class="datepicker" appearance="outline">
                                    <mat-label>select date</mat-label>
                                    <input [min]="minPermitDate" placeholder="DD/MM/YYYY" class="form-control date-picker-input" [disabled]="dateDisabled ? 'true' : 'false'" matInput formControlName="selectedDate" [matDatepicker]="picker" />
                                    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <!-- <mat-error *ngIf="submitted && permitCheckoutFormControls.selectedDate.errors">
                      <span *ngIf="permitCheckoutFormControls.selectedDate.errors.required">Selecting a date is required</span>
                    </mat-error> -->
                                    <mat-error *ngIf="submitted && permitCheckoutFormControls.selectedDate.errors">
                                        <span *ngIf="permitCheckoutFormControls.selectedDate.errors.required">Selecting a date is required</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label>Adventurer Count</label>
                        <div class="field-contain">
                            <div class="ctrl">
                                <div (click)="decrementQuantity()" class="ctrl-button ctrl-button-decrement">-</div>
                                <mat-form-field appearance="outline" class="qnty-form-field">
                                    <input matInput NumberOnly="true" class="form-control qnty-Input" formControlName="selectedQuantity" class="" maxlength="30" type="text" />
                                    <mat-error *ngIf="submitted && permitCheckoutFormControls.selectedQuantity.errors">
                                    </mat-error>
                                </mat-form-field>
                                <div (click)="incrementQuantity()" class="ctrl-button ctrl-button-increment">+</div>
                            </div>
                            <div class="ctrl-total">
                                <label>Total</label>
                                <strong>R {{permitTotal | number:'1.2-2'}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="" style="padding: 15px 0;">
                        <small>Description</small>
                        <br>
                        <ul *ngIf="selectedPermit?.description != '' else sorry">{{selectedPermit?.description}}</ul>
                        <ng-template #sorry>
                            <ul>
                                Sorry we are working on a clear discription, please contact us for more information before purchase of permit.
                            </ul>
                        </ng-template>
                    </div>
                </div>
            </form>

        </div>
        <div class="checkout-side">
            <div class="checkout-wrap">
                <h4>Permit Summary</h4>
            </div>
            <hr />
            <div class="checkout-wrap">
                <div class="field">
                    <label>Permit Type</label>
                    <span>{{selectedPermitType.name || '-'}}</span>
                </div>
                <div class="field">
                    <label>Date</label>
                    <span>{{(selectedDate | date: 'dd/MM/yyyy') || '-'}}</span>
                </div>
                <div class="field">
                    <label>Quantity</label>
                    <span>{{selectedQuantity || '-'}}</span>
                </div>
            </div>
            <hr />
            <div class="checkout-wrap">
                <div class="field total">
                    <label>Total</label>
                    <span><strong>R {{permitTotal | number:'1.2-2'}}</strong></span>
                </div>

                <div class="cart-btns">
                    <button type="button" (click)="addPermitToCart()" mat-button class="btn-cart">
            Add to Cart
            <mat-icon>shopping_cart</mat-icon>
          </button>
                    <button (click)="ecentricPayment()" mat-button class="btn-payment">
            Proceed to Payment
          </button>
                </div>
                <div class="terms">
                    By continuing you agree and accept the
                    <a [routerLink]="['/terms-of-service']">Terms and Conditions</a> of this purchase.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="checkout-note">
        Your permit will be linked to your account. Gain access at trail gate by printing your access code or displaying it via the myMTO app.
    </div>
</div>