import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef, ChangeDetectionStrategy, NgZone, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/library/services/authentication/authentication.service';
import { Observable, of, combineLatest } from 'rxjs';
import { Site } from 'src/app/library/interfaces/resources/sites/site';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { ProductType, UIAlertType } from 'src/app/library/utilities/enums/global-enums';
import { Router, NavigationEnd } from '@angular/router';

import { Order } from 'src/app/library/interfaces/order-payments/order/order';
import { StateService } from 'src/app/state/state.service';
import { SITES_STATIC } from 'src/app/library/static/sites';
import { CartService } from 'src/app/library/services/payments_and_orders/cart.service';


@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterViewInit {

  @ViewChild("navBar") toolbarElement: ElementRef;

  public sticky: boolean;
  public stickyToolbar: boolean = false;
  public toolbarPosition: any;
  public locationString$: Observable<string>;

  sites : any = SITES_STATIC;

  cart : any;

  constructor(private authService: AuthenticationService,
    public stateService : StateService,

    private cartService : CartService,
    private alertService: UialertsService, 
    private router: Router) {
  }

  async ngOnInit() {
    this.stateService.cart$
    .subscribe((cart)=>{
      this.cart = cart;
    });

    this.listenForChanges();
  }

  public async ngAfterViewInit() {
    this.toolbarPosition = this.toolbarElement.nativeElement.offsetHeight;
  }

  private listenForChanges() : void { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let element = (<HTMLInputElement>document.getElementById('drop-down-cbox'));
        if (element != null) {
          element.checked = false;
        }
      }
    });
  }
  public async logout() {
    this.authService.logout()
      .then((success) => {
        success ? this.alertService.openSnackBar({ duration: 10, message: 'Successfully Logged Out', mode: UIAlertType.success }) : this.alertService.openSnackBar({ duration: 10, message: 'Error Logging Out, Please Try Again', mode: UIAlertType.error });;
      });
  }

  public orderSize(order: Order) {
    return order ? order.orderItems.filter(e => e.product.productType != ProductType.Addon).length : 0;
  }
  
  @HostListener("window:scroll", ["$event"])
  private handleScroll() {
    this.determineSticky();
  }

  private determineSticky() {
    const windowScroll = window.pageYOffset;
    let toolbar = <HTMLElement>document.querySelector(".nav-bar");
    if(toolbar){
      if (windowScroll >= this.toolbarPosition) {
        this.stickyToolbar = true;
        toolbar.classList.add("sticky-toolbar");
      } else {
        this.stickyToolbar = false;
        toolbar.classList.remove("sticky-toolbar");
      }
    }
  }

}
