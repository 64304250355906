import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { StateService } from 'src/app/state/state.service';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';
import { AccountService } from 'src/app/library/services/account/account.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../account.component.scss']
})
export class ProfileComponent implements OnInit {

  public profileForm    : FormGroup;
  public submittingForm : boolean;

  constructor(
    private formBuilder    : FormBuilder, 
    public stateService   : StateService,
    private account        : AccountService,
    private alertService   : UialertsService ) { }

  async ngOnInit() {
      this.createForm()
  }

  private createForm() : void { 
    this.stateService.user$.subscribe((user)=>{
      if(user) {
        this.profileForm = this.formBuilder.group({
          fullName                : new FormControl(user.fullName, Validators.required),
          email                   : new FormControl({value : user.email, disabled : true}, [Validators.required, Validators.email]),
          emergencyContactNo      : new FormControl(user.emergencyContactNo, [Validators.required, Validators.minLength(8)]),
          contactNo               : new FormControl(user.contactNo, [Validators.required, Validators.minLength(8)]),
          rsaId                   : new FormControl(user.rsaId,[Validators.min(6)]),
          id                      : new FormControl(user.id),
          uid                     : new FormControl(user.uid)
        });
      }
    });
  }

  public async profileFormSubmit() {
    try {
      this.submittingForm = true;
      await this.account.updateUser(this.profileForm.getRawValue());
      this.alertService.openSnackBar({duration : 3,message: 'Profile updated', mode : UIAlertType.success});
    } catch (error) {
      this.alertService.openSnackBar({duration : 3,message: error.message, mode : UIAlertType.error});
    }

    this.submittingForm = false;
    this.profileForm.markAsPristine();
  }


}
