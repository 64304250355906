import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'merchandise-splash',
  templateUrl: './merchandise-splash.component.html',
  styleUrls: ['./merchandise-splash.component.scss']
})
export class MerchandiseSplashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
