<div class="wrapper">
  <div class="inner container">
    <div class="main-article">
      <news-article [article]="mainArticle" ></news-article>
    </div>
    <div class="other-articles ">
      <div class="filter">
        <span class="material-icons">
          filter_list
        </span>
        <mat-select placeholder="Sort By" panelClass="filter-select-dropdown" (selectionChange)="filterChange($event)" name="filter">
          <mat-option *ngFor="let filter of filteritem" [value]="filter.value">
            {{ filter.viewValue }}
          </mat-option>
        </mat-select>
      </div>
      <div class="articles" *ngFor="let article of otherArticles">
        <news-article-card [article]="article" [routerLink]="['/news', article.slug]"></news-article-card>
      </div>
    </div>
  </div>
</div>


<!-- <ng-template #loader>
  <loader></loader>
</ng-template> -->