import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/state/state.service';
import { Order } from 'src/app/domains/cart/order';
import { EcentricService } from 'src/app/library/services/payments_and_orders/ecentric.service';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { CartService } from 'src/app/library/services/payments_and_orders/cart.service';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  constructor(public stateService: StateService, public cartService: CartService, private alertService: UialertsService, private ecentricService: EcentricService) { }

  ngOnInit() {
  }

  removeFromCart(orderDetailsId: number, cart: Order) {
    if (orderDetailsId) {
      this.cartService.removeFromCart(orderDetailsId, cart)
      .then(() => {
        this.alertService.openSnackBar({ duration: 5, message: "Successfully Removed Item From Cart", mode: UIAlertType.success })
      }).catch((error) => {
        this.alertService.openSnackBar({ duration: 10, message: "Error Removing Item From Cart", mode: UIAlertType.error })
      })
    }
  }

  eccentricPayment(){
      this.ecentricService.openPaymentWindow();
  }

}
