import { GalleryImagesSource } from 'src/app/library/temporary/gallery-images-source';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gallery-images-grid',
  templateUrl: './gallery-images-grid.component.html',
  styleUrls: ['./gallery-images-grid.component.scss']
})
export class GalleryImagesGridComponent implements OnInit {
 
  @Input() images:galleryImage[]
  placeholderImgScr = GalleryImagesSource.galleryImagePlaceHolder;

  constructor() { }

  ngOnInit(): void {
    
  }

}

export interface galleryImage{
  Source:string;
  location: 'jonkershoek' | 'garden-route' | 'lowveld'
}

