<div class="container">
    <div class="left-section" [ngStyle]="{'background-image': 'url(' + selectedDay.imageSource + ')'}" ></div>
    <div class="right-section">
        <p>Beginning in Natures Valley, and ending 62.3km further at either the Storms River Bridge or Village, this six-day unidirectional trail takes hikers through the heart of the Tsitsikamma Mountains. </p>
        <p>Shorter variations (2, 3, 4 or 5 days) are possible as each of the five overnight huts have their own unique access route with safe parking at the start.</p>
        <br>
        <br>
        <h4 class="mat-title"><strong>TRAILS</strong></h4>


        <mat-tab-group animationDuration="100ms" mat-align-tabs="start" (selectedTabChange)="dayChanged($event)">
            <mat-tab *ngFor="let day of days" label="DAY {{day.day}}">
                <div class="tab-content">
                    <h4 class="mat-title"><strong>{{day.name}}</strong></h4>
                    <div>
                        <div class="info-card">
                            <div class="small-info">
                                <img class="tile-icon" src="../../../../../assets/images/icons/new/Distance.svg">
                                <div class="info">
                                    <p><strong>Distance</strong></p>
                                    <div class="value">{{day.distance}} Km</div>
                                </div>
                            </div>
                            <div class="small-info end-info">
                                <img class="tile-icon" src="../../../../../assets/images/icons/new/Duration.svg">
                                <div class="info">
                                    <p><strong>Duration</strong></p>
                                    <div class="value">Approx {{day.time}} Hrs</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><strong>DAY'S HIGHLIGHTS</strong></p>
                    <ul class="highlights">
                        <li *ngFor="let highlight of day.highlights">{{highlight}}</li>
                    </ul>
                    <p>
                        {{day.description}}
                    </p>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>