<div class="wrapper">
    <div class="inner container">
        <h1 class="heading">Trail Info</h1>
        <p class="par">
            A southern Cape hiking paradise in a pristine environment and possibly the world’s first officially accredited hiking trail.
        </p>
        <div class="info-blocks">
            <!-- Hickers Checklist-->
            <div class="info-block">
                <div class="content">
                    <h1 class="heading">
                        What equipment to pack 
                    </h1>
                    <ul class="list">
                        <li>
                            A Multi day hike requires a lot planning and preparation. 
                            MTO Trails provides that basic hiking essentials for cooking and overnight beds, but the rest is up to you. 
                            Here is a list to help you plan your adventure. 
                        </li>
                    </ul>
                </div>
                <div class="action" (click)="openLink('https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/website%3A%20www.mtotrails.com%2Fgeneral%2FChecklist.pdf?alt=media&token=569a8114-7c89-4402-be52-71c486f4fa33')">
                    <span class="material-icons-outlined">arrow_circle_right</span>
                    <p>download full information</p>
                </div>
            </div>
            <!-- Porterage Procedures -->
            <div class="info-block">
                <div class="content">
                    <h1 class="heading">
                        Water crossings and safety tips
                    </h1>
                    <ul class="list">
                        <li>
                            The Tsitsikamma Hiking trail heads through very remote parts of the Tsitsikamma Mountains with very little to no cellphone reception. 
                            Traversing through the wilderness and crossing a multitude of rivers which may be disrupted due to heavy rainfall there are some risk associated with the hike. 
                            Here is a long list of things to  look  out for when crossing rivers or when encountered with emergencies. 
                        </li>
                    </ul>
                </div>
                <div class="action" (click)="openLink('https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/website%3A%20www.mtotrails.com%2Fgeneral%2FPLEASE%20READ%20-%20IMPORTANT%20SAFETY%20ADVICE%20TO%20ALL%20HIKERS.pdf?alt=media&token=c49e11a2-1391-460d-9271-20adc74cb005')">
                    <span class="material-icons-outlined">arrow_circle_right</span>
                    <p>download full information</p>
                </div>
            </div>
            <!-- Portage Details -->
            <div  class="info-block">
                <div class="content">
                    <h1 class="heading">
                        Portage Details 
                    </h1>
                    <ul class="list">
                        <li>
                            Slack packing or equipment portage was introduced to assist certain hiker 
                            groups with the carrying of equipment between overnight huts, and therefore
                            make the Tsitsikamma Trail more suitable to a wider variety or hikers. 
                            The storage of perishables in fridges and freezers is a luxury which allows groups
                            to bring along items not normally going in the backpacks on an overnight trail.
                            variety of persons.
                        </li>
                    </ul>
                </div>
                <div class="action" (click)="openLink('https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/website%3A%20www.mtotrails.com%2Fgeneral%2FPortage.pdf?alt=media&token=dc19a79a-82f6-4803-8261-ae77549159f0')">
                    <span class="material-icons-outlined">arrow_circle_right</span>
                    <p>download full information</p>
                </div>
            </div>
            <!-- Shuttle Information -->
            <div class="info-block">
                <div class="content">
                    <h1 class="heading">
                        Shuttle Information 
                    </h1>
                    <ul class="list">
                        <li>
                            The Tsitsikamma Hiking trail is a unidirectional trail so getting back to your car is important. 
                            Here is a list of recommended shuttle services who can assist your on your return. 
                        </li>
                    </ul>
                </div>
                <div class="action" (click)="openLink('https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/website%3A%20www.mtotrails.com%2Fgeneral%2FShuttle%20Numbers.pdf?alt=media&token=e9a64974-251b-488c-bdef-2b370845ba8a')" >
                    <span class="material-icons-outlined">arrow_circle_right</span>
                    <p>download full information</p>
                </div>
            </div>
            <!-- Maps and GPX files -->
            <div  class="info-block">
                <div class="content">
                    <h1 class="heading">
                        Maps and GPX files 
                    </h1>
                    <ul class="list">
                        <li>
                            The trail is well mark form start to finish, but is always better having a map. 
                            Download our map to keep with or to assist in planning your adventure.
                        </li>
                    </ul>
                </div>
                <div class="action" (click)="openLink('https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/website%3A%20www.mtotrails.com%2FMaps%2FTHT%20Map%5B2305843009242584295%5D.jpg?alt=media&token=7b68304b-e599-4154-ac4c-b99917949868')">
                    <span class="material-icons-outlined">arrow_circle_right</span>
                    <p>download full information</p>
                </div>
            </div>
            <!-- Frequently asked questions -->
            <div class="info-block">
                <div class="content">
                    <h1 class="heading">
                        Frequently asked questions 
                    </h1>
                    <ul class="list">
                        <li>
                            Here we assist you in understanding the trail a little better. 
                            If you do not find the answers you were looking for please contact us.
                        </li>
                    </ul>
                </div>
                <div class="action" (click)="openLink('https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/website%3A%20www.mtotrails.com%2Fgeneral%2FFrequently%20ask%20%20Questions.pdf?alt=media&token=c3e770bc-acad-42e3-9186-af404dba4df0')">
                    <span class="material-icons-outlined">arrow_circle_right</span>
                    <p>download full information</p>
                </div>
            </div>
        </div>
    </div>
</div>