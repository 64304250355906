<section class="top-image">
    <div class="des-container">
        <h5 class="page-name">News</h5>
        <p> Keeping you updated on MTO's developments.</p>
    </div>
</section>

<section class="news">
    <news-site-articles [mainArticle]="mainArticle"  [otherArticles]="otherArticles" ></news-site-articles>
</section>

<div class="stay-in-touch-section section">
    <stay-in-touch></stay-in-touch>
</div>

<div class="sponsors-section section ">
    <sponsors></sponsors>
</div>

<!-- <div class=" recent-subscribe-container ">
    <news-recent-articles></news-recent-articles>
    <news-subscribe></news-subscribe>
</div>
<news-site-articles [site]="site " *ngFor="let site of sites$ | async "></news-site-articles> -->
