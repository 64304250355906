import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateService } from 'src/app/state/state.service';
import { Response } from 'src/app/domains/payment/ecentric/response';
import { Order } from 'src/app/domains/cart/order';
import { PaymentPayload } from 'src/app/domains/payment/payment';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UialertsService } from '../ui/uialerts.service';
import { uuidv4 } from '../../utilities/uuidv4';
import { SHA256 } from '../../utilities/converters/sha256';
import { UIAlertType } from '../../utilities/enums/global-enums';
import { GlobalConfig } from '../../configuration/global.config';
import { HttpService } from '../networking/http.service';

const {
  _hpp,
  merchantKey,
  merchantGuid,
  transactionType,
  currency
} = GlobalConfig.ecentric;


@Injectable({
  providedIn: 'root'
})


export class EcentricService {

  constructor(private http: HttpClient, private stateService: StateService, private HttpService: HttpService, private alertService: UialertsService,
    private router: Router) { }


  public openPaymentWindow() {
    this.stateService.cart$.subscribe(cart => {
      if (cart) {
        const paymentReference = uuidv4();
        const amountInCents = cart.totalCost * 100;
        const checkSum = SHA256(`${merchantKey}|${merchantGuid}|${transactionType}|${amountInCents}|${currency}|${paymentReference}`).toUpperCase();
        const obj = {
          MerchantID: merchantGuid,
          TransactionType: transactionType,
          Amount: amountInCents,
          Currency: currency,
          MerchantReference: paymentReference,
          Checksum: checkSum,
        };

        _hpp.payment(obj, (response: Response, error) => {
          if (error) {
            this.alertService.openSnackBar({ duration: 10, message: "An Error Occurred with the payment portal", mode: UIAlertType.error})
          }
          this.addToPayments(cart, response)
            .then(invoiceId => {
              if (invoiceId) {
                this.alertService.openSnackBar({ duration: 5, message: "Payment Success", mode: UIAlertType.success});
                this.router.navigate(['/']);
              } else {
                this.alertService.openSnackBar({ duration: 10, message: "An Error Occurred", mode: UIAlertType.error });
              }
            });
        });
      }
    }).unsubscribe();
  }

  public async addToPayments(cart: Order, response: Response): Promise<number> {
    const obj: PaymentPayload = {
      amount: cart.totalCost,
      orderId: cart.id,
      ecentricResponse: response,
      modifiedBy: null,
      paymentDate: moment().format('YYYY-MM-DD'),
      paymentTypeId: 5,
      paymentRef: response.merchantReference
    }
    return await this.HttpService.post(this.HttpService.getBaseUrl() + 'orders/payments', obj).then((invoiceHeaderId: number) => {
      return invoiceHeaderId;
    }).catch((error) => {
      return null;
    });
  }
}
