import { Injectable } from "@angular/core";
import { StateService } from "src/app/state/state.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpService } from '../../networking/http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { loadActivityTypeAsset, loadDifficultyAsset } from "../../../utilities/assets";
import { loadSeoFriendlyString } from "../../../utilities/string-manipulation/seo-friendly";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  header: HttpHeaders;

  constructor(private http: HttpClient, private state: StateService, private HttpService: HttpService, private sanitizer: DomSanitizer) {
    this.initialize();
  }

  public async initialize() {
     this.getActivities();
  }

  private getActivities() {
    this.header = this.HttpService.getHeaders();
    return this.http.get<any>(this.HttpService.getCoreUrl() + "eco/activities?expand=true&limit=100", { headers: this.header })
      .toPromise()
      .then(async (result) => {
        let adventures = result.value;
        adventures = await Promise.all(adventures.map(async e => {
          e.activityType.iconUrl = loadActivityTypeAsset(e.activityType.name)
          e.difficulty.iconUrl = loadDifficultyAsset(e.difficulty.name);
          e.seoFriendlyName = loadSeoFriendlyString(e.name);
          e.site.seoFriendlyName = loadSeoFriendlyString(e.site.name);
          return e;
        }));
        this.state.activities = adventures;
      });
  }
}
