import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivityType } from '../../utilities/enums/global-enums';

@Pipe({
    name: 'activityAsset',
})
export class ActivityIconAssetPipe implements PipeTransform {

    constructor() { }

    transform(type: ActivityType, ...args) {
        switch (type) {
            case ActivityType.Hiking:
                return "assets/images/icons/activity-icons/hiking.svg";
            case ActivityType.MTB:
                return "assets/images/icons/activity-icons/MTB.svg";
            case ActivityType.Running:
                return "assets/images/icons/activity-icons/running.svg";
            case ActivityType.Accomodation:
                return "assets/images/icons/activity-icons/overnight.svg";
            case ActivityType.Birding:
                return "assets/images/icons/activity-icons/birding.svg";
            case ActivityType.Fishing:
                return "assets/images/icons/activity-icons/fishing.svg";
            case ActivityType.Events:
                return "assets/images/icons/activity-icons/events.svg";
            case ActivityType.Swimming:
                return "assets/images/icons/activity-icons/swimming.svg";
        }
    }
}
