<div class="wrapper">
    <div class="inner">
        <div class="section-heading container">
            <span class="material-icons">circle</span>
            <h1>Gallery</h1>
        </div>
        <div class="gallery-container">
            <gallery-images-grid [images]="images"></gallery-images-grid>
        </div>
    </div>
</div>