import { Activities_Static } from './../../../../library/static/activities';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'landing-about',
  templateUrl: './landing-about.component.html',
  styleUrls: ['./landing-about.component.scss']
})
export class LandingAboutComponent implements OnInit {

  public activities:any = Activities_Static;
  constructor() { }

  ngOnInit(): void {
  }

}
