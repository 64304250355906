import { NewsService } from '../../../library/services/resources/news/news.service';
import { Component, OnInit } from '@angular/core';
import { Wordpress } from 'src/app/domains/content/wordpress';
import { StateService } from 'src/app/state/state.service';
import { WordpressService } from 'src/app/library/services/resources/wordpress/wordpress.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  public mainArticle:Wordpress;
  public otherArticles: Wordpress[];
  public mainSlug: string;

  constructor(public newsService: NewsService, public stateService: StateService, public wordpressService: WordpressService, private route: ActivatedRoute) {
    this.routeListener();
  }

  public async getFeaturedImage(id: number) {
    return await this.wordpressService.getMediaItem(id);
  }

  ngOnInit() {

  }

  async routeListener() {
    this.route.params.subscribe(async params => {
      let articleSlug = params.article;
      this.mainSlug = articleSlug;

      this.stateService.news$.subscribe(data => {
        if (data) {
          data = data.map(e => {
            if (e.featured_media) {
              this.getFeaturedImage(e.featured_media)
                .then(data => {
                  e.featured_media_url = data.source_url;
                });
            }
            return e;
          });
          if (articleSlug) {
            let found = data.find(e => e.slug == articleSlug);
            let others = data.filter(e => e.slug != articleSlug);
            this.mainArticle = found;
            this.otherArticles = others;
          } else {
            this.mainArticle = data[0];
            this.otherArticles = data.slice(1, data.length);
          }
        }

      });
    });

  }

}
