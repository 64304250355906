<div class="testimonial-section">
    <div class="testimonial">
        <h1>
            “Testimonial quote, Odio <br> velit platea lacus, <br> at blandit. Odio.”
        </h1>
        <div class="author">
            <div class="name">
                <span class="material-icons">circle</span>
                <p>Testimonial Author</p>
            </div>
            <div class="border"></div>
        </div>
        <h4>
            Explore the garden Route <br> from our Social pages
        </h4>
        <div class="socials">
            <img src="../../../../../assets/images/icons/social/facebook-blue-icon.png" alt="">
            <img src="../../../../../assets/images/icons/social/instagram-blue-icon.png" alt="">
            <!-- <img src="../../../../../assets/images/icons/social/twitter-blue-icon.png" alt=""> -->
            <img src="../../../../../assets/images/icons/social/mail-iblue-icon.png" alt="">
        </div>
    </div>
</div>