import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { StateService } from 'src/app/state/state.service';

declare const google; 


@Component({
  selector: 'cape-nature-overview',
  templateUrl: './cape-nature-overview.component.html',
  styleUrls: ['./cape-nature-overview.component.scss']
})
export class CapeNatureOverviewComponent implements OnInit {

  siteTrail;
  
  @Input() site;
  localSite;

  tsitsikammaKmls = [
    'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Fcapenature%2FCape%20Nature%20Jonkershoek%20Trails.kmz?alt=media&token=2534da37-f463-4e89-bb7c-16dd6aeffe1d'    
  ]

  map;
  showKMLContent : boolean = false;


  constructor(private stateService : StateService, private ecoService : EcoService, private router : Router) { }

  ngOnInit(): void {

    this.stateService.sites$.subscribe((site)=>{
      this.siteTrail = (site.filter((site) => site.id == 5))[0];
    });
    
    this.initMap();
  }


  public openLink(link: string, newTab: boolean = false) {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      this.router.navigateByUrl(link);
    }
  }


  initMap() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      // center: new google.maps.LatLng(-27.384622, 26.648436),
      zoom: 2,
      mapTypeId: 'satellite',
      gestureHandling: "cooperative"
    });

    this.tsitsikammaKmls.forEach((kml)=>{
      var kmlLayer = new google.maps.KmlLayer(kml, {
        // suppressInfoWindows: true,
        preserveViewport: false,
        map: this.map
      });

      kmlLayer.addListener('click', function(event) {
        // alert('kml clicked');
        this.showKMLContent = true;
        var content = event.featureData.infoWindowHtml;
        var testimonial = document.getElementById('capture');
        testimonial.innerHTML = content;
      });

    })

  }

}
