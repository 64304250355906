<div class="wrapper">
    <div class="inner container">
        <div class="section-heading">
            <i class="material-icons">circle</i>
            <p class="">Latest News</p>
        </div>
        <div class="articl-container">
            <div class="article-wrapper"  *ngFor="let article of articles"  [routerLink]="['/news', article.slug]">
                <news-article-card [article]="article"></news-article-card>
            </div>
        </div>
    </div>
</div>