import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/library/services/authentication/authentication.service';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ParseFirebaseError } from 'src/app/library/utilities/parsers/firebase-error';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';
import { FirebaseError } from 'firebase/app';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  public hide: boolean = true;
  public form: FormGroup;
  public submitted: boolean = false;
  public savingForm = false;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private ngZone: NgZone,
    private authService: AuthenticationService, private alertService: UialertsService, private router: Router) { }

  async ngOnInit() {
    await this.createForm();
  }

  public async submit() {
    this.submitted = true;
    let router = this.router;

    if (this.form.invalid) {
      return;
    }
    this.savingForm = true;
    this.ngZone.run(() => {
      this.authService.loginWithEmailAndPassword(this.form.value)
        .then((user) => {
          let redirectTo = this.route.snapshot.queryParams.redirectTo;
          let url = redirectTo ? redirectTo : '';
          router.navigate([url]);
        }).catch((ex: FirebaseError) => {
          this.alertService.openSnackBar({ duration: 10, message: ParseFirebaseError(ex).message, mode: UIAlertType.error });
        })
        .finally(() => {
          this.savingForm = false;
        });
    });
  }

  private async createForm() {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  get fc() { return this.form.controls; }
}
