import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from 'src/app/state/state.service';
import { GlobalConfig } from '../../../configuration/global.config';
import { PagedResult } from '../../../interfaces/general/paged-result';
import { SearchFilter } from '../../../interfaces/general/search-filter';
import { News } from '../../../interfaces/resources/news/news';
import { HttpService } from '../../networking/http.service';
import { WordpressService } from '../wordpress/wordpress.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient, private state: StateService, private HttpService: HttpService, private wordpressService: WordpressService) {
    this.initialise();
  }

  public async initialise() {
    this.getNewsWP();
  }

  private getNewsWP() {
    this.wordpressService.getWPContent('news').then(data => {
      this.state.news = data;
    });
  }

  private getPublicNews() {
    this.HttpService.get(`${GlobalConfig.APIS.OLDAPI}news/public`)
      .then(data => {
        this.state.news = data;
      });
  }
}
