import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Interceptors } from '../library/interceptors/public.interceptors.export';
import { Services } from '../library/services/services.export';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../library/modules/shared/shared.module';
import { MaterialModule } from '../library/modules/shared/material.module';
import { AppRoutingModule } from '../library/modules/routing/app-routing.module';
import { Components } from '../components/components.export';
import { FirebaseService } from '../library/services/firebase.service';
import { EcoService } from '../library/services/resources/ecotourism/eco.service';
import { ActivitiesService } from '../library/services/resources/Activities/activities.service';
import { StateService } from '../state/state.service';
import { AccountService } from '../library/services/account/account.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    AppComponent,
    ...Components
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    MaterialModule,
    LazyLoadImageModule
  ],
  providers: [
    ...Interceptors,
    ...Services,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private firebaseService : FirebaseService, 
    private ecoService : EcoService,
    private accountService : AccountService,
    private activitiesService : ActivitiesService,
    private stateService : StateService){
      this.startApp();
  }
    
  private async startApp() {
    this.stateService.appLoading = true;
    await this.accountService.initialize();
    await this.firebaseService.initializeApp();
    await this.activitiesService.initialize();
    await this.ecoService.initialize();

    setTimeout(()=> this.stateService.appLoading = false,0);
  }
 }
