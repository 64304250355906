import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/library/interfaces/resources/product/product';

@Component({
  selector: 'merchandise-item',
  templateUrl: './merchandise-item.component.html',
  styleUrls: ['./merchandise-item.component.scss']
})
export class MerchandiseItemComponent implements OnInit {

  @Input() merchandiseItem: Product;

  public stockUnitString;

  constructor() { }

  ngOnInit() {
    this.setStockUnitString();
  }

  private setStockUnitString(){
    this.stockUnitString = this.merchandiseItem.stockAvailable > 9 ? `${this.merchandiseItem.stockAvailable} Units Available` : `Only ${this.merchandiseItem.stockAvailable} more Units Available`;
   }

}
