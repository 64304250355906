import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from 'console';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';

@Component({
  selector: 'location-news',
  templateUrl: './location-news.component.html',
  styleUrls: ['./location-news.component.scss']
})
export class LocationNewsComponent implements OnInit {

  eventGridColSpan: number = 1;
  events : any = [];
  siteId;

  constructor(private ecoService : EcoService, private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((snapshot)=>{
      this.siteId = snapshot.id
      console.log("hey")
      this.resiseGrid();
  
      this.ecoService.events$.subscribe((events)=>{ 
        this.events = (events.filter((event) => event.site.id == this.siteId));      
      })
    });
  }

  resiseGrid(){
    if(window.innerWidth < 870) this.eventGridColSpan = 1;
    if(window.innerWidth > 870) this.eventGridColSpan = 3;
    if(window.innerWidth > 1300) this.eventGridColSpan = 3;
  }

}
