import { CustomFormInputValidator, InputType } from './../../../../library/utilities/classes/CustomForm';
import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/library/services/account/account.service';
import { FeedbackType, UIAlertType } from 'src/app/library/utilities/enums/global-enums';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';


@Component({
  selector: 'contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  name:CustomFormInputValidator;
  contactNumber:CustomFormInputValidator;
  emailAddress:CustomFormInputValidator;
  message:CustomFormInputValidator;
  location:CustomFormInputValidator;

  constructor(private accountService : AccountService, private alertService : UialertsService) { 

  }

  ngOnInit() {
    this.resetForm();  
  }

  resetForm(){

    this.name = new CustomFormInputValidator(InputType.PlainText, "" , false);
    this.contactNumber = new CustomFormInputValidator(InputType.PlainText, "", false);
    this.emailAddress = new CustomFormInputValidator(InputType.Email, "", true);
    this.message = new CustomFormInputValidator(InputType.PlainText, "", false);
    this.location = new CustomFormInputValidator(InputType.Select, "Select Location", true)

  }

  isFormValid(){

    let nV = this.name.validateInput();
    let cV = this.contactNumber.validateInput();
    let eV = this.emailAddress.validateInput();
    let mV = this.message.validateInput();
    let lV = this.location.validateInput();

    return nV && cV && eV && mV && lV;
  }

  async onSubmit(){
      if(this.isFormValid()){
          await this.accountService.submitFeedback({
              fullName : this.name.Value,
              details : this.message.Value,
              contactNumber : this.contactNumber.Value,
              subject : "MTO Trails Website feedback " + this.location.Value ,
              emailAddress : this.emailAddress.Value,
              feedbackType : FeedbackType.General
          });
          this.alertService.openSnackBar({ duration: 10, message: 'Feedback submitted', mode: UIAlertType.success });
        this.resetForm()
      } else {
        this.alertService.openSnackBar({ duration: 10, message: 'Form is invalud', mode: UIAlertType.error }) 
      }
  }

}
