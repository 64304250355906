import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'merchandise-items',
  templateUrl: './merchandise-items.component.html',
  styleUrls: ['./merchandise-items.component.scss']
})
export class MerchandiseItemsComponent{


}
