<div class="testimonial-section section" [ngStyle]="{'background':'url('+ site.motQoute.backgroundImgUrl +')'}">
    <div class="overlay">
        <div class="testimonial">
            <h1>
                {{site.motQoute.qoute}}
            </h1>
            <div class="author">
                <div class="name">
                    <span class="material-icons">circle</span>
                    <p>{{site.motQoute.author}}</p>
                </div>
                <div class="border"></div>
            </div>
            <h4>
                Explore {{site.name}} <br> from our social media pages.
            </h4>
            <div class="socials">
                <img src="../../../../../assets/images/icons/social/facebook-blue-icon.png" style="cursor: pointer;" (click)="openLink(site.socialLinks.facebook, true)" alt="">
                <img src="../../../../../assets/images/icons/social/instagram-blue-icon.png" style="cursor: pointer;" (click)="openLink(site.socialLinks.instagram, true)" alt="" alt="">
                <!-- <img src="../../../../../assets/images/icons/social/mail-iblue-icon.png" alt=""> -->
            </div>
        </div>
    </div>
</div>
