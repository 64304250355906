import { Component, OnInit } from '@angular/core';
import { SITES_STATIC } from 'src/app/library/static/sites';

@Component({
  selector: 'lacations-splash',
  templateUrl: './lacations-splash.component.html',
  styleUrls: ['./lacations-splash.component.scss']
})
export class LacationsSplashComponent implements OnInit {

  locations : any = SITES_STATIC;

  constructor() { }

  ngOnInit(): void {
  }

}
