<div class="wrapper">
    <img class="welcome-div " src="../../../../../assets/images/wide-bg.svg" alt="">
    <div class="inner">
        <!-- <h1 class="title">Welcome to MTO trails</h1> -->
        <!-- <div class="floating-p">
            <p>
                <b>Mto Trails</b> Stretching from Mountains to Oceans, MTO Trails provides and promotes world class hiking, mountain biking, trail running, bird-watching, fishing, events and comfortable accommodation facilities. MTO Trails creates and
                provides adventure experiences to outdoor enthusiasts ensuring exceptional customer experiences and a safe passage through the Forestry Plantations and conservation regions. We also offer safe, clearly marked routes, affording all eco
                enthusiasts the opportunity to explore our hidden treasures.
            </p>
        </div> -->
        <!-- <div class="floating-p">
            <p>
                <b>Who are we ?</b> MTO Trails is a division of the industry leader in agriculture and forestry, MTO Group. The MTO Group is situated throughout the country - Jonkershoek (Western Cape), Garden Route (Western and Eastern Cape) and Lowveld
                (Mpumalanga) and extends along a large variety of landscapes, from the Cape Fynbos, to Afromontane forests, Deep River gorges, famous mountain peaks and bushveld. MTO is Focused on sustainably growing and improving the ecotourism product
                offering, not only mountain biking but also the hiking, trail running, paddling, and birding. We aspire to be a leader in a sustainable, world class adventure destination while conserving the environment and uplifting the local communities
            </p>
        </div> -->
        <br>
        <!-- What to do -->
        <h1 class="title">What to do</h1>
        <br>
        <div class="activities container">
            <div class="activity" *ngFor="let activity of activities;">
                <div class="icon-container">
                    <img height="100" src="{{activity.icon}}" alt="">
                </div>
                <h4>{{activity.name}}</h4>
            </div>
        </div>
    </div>
</div>