import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Wordpress } from 'src/app/domains/content/wordpress';
import { GlobalConfig } from '../../../configuration/global.config';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  constructor(private httpClient: HttpClient) { }

  public async getMediaItem(id: number) {
    return await this.httpClient.get(`${GlobalConfig.Wordpress.mto}wp-json/wp/v2/media/${id}`).toPromise().then(data => {
      return data;
    }).catch((error) => {
      return error;
    });
  }

  public async getWPContent(contentTitle: string) {
    return await this.httpClient.get<Wordpress[]>(`${GlobalConfig.Wordpress.mto}wp-json/wp/v2/${contentTitle}`).toPromise()
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
}
