import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { GlobalConfig } from "../../configuration/global.config";
import { StateService } from "src/app/state/state.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  params: new HttpParams()
};

const baseurl = GlobalConfig.APIS.OLDAPI;
const coreBaseUrl = GlobalConfig.APIS.CORE;
const apiKey = GlobalConfig.APIS.KEY

@Injectable({
  providedIn: "root"
})
export class HttpService {
  constructor(private httpClient: HttpClient, private stateService : StateService) { 
    this.initialize();
  }

   public initialize() : void { 
     try {
      this.stateService.token$.subscribe((token)=>{
        if(token){
          httpOptions.headers = httpOptions.headers.set("Bearer", token);
        }
      });
     } catch (error) {
       console.error(error);
     }
   }

  public async post(url: string, data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, data, httpOptions).subscribe(
        results => resolve(results),
        error => {
          reject(error);
        }
      );
    });
  }

  public async get(path: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.get(path, httpOptions).subscribe(
        results => resolve(results),
        error => {
          console.error(error);
          reject(`get error occured - ${error}`);
        }
      );
    });
  }

  public async delete(path: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.delete(path, httpOptions).subscribe(
        results => resolve(results),
        error => {
          console.error(error);
          reject(`get error occured - ${error}`);
        }
      );
    });
  }

  public async getExternal(path: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.get(path, httpOptions).subscribe(
        results => resolve(results),
        error => {
          console.error(error);
          reject(`get error occured - ${error}`);
        }
      );
    });
  }

  /**
   * Run hatios path
   * @param path
   */

  public async runpath(path: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (path) {
        this.httpClient.get(path, httpOptions).subscribe(
          results => {
            resolve(results);
          },
          error => {
            console.error(error);
            reject(`error occured - ${error}`);
          }
        );
      } else {
        resolve("path invalid");
      }
    });
  }

  public async put(path: string, data: any): Promise<any> {
    httpOptions.headers = httpOptions.headers.set("apiKey", apiKey);
    return new Promise<any>((resolve, reject) => {
      this.httpClient.put(path, data, httpOptions).subscribe(
        results => resolve(results),
        error => {
          reject(error);
        }
      );
    });
  }

  public getHeaders(): HttpHeaders {
    httpOptions.headers = httpOptions.headers.set(
      "Authorization",
      "apiKey " + apiKey
    );
    return httpOptions.headers;
  }

  public getBaseUrl() {
    return baseurl;
  }
  public getCoreUrl() {
    return coreBaseUrl;
  }
}
