<div class="forgot-pass-container container">
    <div class="wrapper">
      <div class="header" [routerLink]="['/']"  >
        <img src="../../../../../assets/images/logo/logo-white.png" />
        <span>MTO GROUP</span>
      </div>
      <div class="inner">
        <span class="text-tip">Please enter the email address of the account you want to reset the password for.</span>
        <forgot-password-form></forgot-password-form>
  
       
        <div class="return-wrapper">
         <span class="important" [routerLink]="['/account/login']">Return To Sign In Page</span>
        </div>
      </div>
    </div>
  </div>