<div class="wrapper">
    <div class="inner">
        <div class="location-info container">
            <div class="info-block">
                <span class="material-icons">house</span>
                <h4 class="info-heading">Serviced Guesthouse</h4>
            </div>
            <div class="info-block">
                <span class="material-icons">restaurant</span>
                <h4 class="info-heading">Self Catering</h4>
            </div>
            <div class="info-block">
                <span class="material-icons">night_shelter</span>
                <h4 class="info-heading" *ngIf="accommodation.id == 109">Sleeps 6</h4>
                <h4 class="info-heading" *ngIf="accommodation.id != 109">Sleeps 8</h4>
            </div>
            <div class="info-block">
                <span class="material-icons">cleaning_services</span>
                <h4 class="info-heading">Daily Housekeeping</h4>
            </div>
        </div>
        <div class="main-container">
            <div class="facility-info" *ngIf="accommodation.id != 109">
                <div class="content container">
                    <div class="info">
                        <div class="info-heading">
                            <span class="material-icons">circle</span>
                            <h1>Facilities</h1>
                        </div>
                        <ul class="info-list">
                            <li>2 Bedrooms with king size beds</li>
                            <li>3rd bedroom is a loft with has 2 single beds</li>
                            <li>Sleeper couch for additional 2 children if 8 sleeper is required.</li>
                            <li>An outdoor shower</li>
                        </ul>
                    </div>
                    <div class="info">
                        <div class="info-heading">
                            <span class="material-icons">circle</span>
                            <h1>Access & Location</h1>
                        </div>
                        <ul class="info-list">
                            <li>Paradise Falls is situated 2.3 kms from the R37 T-junction on the R539 towards Sudwala, a 40 min drive from Nelspruit or White River. </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="facility-info" *ngIf="accommodation.id == 109">
                <div class="content container">
                    <div class="info">
                        <div class="info-heading">
                            <span class="material-icons">circle</span>
                            <h1>Facilities</h1>
                        </div>
                        <ul class="info-list">
                            <li>3 Bedrooms each with 2 single beds</li>
                            <li>Outside veranda with gas a braai</li>
                            <li>Bike room to lock away any valuables</li>
                            <li>Private access and parking to the cottage</li>
                        </ul>
                    </div>
                    <div class="info">
                        <div class="info-heading">
                            <span class="material-icons">circle</span>
                            <h1>Access & Location</h1>
                        </div>
                        <ul class="info-list">
                            <li>Located just 7km from the centre of Stellenbosch, the Jonkershoek Valley Cottage is nestled at the end of the Jonkershoek Valley Road.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section class="two-section">
                <div class="section_1 section_c">
                    <div class="content_container" style="height: 194px;">
                        <h1 class="title">Rates</h1>
                        <p>From R2 400 a night.</p>
                    </div>
                    <div (click)="openLink('https://book.nightsbridge.com/32891',true)" class="button_bottom">View Rates</div>
                </div>
                <div *ngIf="accommodation.id != 109" class="section_2 section_c">
                    <div class="content_container correct2">
                        <div class="info-img-bg extend-h-mobile">
                            <div class="info-block">
                                <div class="content-info">
                                    <h2 class="title">Activities from your doorstep</h2>
                                    <ul class="list top-list">
                                        <li>Enjoy 27 kms of trails for mountain biking, trail running, hiking and birding.</li>
                                        <li>Picnic in the forest or at the rock pools.</li>
                                        <li>
                                            Hang-glide off the escarpment edge or simply relax on the shaded wooden deck with the expansive view and birdsong.
                                        </li>
                                    </ul>
                                    <br>
                                    <h2 class="title bottom-title">Activities further afield</h2>
                                    <ul class="list">
                                        <li>Visit Kruger Park for a day – Phabeni and Numbi gates are just over an hour drive.</li>
                                        <li>Klipkopje Dam for a day on the boats skiing, tubing and fishing</li>
                                        <li>Blyde River Canyon</li>
                                        <li>God’s Window</li>
                                        <li>Sudwala Caves</li>
                                        <li>Sabie</li>
                                        <li>Pilgrims Rest</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div (click)="openLink('https://www.google.com/maps/dir/-27.403088,26.6382003/Legogote+St,+White+River,+1240/@-26.3506744,26.5681352,7z/data=!3m1!4b1!4m17!1m7!3m6!1s0x1ee83190757ad3b9:0x38b3a2adaf0cb4cc!2sLegogote+St,+White+River,+1240!3b1!8m2!3d-25.3176907!4d31.0001682!4m8!1m1!4e1!1m5!1m1!1s0x1ee83190757ad3b9:0x38b3a2adaf0cb4cc!2m2!1d31.0001682!2d-25.3176907',true)"
                        class="button_bottom">
                        Go to Location
                    </div>
                </div>

                <div *ngIf="accommodation.id == 109" class="section_2 section_c">
                    <div class="content_container correct2">
                        <div class="info-img-bg extend-h-mobile">
                            <div class="info-block">
                                <div class="content-info">
                                    <h2 class="title">Activities from your doorstep</h2>
                                    <ul class="list top-list">
                                        <li>Access to over 22 different mountain bike, hiking and trail running trails offered by MTO Trails and Cape Nature
                                        </li>
                                        <li>Picnic in the forest or at the rock pools.</li>
                                        <li>
                                            Grab a fresh coffee from Balance Coffee Roastery
                                        </li>
                                        <li>
                                            Swim in the Eerste River and its surrounding waterfalls
                                        </li>
                                    </ul>
                                    <br>
                                    <h2 class="title bottom-title">Activities further afield</h2>
                                    <ul class="list">
                                        <li>Ride Inn offers a fleet of rental bikes and they are located 3km down the road from the cottage.
                                        </li>
                                        <li>Post Card Café located 2km from the cottage offers a beautiful setting for wine tasting in the valley followed by a visit next door to Old Nectar Gardens.
                                        </li>
                                        <li>Scoot tours offering guided mountain bike tours
                                        </li>
                                        <li>Stellenbosch Town and all of its attractions from restaurants and wine bars, to tourist retail outlets
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div (click)="openLink('https://www.google.com/maps/place/MTO+Forestry+(Jonkershoek)/@-33.9664008,18.9272357,16z/data=!4m13!1m7!3m6!1s0x1dcdb17e4102db2f:0x1d1579e4ed99a2c!2sJonkershoek+Rd!3b1!8m2!3d-33.9512794!4d18.9052428!3m4!1s0x1dcdb1155b3c833d:0xf99d9055c9cb5df4!8m2!3d-33.9650215!4d18.9274675',true)"
                        class="button_bottom">
                        Go to Location
                    </div>
                </div>
            </section>
        </div>


        <!-- <div class="facility-info">
            <div class="absolute-border">
                <img src="../../../../../assets/images/backgrounds/mtn-grunge_border_1.png" alt="">
            </div>
            <div class="content container">
                <div class="info">
                    <div class="info-heading">
                        <span class="material-icons">circle</span>
                        <h1>Facilities</h1>
                    </div>
                    <ul class="info-list">
                        <li>2 Bedrooms with king size beds</li>
                        <li>3rd bedroom is a loft with has 2 single beds</li>
                        <li>Sleeper couch for additional 2 children if 8 sleeper is required.</li>
                        <li>An outdoor shower</li>
                    </ul>
                </div>
                <div class="info">
                    <div class="info-heading">
                        <span class="material-icons">circle</span>
                        <h1>Access & Location</h1>
                    </div>
                    <ul class="info-list">
                        <li>Paradise Falls is situated 2.3 kms from the R37 T-junction on the R539 towards Sudwala, a 40 min drive from Nelspruit or White River. </li>
                    </ul>
                </div>
            </div>
            <div class="content container">
                <div class="info-img-bg" style="background: url('../../../../../assets/images/accomidation/rates-bg.png');">
                    <div class="info-block">
                        <div class="content-info">
                            <h2>Rates</h2>
                            <p>
                                At ridiculus id netus ac nisl tincidunt nisl, arcu. Ligula feugiat eget faucibus metus metus in. Faucibus sem venenatis orci id venenatis eu vestibulum. Ultricies molestie eu hac.
                            </p>
                        </div>
                        <div class="action">
                            <span class="material-icons-outlined">arrow_circle_right</span>
                            <p>view rates</p>
                        </div>
                    </div>
                </div>
                <div class="info-img-bg extend-h-mobile">
                    <div class="info-block">
                        <div class="content-info">
                            <h2>Activities from your doorstep</h2>
                            <ul class="list">
                                <li>Enjoy 27 kms of trails for mountain biking, trail running, hiking and birding.</li>
                                <li>Picnic in the forest or at the rock pools.</li>
                                <li>
                                    Hang-glide off the escarpment edge or simply relax on the shaded wooden deck with the expansive view and birdsong.
                                </li>
                            </ul>
                            <br>
                            <h2>Activities further afield</h2>
                            <ul class="list">
                                <li>Visit Kruger Park for a day – Phabeni and Numbi gates are just over an hour drive.</li>
                                <li>Klipkopje Dam for a day on the boats skiing, tubing and fishing</li>
                                <li>Blyde River Canyon</li>
                                <li>God’s Window</li>
                                <li>Sudwala Caves</li>
                                <li>Sabie</li>
                                <li>Pilgrims Rest</li>
                            </ul>
                        </div>
                        <div class="action">
                            <span class="material-icons-outlined">arrow_circle_right</span>
                            <p>view location</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="testimonial-section">
            <div class="testimonial">
                <h1>
                    “Testimonial quote, Odio <br> velit platea lacus, <br> at blandit. Odio.”
                </h1>
                <div class="author">
                    <div class="name">
                        <span class="material-icons">circle</span>
                        <p>Testimonial Author</p>
                    </div>
                    <div class="border"></div>
                </div>
                <h4>
                    Explore the garden Route <br> from our Social pages
                </h4>
                <div class="socials">
                    <img src="../../../../../assets/images/icons/social/facebook-blue-icon.png" alt="">
                    <img src="../../../../../assets/images/icons/social/instagram-blue-icon.png" alt="">
                    <!-- <img src="../../../../../assets/images/icons/social/twitter-blue-icon.png" alt=""> -->
                    <img src="../../../../../assets/images/icons/social/mail-iblue-icon.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>