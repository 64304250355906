<div class="container">
    <div class="left-section">

    </div>

    <div class="right-section">
        <h4 class="mat-title"><strong>ABOUT</strong></h4>
        Jonkershoek Nature Reserve, which includes the smaller Assegaaibosch Nature Reserve, lies near the town of Stellenbosch in the southwestern Cape. The Reserve comprises the imposing Jonkershoek mountains and portions of the upper Jonkershoek valley. The
        Jonkershoek mountains, with their high peaks and deep kloofs, form part of the larger Boland mountain range (part of the greater Hottentots Holland Nature Reserve). The Eerste, Berg, Lourens and Riviersonderend rivers all have their sources high
        in these mountains, although only the Eerste River actually flows through the Jonkershoek valley. The lower reaches of the valley are a well-known wine-producing area.
        <br>
        <br>



        <mat-tab-group animationDuration="100ms" mat-align-tabs="start">
            <mat-tab *ngFor="let day of Days" label="{{day.name}}">
                <div class="tab-content">
                    <h4 class="mat-title"><strong>{{day.name}}</strong></h4>

                    <p [innerHtml]="day.description"></p>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>