import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';

@Component({
  selector: 'location-accommodation',
  templateUrl: './location-accommodation.component.html',
  styleUrls: ['./location-accommodation.component.scss']
})
export class LocationAccommodationComponent implements OnInit {
  siteId;
  accommodation;

  constructor(private ecoService : EcoService, private route : ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe((snapshot)=>{
      this.siteId = snapshot.id;

      this.ecoService.accommodation$.subscribe((accommodationList)=>{ 
        this.accommodation = (accommodationList.filter((accommodation) => accommodation.site.id == this.siteId));      
      })
    })


  }
    

}
