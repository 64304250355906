<div class="wrapper">
    <img class="footer-border" src="../../../../assets/images/border-bage.svg" alt="">
    <div class="inner">
        <div class="container">
            <img class="logo-header" src="../../../../assets/images/logo/logo.svg" alt="logo">
            <div class="footer-wrapper">
                <div class="footer-col-wrapper">
                    <div class="footer-col-text ">
                        <span class="heading">Creating Exceptional Experiences</span>
                    </div>
                    <div class="footer-col-text middle-text">
                        <span>
                            Purchase permits and book some of the best mountain biking trails, accomodation and events in the country.
                            View maps, weather and a media gallery for each location straight from the myMTO app
                        </span>
                    </div>
                    <!-- <div class="footer-col-text p-b-5 ">
                        <span class="heading">Download Our myMTO App</span>
                    </div> -->
                    <div class="footer-icon-wrapper">
                        <div class="icon-wrapper" (click)="openLink('https://itunes.apple.com/za/app/mymto/id1423559831', true)"><img src="../../../../assets/images/common/apple-download.svg"></div>
                        <div class="icon-wrapper" (click)="openLink('https://play.google.com/store/apps/details?id=group.mto.mymto', true)"><img src="../../../../assets/images/common/gplay-download.svg"></div>
                    </div>
                </div>
                <div class="footer-col-wrapper">
                    <div class="footer-inner-col-wrapper">
                        <span class="heading items-h"> Get to know MTO Forestry</span>
                        <div class="footer-col-text middle-text">
                            <span>
                               Reach out to us on all our social media platforms <br>   
                               and find out what we are all about.
                            </span>
                        </div>
                        <div class="social-media-icons">
                            <img class="icon" src="../../../../assets/images/icons/social/facebook-icon.png" (click)="openLink('https://www.facebook.com/mtoforestrygroup/', true)" />
                            <!-- <img class="icon" src="../../../../assets/images/icons/social/twitter-icon.png" (click)="openLink('/', true)" /> -->
                            <img class="icon" src="../../../../assets/images/icons/social/instagram-icon.png" (click)="openLink('https://www.instagram.com/realmtogroup/?hl=en', true)" />
                            <!-- <img class="icon" src="../../../../assets/images/icons/social/mail-icon.png" (click)="openLink('mailto:info@mro.com', true)" /> -->
                        </div>
                    </div>
                </div>
                <div class="footer-col-wrapper">
                    <div class="footer-inner-col-wrapper">
                        <span class="heading items-h">Follow MTO trails</span>
                        <h4>Jonkershoek</h4>
                        <div class="social-media-icons">
                            <img class="icon" src="../../../../assets/images/icons/social/facebook-icon.png" (click)="openLink('https://www.facebook.com/mtotrailsjonkershoek/', true)" />
                            <img class="icon" src="../../../../assets/images/icons/social/instagram-icon.png" (click)="openLink('https://www.instagram.com/mtotrails_jonkershoek/', true)" />
                        </div>
                        <h4>Garden Route</h4>
                        <div class="social-media-icons">
                            <img class="icon" src="../../../../assets/images/icons/social/facebook-icon.png" (click)="openLink('https://www.facebook.com/mtotrailsgardenroute/', true)" />
                            <img class="icon" src="../../../../assets/images/icons/social/instagram-icon.png" (click)="openLink('https://www.instagram.com/mtotrails_gardenroute/', true)" />
                        </div>
                        <h4>lowveld</h4>
                        <div class="social-media-icons">
                            <img class="icon" src="../../../../assets/images/icons/social/facebook-icon.png" (click)="openLink('https://www.facebook.com/mtotrailslowveld/', true)" />
                            <img class="icon" src="../../../../assets/images/icons/social/instagram-icon.png" (click)="openLink('https://www.instagram.com/mtotrails_lowveld/', true)" />
                        </div>
                        <!-- <ng-container *ngIf="(sites$ | async) as sites;else loaderSites">
                            <span class="col-item" [routerLink]="['/locations', site.name | seoFriendly]" [queryParams]="{'siteId': site.id}" *ngFor="let site of sites">{{site.name}}</span>
                        </ng-container>
                        <ng-template #loaderSites>
                        <loader></loader>
                        </ng-template> -->
                    </div>
                </div>
                <div class="footer-col-wrapper">
                    <div class="footer-inner-col-wrapper">
                        <span class="heading items-h">Let us help you</span>
                        <span class="col-item" (click)="openLink('/account')">Your account</span>
                        <span class="col-item" (click)="openLink('/account/permits')">Your permits</span>
                        <span class="col-item" (click)="openLink('/contact-us')">Contact us</span>
                        <span class="col-item" (click)="openLink('/contact-us')">Trail maintenance request</span>
                    </div>
                </div>

            </div>
            <div class="footer-copyright">
                Copyright © 2021 MTO Group. All Rights Reserved | Privacy Policy
            </div>
        </div>

    </div>
</div>