import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  events:any = [];
  eventOnView:any = this.events[0];
  eventId:string;
  
  constructor(private ecoService:EcoService, private route:ActivatedRoute) { 

  }

  ngOnInit() : void {
  
  this.route.params.subscribe(async param =>{
      this.eventId = param.id      
      this.ecoService.events$.subscribe((events)=>{ 
        this.events = events;
        this.eventOnView = this.events[0]

        if(this.eventId)this.eventOnView = events.find(e => e.id == this.eventId);
        console.log(this.eventOnView)
      })
  }) 

  }
}
