import { Pipe, PipeTransform } from '@angular/core';
import { DifficultyType } from '../../utilities/enums/global-enums';

@Pipe({
  name: 'activityDifficulty'
})
export class ActivityDifficultyPipe implements PipeTransform {

  transform(value: DifficultyType, args?: any): any {
    switch (value) {
      case DifficultyType.Easy:
        return "Easy";
      case DifficultyType.Moderate:
        return "Moderate";
      case DifficultyType.Hard:
        return "Hard";
      case DifficultyType.Extreme:
        return "Extreme";
      case DifficultyType.Nightmare:
        return "Nightmare";
    }
  }

}