import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hiking-trails-faqs-trails-info',
  templateUrl: './hiking-trails-faqs-trails-info.component.html',
  styleUrls: ['./hiking-trails-faqs-trails-info.component.scss']
})
export class HikingTrailsFaqsTrailsInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public openLink(link: string) {
      window.open(link, "_blank");

  }

}
