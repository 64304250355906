import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';


@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.scss']
})
export class AccommodationComponent implements OnInit {

  accommodation;
  resourceId;
  images;

  constructor(private ecoService : EcoService, private route : ActivatedRoute) {
    this.resourceId = this.route.snapshot.paramMap.get('accId');

    this.loadAccommodation();
   }

  ngOnInit(): void {
    
  }

  private loadAccommodation() : void { 
    try {
      this.ecoService.accommodation$
      .subscribe((acc)=>{
        if( Array.isArray(acc) && acc.length > 0){
          this.accommodation = (acc.filter((a) => a.id == this.resourceId))[0];
          console.log(this.accommodation);
          
        }
        
      });
      
    } catch (error) {
      
    }
  }

}
