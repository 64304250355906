import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() loaderText: string;
  @Input("loadingPerc") $percent: Observable<number>;
  constructor() { }

  ngOnInit() {
  }

}
