import { GalleryImagesSource } from 'src/app/library/temporary/gallery-images-source';
import { Component, Input, OnInit } from '@angular/core';
import { galleryImage } from '../../gallery/gallery-images-grid/gallery-images-grid.component';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';

@Component({
  selector: 'accommodation-paradise-falls-gallery',
  templateUrl: './accommodation-paradise-falls-gallery.component.html',
  styleUrls: ['./accommodation-paradise-falls-gallery.component.scss']
})
export class AccommodationParadiseFallsGalleryComponent implements OnInit {

  @Input() id;

  images;
  constructor(private ecoService : EcoService) { }

  ngOnInit(): void {
    this.loadAccommodation()
  }

  private loadAccommodation() : void { 
    try {
      this.ecoService.accommodation$
      .subscribe((acc)=>{
        if( Array.isArray(acc) && acc.length > 0){
          this.images = (((acc.filter((a) => a.id == this.id))[0]).media.map((x)=>{
            return {
              Source : x.url
            }
          })).splice(0,6)

          console.log(this.images);
          
          
        }
        
      });
      
    } catch (error) {
      
    }
  }


}
