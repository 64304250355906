import { TitleCasePipe, CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ActivityIconAssetPipe } from '../../pipes/assets/activity-asset';
import { ActivityFriendlyStringPipe } from '../../pipes/friendly-string/activity-friendly-string.pipe';
import { NewsDataToFriendlyPipe } from "../../pipes/friendly-string/news-data-to-friendly.pipe";
import { PaymentTypeToFriendlyPipe } from "../../pipes/friendly-string/payment-type-to-friendly.pipe";
import { ProductTypeToFriendlyPipe } from "../../pipes/friendly-string/productType-to-friendly.pipe";
import { SeoFriendlyStringPipe } from '../../pipes/friendly-string/seo-friendly-string.pipe';
import { StatusToFriendlyPipe } from "../../pipes/friendly-string/status-to-friendly.pipe";
import { SanitizeUrl } from "../../pipes/sanitizeUrl/santizeUrl";
import { GroupByPipe } from "../../pipes/dataTransformation/groupby.pipe";
import { ConverterActivityPipe } from "../../pipes/dataTransformation/convertActivity.pipe";
import { ActivityDifficultyPipe } from "../../pipes/friendly-string/activity-difficulty.pipe";
import { RoundDownPipe } from "../../pipes/dataTransformation/round-down.pipe";
import { PermitTypePipeAnnual, PermitTypePipeDay, PermitTypePipeOther } from "../../pipes/conditional/permitType.pipe";
import { StripHtmlPipe } from "../../pipes/stripHtml";

@NgModule({
    declarations: [
        ActivityIconAssetPipe,
        ActivityFriendlyStringPipe,
        SeoFriendlyStringPipe,
        StatusToFriendlyPipe,
        ProductTypeToFriendlyPipe,
        PaymentTypeToFriendlyPipe,
        NewsDataToFriendlyPipe,
        SanitizeUrl,
        GroupByPipe,
        ConverterActivityPipe,
        ActivityDifficultyPipe,
        RoundDownPipe,
        PermitTypePipeAnnual,
        PermitTypePipeDay,
        PermitTypePipeOther,
        StripHtmlPipe
    ],
    imports: [
        CommonModule
    ],
    providers: [
        TitleCasePipe,
    ],
    exports: [
        ActivityIconAssetPipe,
        ActivityFriendlyStringPipe,
        SeoFriendlyStringPipe,
        StatusToFriendlyPipe,
        ProductTypeToFriendlyPipe,
        PaymentTypeToFriendlyPipe,
        NewsDataToFriendlyPipe,
        SanitizeUrl,
        GroupByPipe,
        ConverterActivityPipe,
        ActivityDifficultyPipe,
        RoundDownPipe,
        PermitTypePipeAnnual,
        PermitTypePipeDay,
        PermitTypePipeOther,
        StripHtmlPipe
    ]
})
export class PipesModule { }
