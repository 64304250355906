import { Component, Input } from '@angular/core';

@Component({
  selector: 'divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent {

  @Input() direction : 'up' | 'down' = 'up';
  @Input() underNavigation : 'yes' | 'no' = 'no';

}
