import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';

@Component({
  selector: 'events-hero',
  templateUrl: './events-hero.component.html',
  styleUrls: ['./events-hero.component.scss']
})
export class EventsHeroComponent implements OnInit {
  
  constructor() { 

  }

  ngOnInit() : void {


  }

}
