<div class="wrapper">
    <div class="inner container">
        <!-- <landing-about></landing-about> -->
        <div class="section-heading">
            <i class="material-icons">circle</i>
            <p id="locations_container">Regions</p>
        </div>
        <div class="splach-locations">
            <div class="d-flex flex-colunm align-items-center">
                <!-- Location 1 -->
                <div class="location">
                    <div [routerLink]="['/location/jonkershoek/1']" class="location-img" style="background-image: url('../../../../../assets/images/landing/landing-eco_site-location-jonkershoek.png');">

                    </div>
                    <div class="location-details p-1">
                        <h6 class="text-sm text-bold text-caps">
                            Jonkershoek
                        </h6>
                        <ul class="site-activities list-style-none">
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/MTB.svg" alt="">
                                <span class="">MTB Trails</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Distance.svg" alt="">
                                <span class="">Trail Running</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Hiking.svg" alt="">
                                <span class="">Hiking</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Fishing.svg" alt="">
                                <span class="">Fishing</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Accommodation.svg" alt="">
                                <span class="">Accommodation</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Events.svg" alt="">
                                <span class="">Events</span>
                            </li>
                        </ul>
                        <div class="action">
                            <p [routerLink]="['/location/jonkershoek/1']" class="sm-action-text text-xl-p">
                                view location
                            </p>
                            <div class="action-decoration"></div>
                        </div>
                    </div>
                </div>
                <!-- Location 2 -->
                <div class="location">
                    <div class="location-details p-1">
                        <h6 class="text-sm text-bold text-caps">
                            Garden Route
                        </h6>
                        <ul class="site-activities list-style-none">
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/MTB.svg" alt="">
                                <span class="">MTB Trails</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Distance.svg" alt="">
                                <span class="">Trail Running</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Hiking.svg" alt="">
                                <span class="">Tsitsikamma Hiking Trail</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Events.svg" alt="">
                                <span class="">Events</span>
                            </li>
                        </ul>
                        <div class="action">
                            <p [routerLink]="['/location/garden route/5']" class="sm-action-text">
                                view location
                            </p>
                            <div class="action-decoration d-right"></div>
                        </div>
                    </div>
                    <div [routerLink]="['/location/garden route/5']" class="location-img" style="background-image: url('../../../../../assets/images/landing/landing-eco_site-location-garden-route.png');">

                    </div>
                </div>
                <!-- Location 3 -->
                <div class="location">
                    <div [routerLink]="['/location/lowveld/2']" class="location-img" style="background-image: url('../../../../../assets/images/landing/landing-location-eco_site-lowveld.png');">

                    </div>
                    <div class="location-details p-1">
                        <h6 class="text-sm text-bold text-caps">
                            Lowveld
                        </h6>
                        <ul class="site-activities list-style-none">
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/MTB.svg" alt="">
                                <span class="">MTB Trails</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Distance.svg" alt="">
                                <span class="">Trail Running</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Hiking.svg" alt="">
                                <span class="">Hiking</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Fishing.svg" alt="">
                                <span class="">Fishing</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Events.svg" alt="">
                                <span class="">Events</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Horse Riding.svg" alt="">
                                <span class="">Horse Riding</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Accommodation.svg" alt="">
                                <span class="">Accommodation</span>
                            </li>
                            <li class="site-activity d-flex align-items-center">
                                <img width="40" src="../../../../../assets/images/icons/new/Bird Watching.svg" alt="">
                                <span class="">Bird Watching</span>
                            </li>
                        </ul>
                        <div class="action">
                            <p [routerLink]="['/location/lowveld/2']" class="sm-action-text">
                                view location
                            </p>
                            <div class="action-decoration"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>