<div class="permits-container section">
    <section class="top-image" [ngStyle]="{'background-image': 'url(' + site?.static?.image +')'}">
        <div class="des-container container">
            <h5 class="page-name">{{site?.name}} permits</h5>
        </div>
    </section>
    <section class="permits">
        <div class="section-heading">
            <i class="material-icons">circle</i>
            <p>Annual Permits</p>
        </div>

        <div class="permit-cards-container">
            <div *ngFor="let permit of permitTypes | permittype_annual;  let i = index" class="permit-card" [ngClass]="{'alternate-color': i % 2 != 0}">
                <span class="permit-type">Annual Permit</span>
                <h3 class="card-title">{{permit.name}}</h3>
                <div class="includes">
                    <p class="title">Your permit include:</p>
                    <div class="desc-cont">
                        <ul *ngIf="permit.description.length != '' else sorry" class="includes-list">
                            <li>{{permit.description}}</li>
                        </ul>
                        <ng-template #sorry class="sorry">
                            <div>
                               Sorry we are working on a clear discription, please contact us for more information before purchase of permit.
                            </div>
                         </ng-template>
                    </div>
                </div>
                <h3>R{{permit.baseCost}}</h3>
                <br>
                <p class="cartText" [routerLink]="['/checkout',site?.name,siteId,permit.id,'permit']"> <span class="material-icons cart-icon">shopping_cart</span>Add to Cart</p>
            </div>
        </div>

        <div class="section-heading">
            <i class="material-icons">circle</i>
            <p>Day Permits</p>
        </div>

        <div class="permit-cards-container">
            <div *ngFor="let permit of permitTypes | permittype_day;  let i = index" class="permit-card" [ngClass]="{'alternate-color': i % 2 != 0}">
                <span class="permit-type">Once Off / One Day Permits</span>
                <h3 class="card-title">{{permit.name}}</h3>
                <div class="includes">
                    <p class="title">Your permit include:</p>
                    <div class="desc-cont">
                        <ul *ngIf="permit.description.length != '' else sorry" class="includes-list">
                            <li>{{permit.description}}</li>
                        </ul>
                        <ng-template #sorry class="sorry">
                            <div>
                                Sorry we are working on a clear discription, please contact us for more information before purchase of permit.
                            </div>
                         </ng-template>
                    </div>
                </div>
                <h3>R{{permit.baseCost}}</h3>
                <br>
                <p class="cartText" [routerLink]="['/checkout',site?.name,siteId,permit.id,'permit']"><span class="material-icons cart-icon">shopping_cart</span> Add to Cart</p>
            </div>
        </div>

        <div class="section-heading"><i class="material-icons">circle</i>
            <p>Other Permits</p>
        </div>

        <div class="permit-cards-container">
            <div *ngFor="let permit of permitTypes | permittype_other;  let i = index" class="permit-card" [ngClass]="{'alternate-color': i % 2 != 0}">
                <span class="permit-type">Special permits</span>
                <h3 class="card-title">{{permit.name}}</h3>
                <div class="includes">
                    <p class="title">Your permit include:</p>
                    <div class="desc-cont">
                        <ul *ngIf="permit.description.length != '' else sorry" class="includes-list">
                            <li>{{permit.description}}</li>
                        </ul>
                        <ng-template #sorry class="sorry">
                            <div>
                                Sorry we are working on a clear discription, please contact us for more information before purchase of permit.
                            </div>
                         </ng-template>
                    </div>
                </div>
                <h3>R{{permit.baseCost}}</h3>
                <br>
                <p class="cartText" [routerLink]="['/checkout',site?.name,siteId,permit.id,'permit']"> <span class="material-icons cart-icon">shopping_cart</span> Add to Cart</p>
            </div>
        </div>

    </section>
    <section class="rules">
        <div class="small-descript">
            <h1> Rules and regulations </h1>
            <p>IMBA developed the &ldquo;Rules of the Trail&rdquo; to promote responsible and courteous conduct on shared-use trails. Keep in mind that conventions for yielding and passing may vary in different locations, or with traffic conditions. These
                rules have been adapted for the purposes of trails which traverse land of MTO Forestry (Pty) Ltd.</p>
            <ol>
                <li>No helmet, no ride!</li>
                <li>Unless granted permission by MTO Trails, no permit means no ride. Obtain a day&nbsp; permit or an annual permit as required. Both permits are available to purchase at the access gate, <a href="http://www.mto.group">www.mto.group</a> or
                    via the myMTO mobile app. Keep permit with you at all times.</li>
                <li>Ride Open Trails: Respect trail and road closures.&nbsp; Ask access control guards for clarification if you are uncertain about the status of a trail. Do not trespass on private land or on forestry operations land.</li>
                <li>Leave No Trace: Be sensitive to the dirt beneath you. Wet and muddy trails are more vulnerable to damage than dry ones. When the trail is soft, consider other riding options. This also means staying on existing trails and not creating
                    new ones. Don&rsquo;t cut switchbacks. Be sure to pack out at least as much as you pack in.</li>
                <li>Control Your Bicycle: Inattention for even a moment could put you and others at risk. Understand and obey all signage and recommendations, and ride within your limits.</li>
                <li>Yield Appropriately: Do your utmost to let your fellow trail users know you&rsquo;re coming &mdash; a friendly greeting or bell ring are good methods. Try to anticipate other trail users as you ride around corners. Cyclists should yield
                    to other non-motorised trail users, unless the trail is clearly signed for bike-only travel. Cyclists traveling downhill should yield to ones headed uphill, unless the trail is clearly signed for one-way or downhill-only traffic. In
                    general, strive to make each pass a safe and courteous one.</li>
                <li>Never Scare Animals: Animals are easily startled by an unannounced approach, a sudden movement or a loud noise. Give animals enough room and time to adjust to you. Disturbing wildlife and livestock is not acceptable.</li>
                <li>Plan Ahead: Know your equipment, your ability and the area in which you are riding and prepare accordingly. Strive to be self-sufficient: keep your equipment in good repair and carry necessary supplies for changes in whether or other conditions.
                    Always wear a helmet and appropriate safety gear.</li>
                <li>Take note of emergency phone numbers.</li>
                <li>Adhere to FDI (fire) warnings: During the months of September-April in the Cape and May &ndash; November in the Lowveld be aware of the danger of fire. If uncertain contact the fire protection services listed in the emergency contact numbers.
                    If you see any signs of fire that look unusual (i.e. there is nobody on site managing the fire), contact the Lowveld and Escarpment Fire Protection Association on 0137527593 and exit the plantation/s as quickly as possible.</li>
                <li>NO SMOKING:&nbsp; Smoking whilst being on MTO Trails is strictly prohibited.</li>
                <li>Avoid operational areas and activities: Riders are at no time to be within 1km from any situation that may appear to be operational i.e. harvesting activity or from operational vehicles; and not be within 1 km of any active operational
                    area or areas indicated as operational.</li>
                <li>MTB riders must have a valid annual number plate on their bike or proof of purchase of a valid permit. &nbsp;Runners, hikers and obstacle runners must carry a valid permit.&nbsp;</li>
                <li>All children under the age of 16 must be accompanied by an adults on the trails.&nbsp;</li>
                <li>Do not use the trails alone.</li>
                <li>It is your responsibility to get relevant emergency numbers and other safety service details and use them. Every person using the trails must have a valid permit and have the mySOS app downloaded.</li>
            </ol>
        </div>
        <div class="small-descript">
            <h1>Indemnity for MTO Trails</h1>
            <p class="xmsonormal">By purchasing an MTO Trails permit or being on the trails, visitors acknowledge and give consent that this full indemnity applies once entering the MTO venue/trail.</p>
            <p class="xmsonormal">MTO Trails visitors/permit holders acknowledge that they visit the premises/trails of MTO Trails entirely at their own risk, and are cognisant and accept the real dangers and risks that are associated with visiting/using these trails for purposes
                including mountain biking, walking, running and other features/facilities at MTO Trails in addition to risks arising from activities/ presence of wild and dangerous animals/ reptiles, birds, insects and the risk of suffering bodily harm,
                injury or death, theft and/or loss to property which may arise as a risk of the activities and/or presence. MTO Trails permit holders/visitors accept that there are no lifesaving or medical facilities provided by MTO Trails. MTO Trails
                permit holders/visitors furthermore confirm that they are capably fit, knowledgeable and experienced to use, take part, compete in the necessary activities and features at MTO Trails.</p>
            <p class="xmsonormal">All MTO Trails permit holders/visitors waive all claim/s of whatsoever cause or nature whether being of a negligent nature or not, or loss howsoever arising against the land managers, MTO Forestry (Pty) Ltd, their associates, employees or
                any person or company connected whether directly or indirectly with the running of the venue/trails and fellow guests/invitees, whether arising from an act of commission or omission on the part of those hereby indemnified or any one of
                them.
            </p>
            <p class="xmsonormal">MTO Trails permit holders/visitors further acknowledge that they read and agree to adhere to the Rules and Regulations relating to being on MTO Trails property.</p>
        </div>
    </section>
</div>

<div class="discover-section section">
    <discover-splash></discover-splash>
</div>

<div class="sponsors-section section">
    <sponsors></sponsors>
</div>