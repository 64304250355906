<form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input matInput class="form-control" formControlName="email">
        <mat-error *ngIf="submitted && fc.email.errors">
            <span *ngIf="fc.email.errors.required">Please input your email address</span>
            <span *ngIf="fc.email.errors.email">Please input a valid email address</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput class="form-control" [type]="hide ? 'password' : 'text'" formControlName="password">
        <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon (click)="hide = !hide" >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
        <mat-error *ngIf="submitted && fc.password.errors">
            <span *ngIf="fc.password.errors.required">Please input a password</span>
        </mat-error>
    </mat-form-field>
    <div class="forgot-pass-wrapper"> <span [routerLink]="['/account/forgot-password']">Forgot Password?</span></div>
    <button mat-button type="submit" class="standard-blue-btn sign-in-btn" [class.spinner]="savingForm" [disabled]="savingForm">Sign In</button>
</form>