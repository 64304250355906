const iconLocation =  "../../../assets/images/sponsors/"

export const SPONSORS_STATIC = [
    {
        name: 'general',
        images: [
            iconLocation + "home/boigen-logo.jpg",        
            iconLocation + "home/CN primary - 15cm.jpg",        
            iconLocation + "home/fnb-logo.jpg",        
            iconLocation + "home/ibex-logo.jpeg",        
            iconLocation + "home/Large logo long-01.png",        
            iconLocation + "home/toyota-logo.jpg"        
        ]
    },
    {
        name: 'Garden Route',
        images: [
            iconLocation + "garden-route/boigen-logo.jpg",        
            iconLocation + "garden-route/bikeshop-logo.jpg",        
            iconLocation + "garden-route/KNYSNA TOYOTA LOGO.png",        
            iconLocation + "garden-route/Talon-Tough.png",        
            iconLocation + "garden-route/bear-logo.jpg",        
            iconLocation + "garden-route/nuk-logo.jpg",        
            iconLocation + "garden-route/Pam-Golding-New-Logo.jpg",        
            iconLocation + "garden-route/club-100 Logo.jpeg",
        ]
    },
    {
        name: 'jonkershoek',
        images: [
            iconLocation + "jonkershoek/CN primary - 15cm.jpg",
            iconLocation + "jonkershoek/fnb-logo.jpg",
            iconLocation + "jonkershoek/ibex.jpeg",
            iconLocation + "jonkershoek/Large logo long-01.png",
            iconLocation + "jonkershoek/toyota-logo.jpg",
        ]
    },
    {
        name: 'lowveld',
        images: [
            iconLocation + "lowveld/subaru.png",
            iconLocation + "lowveld/white-river-crossing-logo.jpg",
            iconLocation + "lowveld/muse-lowveld.png",
        ]
    },
    {
        name: 'white river',
        images: [
            iconLocation + "lowveld/subaru.png",
            iconLocation + "lowveld/white-river-crossing-logo.jpg",
            iconLocation + "lowveld/muse-lowveld.png",
        ]
    },
    {
        name: 'tsitsikamma',
        images: [
            iconLocation + "tsitsikamma/Clear Background TTA Logo.png",
            iconLocation + "tsitsikamma/GreenFlagLogo800dpi.png",
        ]
    },
    {
        name: '_Jonkershoek_CapeNature',
        images: [
            iconLocation + "capenature/logo.png",
        ]
    },

]