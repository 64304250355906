<div class="wrapper">
    <div class="inner container">
        <div class="col-item img-col">
            <div class="image-wrapper">
                <img src="../../../../../assets/images/landing/mto_trails-mobile-app_image_1.png" />
            </div>
        </div>
        <div class="col-item app-content text text-white">
            <div class="logo-containter">
                <img width="50px" src="../../../../../assets/images/logo/logo-white.png" />
                <h3>myMTO</h3>
            </div>
            <span class="av-font heading">Discover a world of exceptional experiences that MTO creates for you.</span>
            <p>Our myMTO App is useful all-in-one tool for your trail use </p>
            <ul class="app-uses">
                <li>Purchase and show your permits</li>
                <li>Instant access to trail maps</li>
                <li>Fools pin starting points</li>
                <li>Book accommodation</li>
                <li>View events</li>
            </ul>
            <div class="action">
                <i class="material-icons-outlined mr-1">arrow_circle_right</i>
                <span class="sm-action-text">
            download for free now
        </span>
            </div>
            <div class="icons-wrapper">
                <img class="icon" (click)="openLink('https://itunes.apple.com/za/app/mymto/id1423559831', true)" src="../../../../../assets/images/playstore/app-store.svg" />
                <img class="icon g-play-icon" (click)="openLink('https://play.google.com/store/apps/details?id=group.mto.mymto', true)" src="../../../../../assets/images/playstore/google-play.svg" />
            </div>
        </div>
    </div>
</div>