<div class="wrapper">
    <div class="inner">
        <div class="img-container">
            <img src="{{accommodation?.featuredImage}}" alt="">
        </div>
        <div class="paradise-info">
            <h1>{{accommodation?.name}}</h1>
            <p class="s-heading">{{accommodation?.site?.name}}</p>
            <p class="par" [innerHtml]="accommodation?.description"></p>
            <!-- <p class="s-heading">Self catering & guesthouse options available</p>   -->
            <div class="booking-form">
                <div class="form-button">
                    {{accommodation?.externalLink}}
                    <!--<div (click)="openLink(accommodation?.externalUrl, true)" class="action">
                                            <span class="material-icons-outlined">arrow_circle_right</span>
                                            <p>book now</p>
                                        </div>-->
                    <div (click)="openManual(accommodation)" class="action">
                        <span class="material-icons-outlined">arrow_circle_right</span>
                        <p>book now</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider-accommodation-up">
        <divider [direction]="'up'" [underNavigation]="'no'"></divider>
    </div>
</div>