
export class CustomFormInputValidator{

    constructor(inputType:InputType , input:any, required:boolean){    
        this._inputType = inputType;
        this._value = input;
        this._required = required
    }

    private _inputType: InputType;
    private _isValid: boolean = true;
    private _value: any;
    private _required: boolean;

    public ResponseMessage: string = "";

    public get Value(){
        return this._value;
    }
 
    public set Value(value:any){
        this._value = value;
        if(value != null && value != ""){
            this._isValid = this.validateInput();
        }
    }
 
    public get IsValid(){
        return this._isValid;
    } 
    
    public get Required(){
        return this._required;
    }
 
    validateInput(){
        
        //Required
        if(this.Required){
            this._isValid = false;

            if(this.Value  == null || this.Value as string == "")
            this.ResponseMessage = "Required";
        }

        //Email validation
        if (this._inputType == InputType.Email){
            
            let email = this.Value as string
            const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if(email == "" || !regularExpression.test(String(email).toLowerCase())){
                console.log("testing email")          
                this.ResponseMessage = "Please enter a valid email"
                return false;
            }
        }

        //Password
        if (this._inputType == InputType.Password){
            
            const password = this.Value as string
            const minNumberofChars = 8;
            const maxNumberofChars = 16;
            const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

            if(password.length < minNumberofChars){
                this.ResponseMessage = "Password must be atleast 8 characters long" 
                return false;
            }

            if(password.length > maxNumberofChars){
                this.ResponseMessage = "Password cannot be more that 16 characters long" 
                return false;
            }

            if(!regularExpression.test(password)) {
                this.ResponseMessage = "Password should contain atleast one number and one special character";
                return false;
            }

        }

        //Select
        if(this._inputType == InputType.Select){
            
            let option = this.Value as string;
            if(option.includes("Select")){
                this.ResponseMessage = "You need to select an option";
                return false;                     
            }
        }

        console.log(this)
        this.ResponseMessage = "";
        return true;

    }
}

export enum InputType{
    PlainText= 0, Email = 1, Password = 2 , Select = 3,
}