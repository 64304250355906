<div class="contact-form">
  <div class="form-field">
    <label for="">Name</label>
    <input [(ngModel)]="name.Value" type="text" name="name" placeholder="Name">
    <div class="error" *ngIf="!name.IsValid" >{{name.ResponseMessage}}</div>
  </div>
  <div class="form-field">
    <label for="">Contact Number</label>
    <input [(ngModel)]="contactNumber.Value" type="text" name="phone" placeholder="(+27)">
    <div class="error" *ngIf="!contactNumber.IsValid">{{contactNumber.ResponseMessage}}</div>
  </div>
  <div class="form-field">
    <label for="">Email</label>
    <input [(ngModel)]="emailAddress.Value" type="email" name="email" placeholder="example@email.com">
    <div class="error" *ngIf="!emailAddress.IsValid" >{{emailAddress.ResponseMessage}}</div>
  </div>
  <div class="form-field">
    <label for="">Message</label>
    <textarea [(ngModel)]="message.Value" name="message" id="message" rows="2"></textarea>
    <div class="error" *ngIf="!message.IsValid" >{{message.ResponseMessage}}</div>
  </div>
  <div class="form-field">
    <select [(ngModel)]="location.Value" name="location" id="message" rows="2">
      <option value="{{location.Value}}" selected >{{location.Value}}</option>
      <option value="Jonkershoek">Jonkershoek</option>
      <option value="Garden Route">Garden Route</option>
      <option value="Lowveld">Lowveld</option>
    </select>
    <div class="error" *ngIf="!location.IsValid" >{{location.ResponseMessage}}</div>
  </div>
  <div class="action-container">
    <div class="action" (click)="onSubmit()">
      <span class="material-icons-outlined">arrow_circle_right</span>
      <p>send</p>
    </div>
  </div>
</div>  