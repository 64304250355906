<div class="wrapper">
    <div class="inner">
        <div class="splash">
            <div class="splash-img-container">
            </div>
            <div class="splash-content">
                <div class="content-container">
                    <div class="content">
                        <h1 class="text-xl-h">
                            About
                        </h1>
                        <p class="text-xl-p">
                            MTO strives to be the leader in sustainable, world class adventure destinations whilst conserving the environment and uplifting the local community.
                        </p>
                    </div>
                </div>
                <divider [direction]="'up'" [underNavigation]="'no'"></divider>
            </div>
        </div>
        <div class="about-container container">
            <div class="logo-block">
                <a href="https://www.mto.group">
                    <img src="../../../../assets/images/logo/mto-group-logo.png" width="400" style="cursor: pointer; margin-bottom: 3rem;" alt="">
                </a>
            </div>
            <br>
            <div class="about-white-wrapper">
                <div class="about-wrapper-black-block-1">
                    <div class="about-inner-black-block" (click)="openLink('http://www.mto.group')">
                        <div class="block">
                            <span class="black-block-heading">Who are we?</span>
                            <span class="black-block-body">
                                MTO trails is an entity of the MTO Group whose sectors include forestry, manufacturing,
                                agriculture and energy. We have internationally accredited plantations in the Western
                                Cape,
                                Eastern Cape and Mpumalanga provinces through which we provide recreational access to
                                world class adventure destinations whilst conserving the environment and uplifting the
                                local communities.
                            </span>
                        </div>
                    </div>
                    <div class="image-wrapper">
                        <img class="image" src="../../../../../assets/images/about/1.jpg" width="690" height="400" />
                    </div>
                </div>
                <div class="about-wrapper">
                    <div class="about-text-wrapper ">
                        <div class="about-text-inner block ">
                            <span class="about-body-inner">Rehabilitating and maintaining natural vegetation within
                                afforested
                                areas is a
                                focus
                                for MTO. MTO has made a considerable investment in creating world-class trails for
                                hiking,
                                mountain
                                biking and trail running. Our lush ecotourism forests in Lowveld, Garden Route and
                                Jonkershoek
                                provide entertainment for all fitness levels.</span>
                        </div>
                    </div>
                </div>
                <div class="about-wrapper-black-block-1" (click)="openLink('http://www.mto.group')">
                    <div class="image-wrapper">
                        <img class="image" src="../../../../../assets/images/about/3.jpg" width="400" height="400" />
                    </div>
                    <div class="about-inner-black-block ">
                        <div class="block">
                            <span class="black-block-heading">What we offer?</span>
                            <span class="black-block-body">
                                MTO trails Stretching from Mountains to Oceans, MTO Trails provides and promotes world class hiking, mountain biking, trail running, bird-watching, 
                                fishing, events and comfortable accommodation facilities. MTO Trails creates and provides adventure experiences to outdoor enthusiasts ensuring exceptional customer 
                                experiences and a safe passage through the Forestry Plantations and conservation regions. We also offer safe, clearly marked routes, affording all eco enthusiasts the opportunity to explore our hidden treasures
                            </span>
                        </div>
                    </div>
                </div>
                <div class="about-wrapper-final ">
                    <span class="body block">Intrepid MTB enthusiasts have been entering MTO forests and terrains in the
                        Garden
                        Route,
                        Jonkershoek and Lowveld for years, exploring trails and discovering new exciting places. Instead
                        of
                        barring them from the private land, the people at MTO have worked closely with each regions
                        community to
                        formalise trails and create world-class mountain biking, trail running and hiking
                        experiences.</span>
                    <!-- <div class="image-wrapper">
                    <img class="image" src="../../../../../assets/images/about/5.jpg" width="690" height="400" />
                </div> -->
                </div>
                <div class="about-wrapper-final-image">
                    <div class="img"></div>
                </div>
            </div>
        </div>
    </div>
</div>