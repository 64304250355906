import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';

@Component({
  selector: 'events-splash',
  templateUrl: './events-splash.component.html',
  styleUrls: ['./events-splash.component.scss']
})
export class EventsSplashComponent implements OnInit {


  eventGridColSpan: number = 1;
  events : any = [];
  siteId;

  constructor(private ecoService : EcoService, private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.siteId = this.route.snapshot.paramMap.get('id');
    this.resiseGrid();
    this.ecoService.events$.subscribe((events)=>{ 
      this.events = events;
    })
  }


  resiseGrid(){
    if(window.innerWidth < 870) this.eventGridColSpan = 1;
    if(window.innerWidth > 870) this.eventGridColSpan = 2;
    if(window.innerWidth > 1300) this.eventGridColSpan = 3;
  }

}
