import { Injectable } from '@angular/core';
import { CartPackage } from 'src/app/domains/cart/cartPackage';
import { CartPermit } from 'src/app/domains/cart/cartPermit';
import { Order } from 'src/app/domains/cart/order';
import { StateService } from 'src/app/state/state.service';
import { GlobalConfig } from '../../configuration/global.config';
import { HttpService } from '../networking/http.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private stateService: StateService, private HttpService: HttpService) {
    this.initialize();
  }

  async initialize() {
    this.stateService.user$.subscribe(async user => {
      if (user && user.uid) {
        await this.loadActiveCart(user.uid);
      }
    });
  }

  async loadActiveCart(uid: string) {
    this.stateService.cart = await this.HttpService.get(`${GlobalConfig.APIS.OLDAPI}orders/orders/active?Uid=${uid}`);
  }

  async addPermitToCart(permit: CartPermit) {
    await this.HttpService.post(`${GlobalConfig.APIS.OLDAPI}eco/permits`, permit)
      .then(async activeOrderId => {
        if (activeOrderId) {
          await this.loadActiveCart(permit.adventurerUid);
        }
      });
  }

  async addPackageItemToCart(packageItem: CartPackage) {
    return await this.HttpService.post(`${GlobalConfig.APIS.OLDAPI}eco/packages`, packageItem)
      .then(async (bookingId: number) => {
        return bookingId;
      });
  }

  async removeFromCart(orderDetailsId: number, cart: Order) {
    await this.HttpService.delete(`${GlobalConfig.APIS.OLDAPI}orders/orderDetails/${orderDetailsId}`)
      .then(() => {
        //remove item from state
        let updatedOrderDetails = cart.orderDetails.filter(e => e.id !== orderDetailsId);
        cart.orderDetails = updatedOrderDetails;
        this.stateService.cart = cart;
        //get true data from api
        this.stateService.user$.subscribe(async user => {
          if (user && user.uid) {
            await this.loadActiveCart(user.uid);
          }
        })
      }).catch(error => { return error });
  }
}


