<div class="wrapper">
    <div class="inner">
        <div class="splash-container">
            <div class="splash-img-container">
            </div>
            <div class="splash-content">
                <div class="content-container container">
                    <div class="content">
                        <h1 class="text-xl-h">
                            Cape Nature Jonkershoek
                        </h1>
                        <p class="message">
                            Jonkershoek Nature Reserve, which includes the smaller Assegaaibosch Nature Reserve, lies near the town of Stellenbosch in the southwestern Cape. The Reserve comprises the imposing Jonkershoek mountains and portions of the upper Jonkershoek valley.
                        </p>
                        <div class="action">
                            <span class="material-icons-outlined">arrow_circle_right</span>
                            <p class="buy-now" [routerLink]="['/checkout','Cape Nature',8,0,'permit']">Buy Permit</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>