<ng-template #noBookings>
    <div class="purchased-items-container">
        <p class="purchased-items-text">
            You Have No Bookings!
        </p>
    </div>
</ng-template>

<ng-template #noPermits>
    <div class="purchased-items-container">
        <p class="purchased-items-text">
            You Have No Permits!
        </p>
    </div>
</ng-template>

<div class="container">
    <div class="account-contain">
        <div class="sidebar">
            <h1>My Account</h1>
            <a [routerLink]="['/account', 'profile']" routerLinkActive="active">Profile Details</a>
            <a [routerLink]="['/account', 'history']" routerLinkActive="active">Purchase History</a>
            <!-- <a href="#">Billing Details</a> -->
        </div>
        <div class="content">
            <mat-tab-group *ngIf="stateService?.purchases$ | async as userBookings; else loader" animationDuration="0ms" [disableRipple]="true">
                <mat-tab label="Bookings">
                    <div *ngIf="userBookings.bookings?.length > 0;else noBookings">
                        <div class="account-card" *ngFor="let booking of userBookings.bookings">
                            <div class="header">
                                <div class="card-title">
                                    <h2>{{booking.activity.name}}</h2>
                                    <p>Booking No: <strong>{{booking.id}}</strong></p>
                                </div>
                                <div class="card-detail">
                                    <!-- <div class="badge active">
                        Active
                    </div> -->
                                    <!-- <span>365 Days Remaining</span> -->
                                </div>
                            </div>

                            <div class="body">
                                <div>
                                    <div class="field">
                                        <!-- <label *ngIf="expression">No of People Allowed:</label> -->
                                        <strong>{{booking.activity.pax}}</strong>
                                    </div>
                                    <div class="field">
                                        <label>Valid From:</label>
                                        <strong>{{booking.startDate | date:'dd LLLL yyyy'}}</strong>
                                    </div>
                                    <div class="field">
                                        <label>Valid Until:</label>
                                        <strong>{{booking.endDate | date:'dd LLLL yyyy'}}</strong>
                                    </div>
                                </div>
                                <!-- <div>
                  <button mat-button class="btn-stroke">
                    Download Receipt
                  </button>
                </div> -->
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Permits">
                    <div *ngIf="userBookings.permits?.length > 0;else noPermits">
                        <div class="account-card" *ngFor="let permit of userBookings.permits">
                            <div class="header">
                                <div class="card-title">
                                    <h2>{{permit.permitType.name}}</h2>
                                    <!-- <p>Booking No: <strong>8830</strong></p> -->
                                </div>
                                <div class="card-detail">
                                    <div class="badge" [ngClass]="{'active': permit.status == 'Active'}">
                                        {{permit.status}}
                                    </div>
                                </div>
                            </div>

                            <div class="body">
                                <div>
                                    <div class="field">
                                        <label>No of People Allowed:</label>
                                        <strong>{{permit.pax}}</strong>
                                    </div>
                                    <div class="field">
                                        <label>Valid From:</label>
                                        <strong>{{permit.validFrom | date:'dd LLLL yyyy'}}</strong>
                                    </div>
                                    <div class="field">
                                        <label>Valid Until:</label>
                                        <strong>{{permit.validTo | date:'dd LLLL yyyy'}}</strong>
                                    </div>
                                </div>
                                <!-- <div>
                  <button mat-button class="btn-stroke">
                    Download Receipt
                  </button>
                </div> -->
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <ng-template #loader>
                <loader></loader>
            </ng-template>

        </div>
    </div>
</div>