import { Pipe, PipeTransform } from '@angular/core';
import { DifficultyType } from '../../utilities/enums/global-enums';

@Pipe({
  name: 'permittype_annual'
})
export class PermitTypePipeAnnual implements PipeTransform {

  transform(permits: any): any {

    if(permits) {
      return permits.filter(permit => {
          return permit.daysValid == 365;
      });
  }

  }

}

@Pipe({
  name: 'permittype_day'
})
export class PermitTypePipeDay implements PipeTransform {

  transform(permits: any): any {

    if(permits) {
      return permits.filter(permit => {
          return permit.daysValid == 1;
      });
  }
  }
}


@Pipe({
  name: 'permittype_other'
})
export class PermitTypePipeOther implements PipeTransform {

  transform(permits: any): any {

    if(permits) {
      return permits.filter(permit => {
          return (permit.daysValid != 365 && permit.daysValid != 1);
      });
  }
  }
}