import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { GlobalConfig } from '../../configuration/global.config';

@NgModule({
    imports: [
        AngularFireModule.initializeApp(GlobalConfig.Firebase.firebaseEnvironment),
    ],
    exports: [
        AngularFireModule,
        AngularFireAuthModule,
    ]
})
export class FirebaseModule { }