import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'accommodation-paradise-falls-more-info',
  templateUrl: './accommodation-paradise-falls-more-info.component.html',
  styleUrls: ['./accommodation-paradise-falls-more-info.component.scss']
})
export class AccommodationParadiseFallsMoreInfoComponent implements OnInit {

  @Input() accommodation;

  constructor(private router : Router) { }

  ngOnInit(): void {
  }

  public openLink(link: string, newTab: boolean = false) {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      this.router.navigateByUrl(link);
    }
  }

}
