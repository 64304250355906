<div class="splash-container">
    <div class="splash-img-container">
    </div>
    <div class="splash-content">
        <div class="content-container">
            <div class="content">
                <h1 class="text-xl-h">
                    Shop
                </h1>
                <!-- <p class="text-xl-p">
                    Facilisis eu ac, mollis praesent vestibulum cras sit suscipit amet. Nec in sem dictum lorem volutpat, 
                    justo lacus tortor. Dolor vitae congue vulputate.
                </p> -->
            </div>
        </div>
    </div>
</div>