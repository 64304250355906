import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'landing-splash',
  templateUrl: './landing-splash.component.html',
  styleUrls: ['./landing-splash.component.scss']
})
export class LandingSplashComponent implements OnInit {

  public slideConfig;
  index : number = 0;
  
  interval;

  backgroundImages:Array<string> = [
    "../../../../../assets/images/landing/landing-bg-2.jpg",
    "../../../../../assets/images/landing/landing-bg-3.jpg", 
    "../../../../../assets/images/landing/landing-bg-1.jpg"
    , 
  ];
  imgUrl:string;

  ngOnInit() {
    this.startCarousal();
  }
  
  startCarousal(){
    this.setmage();
    this.interval = setInterval(()=>{
      this.moveRight();
    },8000);
  }

  private setmage()  {
    this.imgUrl = this.backgroundImages[this.index];

  }

  public moveLeft(){
    this.index--;

    if(this.index < 0){
      this.index = 2;
    }
    this.setmage();
  }


  public moveRight(){
    this.index++;
    if(this.index > 2){
      this.index = 0;
    }
    
    this.setmage();

  }


  scroll(){
    document.getElementById('locations_container').scrollIntoView({behavior: "smooth"});    
  }

}
