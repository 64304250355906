
import { UialertsService } from './ui/uialerts.service';
import { AuthenticationService } from './authentication/authentication.service';
import { APP_INITIALIZER } from '@angular/core';
import { AuthenticatedGuard } from '../guards/authenticated-guard';

import { StateService } from 'src/app/state/state.service';
import { EcoService } from './resources/ecotourism/eco.service';
import { EcentricService } from './payments_and_orders/ecentric.service';
import { ActivitiesService } from './resources/Activities/activities.service';
import { AccountService } from './account/account.service';

export const Services = [
    UialertsService,
    { provide: APP_INITIALIZER, multi: true, deps: [AuthenticationService], useFactory: (authService) => () => null },
    AuthenticatedGuard,
    EcentricService,
    StateService,
    EcoService,
    ActivitiesService,
    AccountService
]
