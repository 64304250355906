import { ContactInfo } from './contact-card/contact-card.component';
import { Component } from '@angular/core';
import { PublicContactType } from 'src/app/library/utilities/enums/global-enums';


@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  public contactCards:ContactInfo[] = contactInfo;
  constructor() { }

}


const contactInfo: ContactInfo[] = [
  {
    title: "Jonkershoek",
    number: "082 688 7783",
    email: "kyle.g@mto.co.za",
    coordinates: "33.9708° S, 18.9973° E",
    actionLink: "/",
  },
  {
    title: "Garden Route",
    number: "083 968 5111",
    email: "douw@mto.co.za",
    coordinates: "33.9708° S, 18.9973° E",
    actionLink: "/",
  },
  {
    title: "Lowveld",
    number: "013 751 3021 / 083 968 5111",
    email: "douw@mto.co.za",
    coordinates: "33.9708° S, 18.9973° E",
    actionLink: "/",
  },
];
