import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { SITES_STATIC } from 'src/app/library/static/sites';

@Component({
  selector: 'mountain-biking-trails-splash',
  templateUrl: './mountain-biking-trails-splash.component.html',
  styleUrls: ['./mountain-biking-trails-splash.component.scss']
})
export class MountainBikingTrailsSplashComponent implements OnInit {

  site;
  staticSite;
  constructor(public route: ActivatedRoute, private ecoService : EcoService) { }

  ngOnInit(): void {

    this.getSite(this.route.snapshot.paramMap.get('id'));
  }

  
  private async getSite(id : any) { 
    try {
      this.staticSite = (SITES_STATIC.filter((site)=> site.id == id))[0];
      this.site = (await this.ecoService.getSite(id));
    } catch (error) {
      
    }
  }

}
