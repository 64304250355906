import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Media } from 'src/app/domains/site';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {mediaItem: Media}, private sanitizer: DomSanitizer) { 
  }
}
