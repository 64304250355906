import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { resolve } from 'dns';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { SITES_STATIC } from "../../../../library/static/sites";

@Component({
  selector: 'location-welcome',
  templateUrl: './location-welcome.component.html',
  styleUrls: ['./location-welcome.component.scss']
})
export class LocationWelcomeComponent implements OnInit {

  siteId;
  site; 
  siteImage:string;
  static : any = SITES_STATIC;

  constructor(private route: ActivatedRoute, private ecoService : EcoService, private router : Router) { }

  ngOnInit(): void {


    this.route.params.subscribe(async params => {
      this.siteId = params.id;
      await this.getSite(this.siteId)

      this.site['static'] = (this.static.filter(x => x.id == this.siteId))[0];
      this.siteImage = this.site.static.image;

    });
  }

  private async getSite(id : any) { 
    try {
      this.site = await this.ecoService.getSite(id);
       console.log("sites =>", this.site);
      Promise.resolve();
    } catch (error) {
      
    }
  }

  scroll(el: string, route : boolean = false) {
    // console.log(el);
    
    if(route){
      this.router.navigate([el]);
      return;
    } 

    document.getElementById(el).scrollIntoView({behavior: "smooth"});
    
    // document.getElementById(el).scrollIntoView();
  }


}
