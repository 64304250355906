import { SITES_STATIC } from 'src/app/library/static/sites';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { GalleryImagesSource } from 'src/app/library/temporary/gallery-images-source';

@Component({
  selector: 'app-permits',
  templateUrl: './permits.component.html',
  styleUrls: ['./permits.component.scss']
})
export class PermitsComponent implements OnInit {

  siteId : any;
  site : any;
  permitTypes : any;


  images;

  constructor(private route: ActivatedRoute, private ecoService : EcoService) { }

  async ngOnInit() {
    this.route.params.subscribe((data)=>{
      this.siteId = data.id;      
      this.getSite(this.siteId);
      this.getAllPermitTypes(this.siteId);
      this.getImages(data.site)
    });
  }

  private getImages(site) { 
    switch (site) {
      case 'jonkershoek':
        this.images = GalleryImagesSource.jonkersHoek.splice(0,6);
        break;
      
      case 'gardenRoute':
        this.images = GalleryImagesSource.gardenRoute.splice(0,6);
          break;

      case 'lowveld':
        this.images = GalleryImagesSource.lowveld.splice(0,6);
            break;
    
      default:
        break;
    }
  }

  private async getSite(id : number) { 
    try {
      this.site = await this.ecoService.getSite(id);
      this.site['static'] = (SITES_STATIC.filter(x => x.id == this.siteId))[0];

    } catch (error) {
      
    }
  }

  private async getAllPermitTypes(id : number) { 
    try {
      this.permitTypes = ((await this.ecoService.getPermitTypes(id)).value).filter((permit) => permit.status.id == 5);
      console.log('permit types', this.permitTypes);
    } catch (error) {
      
    }
  }

}
