import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: "accommodation-paradise-falls",
  templateUrl: "./accommodation-paradise-falls.component.html",
  styleUrls: ["./accommodation-paradise-falls.component.scss"],
})
export class AccommodationParadiseFallsComponent implements OnInit {
  @Input() accommodation;

  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log(this.accommodation);
  }

  public openLink(link: string, newTab: boolean = false) {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      this.router.navigateByUrl(link);
    }
  }

  public openManual(accomodation) {
    console.log('accom', accomodation);
    if (accomodation.id == 86) {
      window.open("https://book.nightsbridge.com/33108?nbid=337", "_self");
    } else if (accomodation.id == 109) {
      window.open("https://book.nightsbridge.com/32891", "_self");
    }
  }
}
