import { Component, OnInit,Input} from '@angular/core';
import { Wordpress } from 'src/app/domains/content/wordpress';

@Component({
  selector: 'news-site-articles',
  templateUrl: './news-site-articles.component.html',
  styleUrls: ['./news-site-articles.component.scss']
})
export class NewsSiteArticlesComponent implements OnInit {

  @Input() public mainArticle: Wordpress;
  @Input() public otherArticles: Wordpress[];

  public selectedFilter: { value: string, viewValue: string };
  public filteritem: { value: string, viewValue: string }[] = [
    { value: 'date_asc', viewValue: 'Date Published (ASC)' },
    { value: 'date_desc', viewValue: 'Date Published (DESC)' }
  ];

  constructor() {

  }

  ngOnInit() {

  }
  
  filterChange(event){
    switch (event.value) {
      case "date_desc":
        this.otherArticles = this.otherArticles.sort((d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime()).reverse();
        // this.otherArticles$ = of(data);
        break;
      case "date_asc":
        this.otherArticles = this.otherArticles.sort((d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime());
        break;
    };
  }

}
