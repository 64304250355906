export function loadActivityTypeAsset(name: string) {
    let asset: string;
    switch (name) {
        case "Hiking":
            asset = "assets/images/icons/icn-hiking@2x.png";
            break;
        case "Mountain Biking":
            asset = "assets/images/icons/icn-biking@2x.png";
            break;
        case "Trail Running":
            asset = "assets/images/icons/icn-running@2x.png";
            break;
        case "Scenic Drive":
            asset = "assets/images/icons/icn-driving@2x.png";
            break;
        case "Fishing":
            asset = "assets/images/icons/icn-angling@2x.png";
            break;
    }
    return asset;
}

export function loadActivityCategoryDisplayName(id) {
    let string: string;
    switch (id) {
        //trail parks and hiking trails
        case 1:
            string = "Trail Parks";
            break;
        //multip day hjiking trails
        case 2:
            string = "Hiking Trails";
            break;
        //events
        case 3:
            string = "Experiences";
            break;
        //accomodation
        case 4:
            string = "Accomodation";
            break;
    }
    return string;
}

export function loadActivityCategoryCssSelector(id) {
    let string: string;
    switch (id) {
        //trail parks and hiking trails
        case 1:
            string = "trailparks";
            break;
        //multip day hjiking trails
        case 2:
            string = "hikingtrails";
            break;
        //events
        case 3:
            string = "experiences";
            break;
        //accomodation
        case 4:
            string = "accomodation";
            break;
    }
    return string;
}

export function loadActivityCategoryAsset(id) {
    let asset: string;
    switch (id) {
        //trail parks
        case 1:
            asset = "assets/images/icons/trailParks.svg";
            break;
        //hiking trails
        case 2:
            asset = "assets/images/icons/hikingTrails.svg";
            break;
        //experiences
        case 3:
            asset = "assets/images/icons/experiences.svg";
            break;
        //accommodation
        case 4:
            asset = "assets/images/icons/accommodation.svg";
            break;
    }
    return asset;
}

export function loadDifficultyAsset(name: string) {
    let asset: string;
    switch (name) {
        case "I Can Win":
            asset = "assets/images/icons/icn-win@2x.png";
            break;
        case "Bring It On":
            asset = "assets/images/icons/icn-bring@2x.png";
            break;
        case "Hurt Me Plenty":
            asset = "assets/images/icons/icn-hurt@2x.png";
            break;
        case "Hardcore":
            asset = "assets/images/icons/icn-hardcore@2x.png";
            break;
        case "Nightmare":
            asset = "assets/images/icons/icn-nightmare@2x.png";
            break;
    }
    return asset;
}

export function loadDifficultyCssSelector(name: string) {
    let string: string;
    switch (name) {
        case "I Can Win":
            string = "icanwin";
            break;
        case "Bring It On":
            string = "bringiton";
            break;
        case "Hurt Me Plenty":
            string = "hurtmeplenty";
            break;
        case "Hardcore":
            string = "hardcore";
            break;
        case "Nightmare":
            string = "nightmare";
            break;
    }
    return string;
}

export function loadActivityTypeCssSelector(name: string) {
    let string: string;
    switch (name) {
        case "Hiking":
            string = "hiking";
            break;
        case "Mountain Biking":
            string = "mountainbiking";
            break;
        case "Trail Running":
            string = "trailrunning";
            break;
        case "Scenic Drive":
            string = "scenicdrive";
            break;
        case "Fishing":
            string = "fishing";
            break;
    }
    return string;
}