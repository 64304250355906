<div class="container">
    <div class="account-contain">
        <div class="sidebar">
            <h1>My Account</h1>
            <a [routerLink]="['/account', 'profile']" routerLinkActive="active">Profile Details</a>
            <a [routerLink]="['/account', 'history']" routerLinkActive="active">Purchase History</a>
        </div>
        <div class="content">
            <form *ngIf="(stateService.user$ | async) && !submittingForm;else loader" [formGroup]="profileForm" (ngSubmit)="profileFormSubmit()">
                <div class="form-field">
                    <div class="profile-col">
                        <mat-form-field appearance="outline">
                            <mat-label>Email Address</mat-label>
                            <input matInput class="form-control" formControlName="email">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Full Name</mat-label>
                            <input matInput class="form-control" formControlName="fullName">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>RSA ID</mat-label>
                            <input matInput class="form-control" formControlName="rsaId">
                        </mat-form-field>
                    </div>
                    <div class="profile-col">
                        <mat-form-field appearance="outline">
                            <mat-label>Contact Number</mat-label>
                            <input matInput class="form-control" formControlName="contactNo">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Emergency Contact Number</mat-label>
                            <input matInput class="form-control" formControlName="emergencyContactNo">
                        </mat-form-field>
                    </div>
                </div>
                <button mat-button [disabled]="!profileForm.dirty" type="submit" class="btn update-btn">Save</button>
            </form>
            <ng-template #loader>
                <loader></loader>
            </ng-template>
        </div>

    </div>

</div>