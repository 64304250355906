<div class="wrapper">
    <div class="inner">
        <div class="get-in-touch-form">
            <div class="form-container">
                <p class="heading">Let's stay in touch</p>
                <p class="paragraph">
                    Get updated on events, trails, helpful tips, and stories from our visitors delivered right to your inbox.
                </p>
                <div class="email-wrapper">
                    <input class="input-field" type="email" name="email" id="email" placeholder="yourname@email.com">
                    <div class="icon-wrapper">
                        <img src="../../../../../assets/images/icons/icon-paper-plane.png" alt="paper-plane">
                    </div>
                </div>
            </div>
        </div>
        <div class="image-wrapper">
            <div class="img-container">
                <img src="../../../../../assets/images/landing/get-in-touch-bg.png" alt="contact-background">
            </div>
        </div>
    </div>
</div>
