<div class="splash-container">
    <div class="splash-img-container" [ngStyle]="{'background-image': 'url(' + imgUrl + ')'}">
    </div>
    <div class="splash-content">
        <div class="content-container">
            <div class="content">
                <h1 class="text-xl-h">MTO Trails
                </h1>
                <p class="text-xl-p">
                    Stretching from Mountains to Oceans, MTO Trails provides and promotes world-class hiking, mountain biking, trail running, bird-watching, fishing, events, and comfortable accommodation facilities
                </p>
                <div class="content-action d-flex justify-content-start align-items-center cursor-pointer">
                    <div class="material-icons down-arrow anim-bounce">
                        arrow_circle_down
                    </div>
                    <p class="text-xl-p" (click)="scroll()" class="sm-action-text p-1">
                        start your adventure
                    </p>
                </div>
            </div>
        </div>
        <div class="slider-indicator">
            <span (click)="moveLeft()" class="material-icons-outlined icon right">chevron_left</span>
            <div [ngClass]="{'indicator-active': index == 0}"></div>
            <div [ngClass]="{'indicator-active': index == 1}"></div>
            <div [ngClass]="{'indicator-active': index == 2}"></div>
            <span (click)="moveRight()" class="material-icons-outlined icon right">chevron_right</span>
        </div>
        <!-- <div class="divider-jagged"></div> -->
    </div>

</div>