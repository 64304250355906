import { SITES_STATIC } from 'src/app/library/static/sites';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { galleryImage } from '../gallery/gallery-images-grid/gallery-images-grid.component';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  loadingState : boolean = true; 
  images:galleryImage[];

  theSite:any;
  siteName: "jonkershoek" | "garden-route" | "lowveld" ;


  constructor(private route : ActivatedRoute) { }

  ngOnInit(): void {

    this.getSite()    
    // this.getSite_MotivationalQoute();
    
    this.route.queryParams.subscribe((q)=>{

      this.scroll(q.Location);
      // this.siteName = q.location;

    });

    setTimeout(() => {
      this.loadingState = false;
    }, 3000);
  }

  getSite() {
    this.route.params.subscribe(params =>{
      var siteName = params.site;
      if(siteName == "lowveld") siteName = "White River"; //Patch

      this.theSite = SITES_STATIC.filter(site => site.name == siteName)[0];
    })
  }

  scroll(el: string) {
    setTimeout(()=>{
      if(el){
        document.getElementById(el).scrollIntoView({behavior: "smooth"});
      }
    },1000)
  }

}
