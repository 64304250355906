import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cape-more-info',
  templateUrl: './cape-more-info.component.html',
  styleUrls: ['./cape-more-info.component.scss']
})
export class CapeMoreInfo implements OnInit {

  Days: Array<any> = [
    {
      name: "Animals",
      description: `Mammals include leopard, honey badger, baboon, klipspringer, mongoose and numerous smaller animals like mice, shrews and rats. Most of these are shy, however, and
      seldom seen. Large raptors such as black eagle, the occasional fish eagle and spotted eagle owl occur, while kingfishers and typical fynbos birds (sugarbird, orangebreasted sunbird and protea seed-eater) are more abundant. 
      On warm days rock agama lizards can be seen basking in the sun. Berg adder, puff adder, boomslang and Cape cobra are fairly common and hikers should be alert.`
    },
    {
      name: "Climate",
      distance: 14.7,
      time: 7,
      description:
        `Climate The climate of the Jonkershoek area is fairly typical of
        the south-western Cape. Summers are warm to very hot and strong south-easterly winds prevail, creating a serious fire hazard. The winters are cold, often with gale-force
        north-westerly winds. The mountainous topography has a significant effect on the rainfall, which is of the highest in South Africa.
        
        <br>
        <br>

        Snow is not unusual on the higher peaks during thewinter months.
        Hikers should note hat at higher altitudes the weather conditions may be quite different from lower in the valley and can change rapidly, becoming dangerous.`
    },
    {
      name: "Vegetation",
      description: `
      The natural vegetation of the Jonkershoek area is mainly mountain fynbos. More than 1 100 plant species are known to occur, of which a number are rare and/or endemic to
      the area. Distinctive species are Protea repens, P. neriifolia, mountain cypress, as well as various ericas and restios.
      Several relic forest communities occur in narrow, moistkloofs where they are relatively sheltered from fire. 
      
      <br>
      <br>

      Dense riparian vegetation grows along the banks of the Eerste River. Oak trees, although not indigenous, have been allowed to remain in Assegaaibosch because of their special historical value.
      Large pine plantations are a distinctive feature of the valley and occur on property neighbouring the Nature Reserves. 
      The Reserves’ management programmes include control of hakea and other invasive alien plants that threaten the fynbos.`
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

