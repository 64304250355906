import { FirebaseError } from "firebase/app";

export function ParseFirebaseError(error: FirebaseError): FirebaseError {
    switch (error.code) {
        case "auth/user-not-found":
            error.message = "Email address is not registered."
            return error;

        case "auth/wrong-password":
            error.message = "Wrong password, please try again.";
            return error;


        case "auth/too-many-requests":
            error.message = "Account locked or deleted, please try again after a while.";
            return error;

        default:
            return error;
    }
}