<div class="top-contain">
    <div class="container">
        <!-- <a (click)="locationBack()" class="back-link">
            <span class="material-icons">
        keyboard_arrow_left
      </span> Back
        </a> -->
        <h2>
            <mat-icon>shopping_cart</mat-icon>Cart Checkout
        </h2>
    </div>
</div>
<div class="checkout-contain" *ngIf="checkoutActivity$ | async as checkoutActivity">

    <div class="container">
        <div class="checkout-detail">
            <!-- Booking start -->
            <div class="checkout-wrap">
                <h3>{{checkoutActivity.name}}</h3>
            </div>
            <hr />
            <form [formGroup]="bookingCheckoutForm">
                <div class="checkout-wrap">
                    <div class="field-3-col">
                        <label>Number of adventurers</label>
                        <div class="field-contain">
                            <div class="field-right">
                                <div class="ctrl">
                                    <div (click)="decrementAdventurers()" class="ctrl-button ctrl-button-decrement">-
                                    </div>
                                    <!-- <div class="ctrl-counter">
                    <input class="ctrl-counter-input" maxlength="30" type="text" value="0" />
                    <div class="ctrl-counter-num">0</div>
                  </div> -->
                                    <mat-form-field appearance="outline" class="qnty-form-field adventurers-form-field">
                                        <input matInput NumberOnly="true" class="form-control  adventurers-input"
                                            formControlName="selectedAdventurers" class="" maxlength="30" type="text" />
                                        <mat-error
                                            *ngIf="submitted && bookingCheckoutFormControls.selectedAdventurers.errors">
                                        </mat-error>
                                    </mat-form-field>
                                    <div (click)="incrementAdventurers()" class="ctrl-button ctrl-button-increment">+
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field-3-col">
                        <label>Select a date</label>
                        <div class="field-contain">
                            <ng-template #dateDisabled>
                                <ng-container *ngIf="!dateSelectionLoading;else datesLoading">
                                    <div class="disabledCalendarContainer">
                                        <p>Input a number of adventurers to check availibility!</p>
                                    </div>
                                </ng-container>
                            </ng-template>
                            <ng-template #datesLoading>
                                <div class="loadingCalendarContainer">
                                    <div class="running">
                                        <div class="outer">
                                            <div class="body">
                                                <div class="arm behind"></div>
                                                <div class="arm front"></div>
                                                <div class="leg behind"></div>
                                                <div class="leg front"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Checking Availability...</p>
                                    <!-- <p><mat-icon>directions_run</mat-icon>Checking Availability...</p> -->
                                </div>
                            </ng-template>

                            <div class="field-right" *ngIf="!dateSelectionDisabled;else dateDisabled">
                                <mat-form-field class="datepicker">
                                    <input [matDatepickerFilter]="dateFilter" [min]="minBookingDate"
                                        placeholder="DD/MM/YYYY" class="form-control date-picker-input" matInput
                                        formControlName="selectedDate" [matDatepicker]="picker" />
                                    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="checkout-wrap">
                <div class="field-3-col">
                    <label>Package type</label>
                    <div class="field-contain">
                        <input matInput class="form-control" readonly value="{{corePackage.name}}"
                            [ngClass]="selectedPackage">
                        <!-- <mat-select class="form-control" formControlName="selectedPackage" [ngClass]="selectedPackage">
              <mat-option [value]="packageType" *ngFor="let packageType of checkoutActivity.packageTypes">
                6 Day Hike
              </mat-option>
            </mat-select> -->
                    </div>
                    <div class="field-right">
                        <h5>Cost</h5>
                        <strong>R {{(packageTotal$ | async) | number:'1.2-2'}}</strong>
                    </div>
                </div>
                <div class="field-3-col" *ngIf="corePackage.description">
                    <label></label>
                    <div class="field-contain-custom">
                        <h6>Package Description</h6>
                        <p>
                            {{corePackage.description}}
                        </p>
                    </div>
                    <div class="field-contain-custom  cancelation-policy">
                        <h6>Cancelation Policy</h6>
                        <ul>
                            <li>
                                Reservations cancelled less than 21 days prior to arrival (trail starting date) will
                                incur a cancellation fee of 100% of payments made.
                            </li>
                            <li>
                                Reservations cancelled 21 days or longer before trail starting date will be refunded
                                70% of the booking fees.
                            </li>
                            <li>
                                There will be no refunds applicable for reservations not cancelled timeously, 
                                and for non-arrival of persons.
                            </li>
                            <li>
                                A 30% postponement fee is applicable to confirmed bookings changed between 
                                10 – 21 days before arrival (trail starting dater hut per day is 24).
                            </li>
                        </ul>
                    </div>
                </div>

                <div *ngIf="addons$ | async as addons">
                    <div *ngIf="addons.length > 0">
                        <div class="field-3-col" style="padding-bottom: 0;">
                            <label>Select your add-ons</label>
                        </div>
                        <div class="field-3-col" fxHide.lt-lg>
                            <label></label>
                            <div class="field-contain">
                                <h5 style="margin-bottom: 0;">Quantity</h5>
                            </div>
                            <div class="field-right">
                                <h5 style="margin-bottom: 0;">Cost</h5>
                            </div>
                        </div>
                        <div class="field-3-col bordered" *ngFor="let addon of addons">
                            <label>{{addon.packageName}}</label>
                            <div class="field-contain justify-content-center">
                                <div class="ctrl">
                                    <div class="ctrl-button ctrl-button-decrement"
                                        (click)="decrementAddonCount(addon, addons)">-</div>
                                    <div class="ctrl-counter">
                                        <input NumberOnly="true" [(ngModel)]="addon.adventurerCount"
                                            (ngModelChange)="updateAddonTotal($event, addon, addons)"
                                            class="ctrl-counter-input" maxlength="30" type="text" value="0" />
                                        <div class="ctrl-counter-num">0</div>
                                    </div>
                                    <div class="ctrl-button ctrl-button-increment"
                                        (click)="incrementAddonCount(addon, addons)">+</div>
                                </div>
                            </div>
                            <div class="field-right">
                                <strong>R {{addon.totalCost}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Booking end !-->
        </div>
        <div class="checkout-side">
            <div class="checkout-wrap">
                <h4>Booking Summary</h4>
            </div>
            <hr />
            <div class="checkout-wrap">
                <div class="field">
                    <label>Adventurers</label>
                    <span>{{selectedAdventurers}}</span>
                </div>
                <div class="field">
                    <label>Date</label>
                    <span
                        *ngIf="packageDisplayDuration$ | async as packageDisplayDuration">{{(packageDisplayDuration.start
                        | date: 'dd/MM/yyyy')}}
                        - {{(packageDisplayDuration.end | date: 'dd/MM/yyyy')}}</span>
                </div>
            </div>
            <hr />
            <div class="checkout-wrap">
                <h5>Package Type</h5>
                <div class="field-titled">
                    <label>{{corePackage.name}}</label>
                    <span> <strong>R {{(packageTotal$ | async) | number:'1.2-2'}}</strong></span>
                </div>
            </div>
            <hr />
            <div *ngIf="addons$ | async as addons">
                <div class="checkout-wrap" [ngClass]="!hasPortage(addons) ? 'hidden' : ''">
                    <h5>Portage</h5>
                    <div class="field-titled" *ngFor="let addon of addons">
                        <label>{{addon.packageName}}</label>
                        <span><strong>R {{addon.totalCost | number:'1.2-2'}}</strong></span>
                    </div>
                    <hr />
                </div>
            </div>
            <div class="checkout-wrap">
                <div class="field total">
                    <label>Total</label>
                    <span><strong>R {{(bookingTotal$ | async) | number:'1.2-2'}}</strong></span>
                </div>
                <div class="cart-btns">
                    <button type="button" (click)="addBookingToCart()" mat-button class="btn-cart">
                        Add to Cart
                        <mat-icon>shopping_cart</mat-icon>
                    </button>
                    <button (click)="ecentricPayment()" mat-button class="btn-payment">
                        Proceed to Payment
                    </button>
                </div>
                <div class="terms">
                    By continuing you agree and accept the
                    <a [routerLink]="['/terms-of-service']">Terms and Conditions</a> of this purchase.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="checkout-note">
        Your booking will be linked to your account. Gain access at trail gate by printing your access code or
        displaying it via the myMTO app.
    </div>
</div>