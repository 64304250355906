import { EventsComponent } from './../../../components/pages/events/events.component';
import { HikingTrailsFaqsTrailsInfoComponent } from './../../../components/pages/hiking-trails-faqs/hiking-trails-faqs-trails-info/hiking-trails-faqs-trails-info.component';
import { AccommodationComponent } from './../../../components/pages/accommodation/accommodation.component';
import { HikingTrailsComponent } from './../../../components/pages/hiking-trails/hiking-trails.component';
import { LocationComponent } from './../../../components/pages/location/location.component';
import { MountainBikingTrailsComponent } from './../../../components/pages/mountain-biking-trails/mountain-biking-trails.component';
import { Route } from '@angular/router';
import { BaseLayoutComponent } from 'src/app/components/layouts/base-layout/base-layout.component';
import { AboutComponent } from 'src/app/components/pages/about/about.component';
import { ContactComponent } from 'src/app/components/pages/contact/contact.component';
import { GalleryComponent } from 'src/app/components/pages/gallery/gallery.component';
import { LandingComponent } from 'src/app/components/pages/landing/landing.component';
import { MerchandiseComponent } from 'src/app/components/pages/merchandise/merchandise.component';
import { NewsComponent } from 'src/app/components/pages/news/news.component';
import { PageNotFoundComponent } from 'src/app/components/pages/page-not-found/page-not-found.component';
import { PermitsComponent } from 'src/app/components/pages/permits/permits.component';
import { RegisterComponent } from 'src/app/components/pages/authentication/register/register.component';
import { ForgotPasswordComponent } from 'src/app/components/pages/authentication/forgot-password/forgot-password.component';
import { LoginComponent } from 'src/app/components/pages/authentication/login/login.component';
import { ProfileComponent } from 'src/app/components/pages/account/profile/profile.component';
import { HistoryComponent } from 'src/app/components/pages/account/history/history.component';
import { BaseCheckoutComponent } from 'src/app/components/layouts/base-checkout/base-checkout.component';
import { PermitCheckoutComponent } from 'src/app/components/pages/checkout/permit-checkout/permit-checkout.component';
import { BookingCheckoutComponent } from 'src/app/components/pages/checkout/booking-checkout/booking-checkout.component';
import { AccomodationCheckoutComponent } from 'src/app/components/pages/checkout/accomodation-checkout/accomodation-checkout.component';
import { CartComponent } from 'src/app/components/pages/checkout/cart/cart.component';
import { HikingTrailsFaqsComponent } from 'src/app/components/pages/hiking-trails-faqs/hiking-trails-faqs.component';
import { AuthenticatedGuard } from '../../guards/authenticated-guard';
import { CapeNatureComponent } from 'src/app/components/pages/cape-nature/cape-nature';
import { CustomizeHikeComponent } from 'src/app/components/pages/hiking-trails/customize-hike/customize-hike.component';

export const ROUTES: Route[] = [
    {
        path: "",
        component: BaseLayoutComponent,
        children: [
            { path: "", component: LandingComponent},
            { path: "about", component: AboutComponent },
            { path: "contact-us", component: ContactComponent},
            { path: "permits", component: PermitsComponent},
            { path: "location", component: LocationComponent},
            { path: "mtb-trails", component: MountainBikingTrailsComponent},
            { path: "hiking-trails-faqs", component: HikingTrailsFaqsComponent},
            { path: "merchandise", component: MerchandiseComponent },
            { path: "news", component: NewsComponent },
            { path: "news/:article", component: NewsComponent },
            { path: "event", component: EventsComponent },
            { path: "event/:id", component: EventsComponent },
            { path: "gallery", component: GalleryComponent },
            { path: "gallery/:site/:id", component: GalleryComponent },
            { path: "accommodation", component: AccommodationComponent},
            { path: "account/login", component: LoginComponent },
            { path: "account/register", component: RegisterComponent },
            { path: "account/forgot-password", component: ForgotPasswordComponent },

            { path: "account/profile", component: ProfileComponent, canActivate :[AuthenticatedGuard]},
            { path: "account/history", component: HistoryComponent, canActivate :[AuthenticatedGuard]},

            { path: "location/:site/:id/accommodation/:name/:accId", component: AccommodationComponent},
            { path: "location/:site/:id", component: LocationComponent},
            { path: "location/:site/:id/tsitsikamma", component: HikingTrailsComponent},
            { path: "customize", component: CustomizeHikeComponent},
            { path: "location/:site/:id/cape-nature", component: CapeNatureComponent},
            { path: "trailinfo", component: HikingTrailsFaqsComponent},
            { path: "location/:site/:id/trails", component: MountainBikingTrailsComponent},
      
            { path: "permits/:site/:id", component: PermitsComponent},
            { path: "cart", component: CartComponent, canActivate :[AuthenticatedGuard]},
            {
                path: "checkout",
                component: BaseCheckoutComponent,
                canActivate :[AuthenticatedGuard],
                children: [
                  { path: ":site/:siteId/:permitTypeId/permit", component: PermitCheckoutComponent },
                  { path: ":activityId/booking", component: BookingCheckoutComponent },
                  { path: ":activityId/accomodation", component: AccomodationCheckoutComponent },       
                
                ]
              },



        ]
    },
    { path: "404", component: PageNotFoundComponent },
    { path: "**", redirectTo: "/404" }
]

