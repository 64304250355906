<div class="splash-section section">
    <events-hero></events-hero>
</div>
<div class="events-content-section section">
    <div class="info">
        <events-info [event]="eventOnView"></events-info>
    </div>
    <div class="other-events">
        <div *ngFor="let event of events" style="margin-bottom:2rem">
            <events-card [event]="event"></events-card>
        </div>
    </div>
</div>
<div class="stay-in-touch-section section">
    <stay-in-touch></stay-in-touch>
</div>
<div class="sponsors-section section">
    <sponsors></sponsors>
</div>