import { Injectable} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GlobalConfig } from '../../configuration/global.config';
import * as firebase from 'firebase/compat/app';
import { StateService } from 'src/app/state/state.service';
import { AccountService } from '../account/account.service';
import { HttpService } from '../networking/http.service';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    constructor(
        private fireAuth        : AngularFireAuth,
        private stateService    : StateService,
        private accountService  : AccountService
    ) {
        this.listenForAuthStateChanges();
    }

    public async loginWithEmailAndPassword(credentials: { email: string, password: string }): Promise<firebase.default.auth.UserCredential> {
        return this.fireAuth.signInWithEmailAndPassword(credentials.email, credentials.password);
    }

    public async resetPassword(email: string): Promise<void> {
        return this.fireAuth.sendPasswordResetEmail(email);
    }

    public async googleLogin(): Promise<boolean> {
        return await this.fireAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider().addScope('email'))
            .then((credential) => {
                return true;
            }).catch((error: firebase.default.FirebaseError) => {
                console.log(error);
                return false;
            });
    }

    public async facebookLogin(): Promise<boolean> {
        return await this.fireAuth.signInWithPopup(new firebase.default.auth.FacebookAuthProvider().addScope('email'))
            .then((credential) => {
                return true;
            }).catch((error: firebase.default.FirebaseError) => {
                console.log(error);
                return false;
            });
    }
    public async logout(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.fireAuth.signOut()
                .then(() => {
                    resolve(true);
                })
                .catch((error) => { reject(false) });
        })
    }

    public async register(profile : any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let user = await this.fireAuth.createUserWithEmailAndPassword(profile.emailAddress, profile.password);
                resolve(user);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    private async listenForAuthStateChanges() {
        this.fireAuth.onAuthStateChanged(async (user) => {
            if (user) {
                this.stateService.authenticated = {
                    email     : user.providerData[0].email,
                    provider  : user.providerData[0].providerId,
                    uid       : user.uid
                };
                this.stateService.token = await user.getIdToken();
            } else {
                this.stateService.authenticated = null;
                this.stateService.token = null;
            }
        });
    }
}
