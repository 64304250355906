import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'converterActivity'
})

export class ConverterActivityPipe implements PipeTransform {
  transform(array: any[]): any[] {
    return Object.values(
        array.reduce((arr, activity) => {
            if (!arr[activity.name]) arr[activity.name] = { ...activity, activities: [] };
            arr[activity.name].activities.push(activity);
          return arr;
        }, {})
      );
  }
}