<ng-template #emptyCart>
  <div class="cart-items-container">
      <p class="empty-cart-text"><mat-icon class="cart-icon">shopping_cart</mat-icon>Your Cart Is Empty</p>
  </div>

</ng-template>
<div class="container">
  <div>
    <div class="cart-container" *ngVar="(stateService.cart$ | async) as cart">
      <div class="cart-menu">
        <p class="order-cart-text">Order Cart</p>
        <p class="cart-items-text">Cart Items ({{cart?.orderDetails?.length || '0'}})</p>
      </div>
      <div class="cart-items">
        <div class="cart-items-container" *ngIf="cart?.orderDetails?.length > 0;else emptyCart">
          <div class="cart-item" *ngFor="let item of cart?.orderDetails">
              <div class="cart-item-header">
                <p class="cart-item-title"><b>{{item.name}}</b></p>
                <p class="cart-item-action-remove">
                  <mat-icon (click)="removeFromCart(item.id, cart)" class="remove-icon">delete_outline</mat-icon>
                </p>
              </div>
              <div class="cart-item-footer">
                <p class="cart-item-date">VALID:<span
                    class="cart-item-date-value"><b>{{item.startDate | date: 'dd/MM/yyyy'}}</b></span></p>
                <p class="cart-item-amount">QUANTITY:<span class="cart-item-amount-value"><b>{{item.qty}}</b></span></p>
                <p class="cart-item-total">
                  <span class="cart-item-total-value"><b>R {{item.totalCost | number:'1.2-2' }}</b>
                  </span>
                </p>
              </div>
            </div>
        </div>
        <div class="cart-total">
          <div class="cart-total-container">
            <p class="cart-total-display">
              <span class="total-text-wrapper">
                <span class="cart-total-label">CART TOTAL:</span>
                <span class="cart-total-value">
                  <b>R {{(cart?.totalCost  || '0.00') | number:'1.2-2'}}</b>
                </span>
              </span>
            </p>
          </div>
        </div>
        <div class="button-wrapper">
          <button (click)="eccentricPayment()" mat-button class="checkout-button">Proceed to Payment</button>
        </div>
      </div>
    </div>
  </div>
</div>