<div *ngIf="$percent | async as perc else loadBar;">
  <mat-spinner mode="determinate" [value]="perc"></mat-spinner>
  <h5>{{loaderText}}</h5>
</div>
<ng-template #loadBar>
  <div>
  <mat-spinner></mat-spinner>
  <h5>{{loaderText}}</h5>
</div>
</ng-template>
