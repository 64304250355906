<div class="wrapper">
    <div *ngIf="event else noEvent" class="inner container">
        <div class="event-heading">
            {{event.startDate | date:'dd LLLL yyyy'}}
            <h1 class="event-name">
                {{event.name}}
            </h1> 
            {{event.site.name}}
        </div>
        <a target="_blank" [href]="event.featuredImage" >
            <div *ngIf="event.featuredImage else optionTwo " class="event-img-bg" [ngStyle]="{'background-image':'url('+ event.featuredImage+')'}">
            
            </div>
            <ng-template #optionTwo>
                <div *ngIf="event.media[0] else pholder " class="event-img-bg" [ngStyle]="{'background-image':'url('+ event.media[0].url +')'}">
            
                </div>
            </ng-template>        
            <ng-template #pholder>
                <div class="event-img-bg" style="background-image: url('../../../../../assets/images/mto-trails-kogo.png');">

                </div>
            </ng-template>
        </a>
        <p class="event-dics">
            {{event.description}}
        </p>     
        <p class="event-point"><b>Actity Type</b>: {{event.activityType.name}}</p>  
        <p class="event-point"><b>Distance</b>: {{event.distance}} km</p>  
        <p class="event-point"><b>Difficulty</b>: {{event.difficulty.name}} ({{event.difficulty.description}})</p>
        <p><b>Requirments</b></p>
        <p class="event-disc">
            {{event.requirements}}
        </p>
        <br>
        <div class="action-container">
            <a [href]="event.externalUrl" class="action" style="text-decoration: none;">
                <span class="material-icons">arrow_circle_right</span>
            <h4>Join Event</h4>
            </a>
        </div>
    </div>
    <ng-template #noEvent>
        <div class="no-event" style="height: 100%; display: flex; justify-content: center; align-items: center;">
            EVENT NOT FOUND
        </div>
    </ng-template>
</div>
