<div class="login-container container">
    <div class="wrapper">
        <div class="header" [routerLink]="['/']">
            <img class="logo-header" src="../../../../../assets/images/logo/logo.svg" />
        </div>
        <div class="inner">
            <login-form></login-form>
            <div class="external-signin"><button mat-button (click)="googleLogin()"><img src=".././../../../../assets/images/icons/google-icon.svg"/>Sign In with Google</button></div>
            <div class="external-signin facebook" (click)="facebookLogin()"><button mat-button><img src=".././../../../../assets/images/icons/facebook-icon.svg"/>Sign In with Facebook</button></div>
            <div class="register-wrapper">
                <span>Don't have an account? </span> <span class="important" [routerLink]="['/account/register']"> Register account</span>
            </div>
        </div>
    </div>
</div>