import { Location } from '@angular/common';
import { SPONSORS_STATIC } from './../../../library/static/sponsors';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: "sponsors",
  templateUrl: "./sponsors.component.html",
  styleUrls: ["./sponsors.component.scss"],
})
export class SponsorsComponent implements OnInit {
  gridColSpan: number = 2;
  site: string = "general";

  sponsors: string[] = [];

  constructor(private route: ActivatedRoute, private _router: Router) {}

  ngOnInit() {
    this.findNameOfSite();
    this.loadSponsorsImage();
    this.resiseGrid();

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.findNameOfSite();
        this.loadSponsorsImage();
        this.resiseGrid();
      }
    });
  }

  findNameOfSite() {
    this.route.params.subscribe((params) => {
      if (params.site) {
        this.site = params.site;
      } else {
        this.site = "general";
      }
    });

    //Only for Tsitsikamma
    this.route.snapshot.url.map((url) => {
      if (url.path == "tsitsikamma") this.site = "tsitsikamma";
    });
  }

  loadSponsorsImage() {
    try {
      this.sponsors = SPONSORS_STATIC.filter(
        (spon) => spon.name.toLowerCase() == this.site.toLowerCase()
      )[0].images;
    } catch (error) {}
  }

  resiseGrid() {
    if (window.innerWidth < 870) this.gridColSpan = 2;
    if (window.innerWidth > 870) this.gridColSpan = 4;
  }
}
