<span *ngIf="accommodation">
    <div class="parrdise-falls section">
        <accommodation-paradise-falls [accommodation]="accommodation"></accommodation-paradise-falls>
    </div>
    <div class="gallery section">
        <accommodation-paradise-falls-gallery [id]="accommodation.id"></accommodation-paradise-falls-gallery>
    </div>
    <div class="more-info section">
        <accommodation-paradise-falls-more-info [accommodation]="accommodation"></accommodation-paradise-falls-more-info>
    </div>
</span>