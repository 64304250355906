import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trails-beak-down',
  templateUrl: './trails-beak-down.component.html',
  styleUrls: ['./trails-beak-down.component.scss']
})
export class TrailsBeakDownComponent implements OnInit {

  imageLoc = "../../../../../assets/images/tsitsikamma/";
  days: Array<any> = [
    {
      day: 1,
      name: "Nature’s Valley - Kalander Hut",
      distance: 3.4,
      time: 1.3,
      description: `The  first  day  follows  the  eastern  bank  of  the Groot  River  lagoon.  Sounds  from  the  plentiful birdlife and the Indian Ocean at Nature’s Valley accompany  you  through  tall,  dry  indigenous coastal  forest  to  the  Kalander  hut.`,
      highlights: [
        'Tall Dry Coastal Forest',
        'Nature’s Valley Beach and Groot River Mouth'
      ],
      imageSource: this.imageLoc + "d-1.jpg"
    },
    {
      day: 2,
      name: "Kalander - Bloukrans Hut",
      distance: 14.7,
      time: 7,
      description:
        `The day begins with a climb up the escarpment. 
      Panoramic views and changing vegetation 
      herald the arrival of the plateau and its wealth 
      of fynbos. Most of the day’s hike however, will 
      take you through indigenous afromontane forest 
      as you venture inland towards the foothills of 
      the Tsitsikamma Mountains. The Bloukrans 
      hut is situated on the verge of a cliff and offers 
      incredible views of the river gorges below and 
      Peak Formosa and the Tsitsikamma Mountains to 
      the north. A couple of hundred metres beneath 
      the balcony a coca-cola coloured waterfall 
      tumbles away. Here private rock pools and falls 
      provide an awesome swimming experience. A 
      large-spotted genet pair and bushpig male are 
      regular overnight visitors as well.`
      ,
      highlights: [
        'Viewpoints from Apiesrug.',
        'Medium-Moist Plateau Forest.',
        'Staircase Waterfall.',
        'Views of the Bloukrans River Gorge.',
        'Bloukrans Hut & Tolbos River Rock Pools.'
      ],
      imageSource: this.imageLoc + "d-2.jpg"
    },
    {
      day: 3,
      name: "Bloukrans - Keurbos Hut",
      distance: 13.4,
      time: 6,
      description: `
      Day three takes one deep into the Tsitsikamma 
      Mountains and forest. This is a wetter and more 
      remote world where a true sense of isolation begins 
      to take hold. Buffelsbos is where the last buffalo 
      of the Tsitsikamma was shot and killed in 1886. 
      In this eerie and enchanted patch of indigenous 
      forest the trail begins its eastward course through 
      the mountains. The crossing of the Bloukrans River 
      at Waterwitelsgat is always exciting and provides a 
      wonderful swimming and resting place. The rest of 
      the day follows predominantly flat terrain through 
      flower-rich fynbos and relic forest. Shortly before 
      reaching Keurbos hut there are rockpools resembling 
      natural jacuzis at the twintubs. The hut is situated in 
      the wetter Keurbos forest. Here both red and white 
      alder are plentiful and the narina trogon’s plaintiff 
      call is sometimes heard.`
      ,
      highlights: [
        'Buffelsbos.',
        'Waterwitelsgat.',
        'Benebos.',
        'The Twin Tubs.',
        'Keurbos Forest.',
      ],
      imageSource: this.imageLoc + "d-3.jpg"
    },
    {
      day: 4,
      name: "Keurbos - Heuningbos Hut",
      distance: 13.4,
      time: 6,
      description: `
      Day four passes through very diverse habitat types 
      and has a number of exciting river crossings. After an 
      approximate 1,5km gradual descent from Keurbos hut 
      through very tall fynbos the Lottering River is reached. 
      Hikers should proceed slowly up Rushes Pass, pausing 
      regularly to appreciate the majestic views. Once over the 
      saddle, large pockets of untouched indigenous forest 
      become visible in remote mountain regions. The path 
      then descends for a similar distance to follow the western 
      bank of the Elandsbos River for almost a kilometre before 
      reaching the Elandsbos River crossing point. Heuningbos 
      indigenous forest has many hidden secrets awaiting hikers 
      further ahead. After the final descent towards the hut, 
      you will cross the Kleinbos River twice before reaching 
      the Heuningbos Hut. A very pleasant swimming pool lies 
      within rolling distance away.`,
      highlights: [
        'Lottering River.',
        'Views from Rushes Pass.',
        'Elandsbos River.',
        'Heuningbos Forest.',
        'Kleinbos River - very close to Heuningbos Hut.',
        'Keurbos Forest.'
      ],
      imageSource: this.imageLoc + "d-4.jpg"
    },
    {
      day: 5,
      name: "Heuningbos - Sleepkloof Hut",
      distance: 14.2,
      time: 7,
      description: `The longest day of the trail will see hikers negotiate 
      two saddles on route to Sleepkloof hut. Most of the day 
      passes through pristine mountain fynbos where many 
      interesting species occur. Splendid Pass is such an 
      example, named after the beautiful Mimetes splendidus. 
      The wet indigenous forest at Mostertsbos is a stark 
      contrast from the open mountain slopes. The crossing of 
      the Witteklip River marks the day’s half-way point and 
      the second saddle’s ascent begins shortly thereafter. 
      The view from the top of Nademaalsnek is a just reward. 
      The spectacular Storms River gorge, Sleepkloof hut 
      and the final two-kilometre indigenous forest stretch 
      are both visible from that point. Sleepkloof hut nestles 
      on the slopes of a densely forested gorge where wood 
      was dragged out during the woodcutter era. The hut has 
      magnificent views of the Tsitsikamma mountains lurking 
      in the distance.`,
      highlights: [
        'Views from Splendid Pass & Mostertshoogte.',
        'Mosterdsbos.',
        'Witteklip River.',
        'Views from Nademaalsnek.',
        'Indigenous Forest close to Sleepkloof Hut'
      ],
      imageSource: this.imageLoc + "d-5.jpg"
    },
    {
      day: 6,
      name: "Heuningbos - Paul Sauer Bridge",
      distance: 3.2,
      time: 1,
      description:
        `The final day provides hikers with the option of 
      either finishing at Paul Sauer Bridge or Storms 
      River Village. Both route’s share the first 
      kilometre’s walk through dense forest pioneer 
      vegetation. The shorter route to Paul Sauer Bridge 
      is a very easy two kilometre descent through forest 
      pioneer species and fynbos. The slightly longer 
      walk to Storms River Village takes one through 
      tall indigenous forest. This second option forms 
      part of the Plaatbos Forest and joins up with other 
      existing day walks in the area.`,
      highlights: [
        'Plaatbos Indigenous Forest.',
        'Storms River Village or Storms River Bridge (Petroport)'
      ],
      imageSource: this.imageLoc + "d-6.jpg"

    }
  ]

  selectedDay;

  constructor() { }

  ngOnInit(): void {
    this.selectedDay = this.days[0];
  }

  dayChanged(event){
    this.selectedDay = this.days[event.index]
  }
}

