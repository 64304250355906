<div class="top-contain">
    <div class="container">
        <!-- <a (click)="locationBack()" class="back-link">
      <span class="material-icons">
        keyboard_arrow_left
      </span>
      Back</a> -->
        <h2>
            <mat-icon>shopping_cart</mat-icon>Cart Checkout
        </h2>
    </div>
</div>
<div class="checkout-contain" *ngIf="checkoutActivity$ | async as checkoutActivity">

    <div class="container">
        <div class="checkout-detail">
            <!-- Booking start -->
            <div class="checkout-wrap">
                <h3>{{checkoutActivity.name}}</h3>
            </div>
            <hr />
            <form [formGroup]="bookingCheckoutForm">
                <div class="checkout-wrap">
                    <div class="field-3-col">
                        <label>Select a date</label>
                        <div class="field-contain">
                            <ng-template #datesLoading>
                                <div class="loadingCalendarContainer">
                                    <div class="running">
                                        <div class="outer">
                                            <div class="body">
                                                <div class="arm behind"></div>
                                                <div class="arm front"></div>
                                                <div class="leg behind"></div>
                                                <div class="leg front"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Checking Availability...</p>
                                </div>
                            </ng-template>
                            <div class="field-right" *ngIf="!dateSelectionLoading;else datesLoading">
                                <mat-form-field class="datepicker">
                                    <input [matDatepickerFilter]="dateFilter" [satDatepicker]="picker" [min]="minBookingDate" placeholder="DD/MM/YYYY" class="form-control" matInput formControlName="selectedDate" />
                                    <sat-datepicker #picker [rangeMode]="true">
                                    </sat-datepicker>
                                    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
                                    <!-- <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker [calendarHeaderComponent]="customDateHeaderComponent" #picker></mat-datepicker> -->
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="checkout-wrap">
                <div class="field-3-col">
                    <label>Package type</label>
                    <div class="field-contain">
                        <input matInput class="form-control" readonly value="{{accomodationPackage.name}}" [ngClass]="selectedPackage">
                    </div>
                    <div class="field-right">
                        <h5>Cost</h5>
                        <strong>R {{(bookingTotal$ | async) | number:'1.2-2'}}</strong>
                    </div>
                </div>
                <div class="field-3-col" *ngIf="accomodationPackage.description">
                    <label></label>
                    <div class="field-contain-custom">
                        <h6>Package Description</h6>
                        <p>
                            {{accomodationPackage.description}}
                        </p>
                    </div>
                </div>
            </div>

            <!-- Booking end !-->

        </div>
        <div class="checkout-side">
            <div class="checkout-wrap">
                <h4>Booking Summary</h4>
            </div>
            <hr />
            <div class="checkout-wrap">
                <div class="field">
                    <label>Nights</label>
                    <span>{{selectedDuration}}</span>
                </div>
                <div class="field">
                    <label>Date</label>
                    <span>{{(selectedDate?.begin | date: 'dd/MM/yyyy')}}
            - {{(selectedDate?.end | date: 'dd/MM/yyyy')}}</span>
                </div>
            </div>
            <hr />
            <div class="checkout-wrap">
                <h5>Package Type</h5>
                <div class="field-titled">
                    <label>{{accomodationPackage.name}}</label>
                    <span> <strong>R {{(bookingTotal$ | async) | number:'1.2-2'}}</strong></span>
                </div>
            </div>
            <hr />
            <div class="checkout-wrap">
                <div class="field total">
                    <label>Total</label>
                    <span><strong>R {{(bookingTotal$ | async) | number:'1.2-2'}}</strong></span>
                </div>
                <div class="cart-btns">
                    <button type="button" (click)="addBookingToCart()" mat-button class="btn-cart">
            Add to Cart
            <mat-icon>shopping_cart</mat-icon>
          </button>
                    <button (click)="ecentricPayment()" mat-button class="btn-payment">
            Proceed to Payment
          </button>
                </div>
                <div class="terms">
                    By continuing you agree and accept the
                    <a [routerLink]="['/terms-of-service']">Terms and Conditions</a> of this purchase.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="checkout-note">
        Your booking will be linked to your account. Gain access at trail gate by printing your access code or displaying it via the myMTO app.
    </div>
</div>