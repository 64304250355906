<div class="wrapper">
    <div class="inner">
        <div class="container header-container" style="padding-bottom: 4rem;" >
            <h1>Welcome to {{site?.name}}</h1>
            <div class="welcome-info">
                <div *ngFor="let item of site?.static?.locationLink" class="info-point">
                    <img height="100" src="{{item.icon}}" alt="">
                    <div class="point-action">
                        <h2>{{item.name}}</h2>
                        <div class="action">
                            <span class="material-icons">arrow_circle_right</span>
                            <h4 (click)="scroll(item.id,item.route)">view</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <divider [direction]="'down'" [underNavigation]="'no'"></divider>
        <div class="welcome-message" [ngStyle]="{'background-image': 'url(' + site?.static?.image +')'}">
            <div class="overlay">
                <div class="content container">
                    <div class="message">
                        <!-- <h1 class="title">Welcome to {{site?.name}}</h1> -->
                        <p>
                            {{site?.description}}
                        </p>
                        <br>
                        <a class="permits-link" [routerLink]="'/permits/' + site.seoFriendlyName + '/' + site.id">
                            <h2>Buy your permit to enjoy our trails</h2>
                        </a>
                        <!-- <p>
                            The trails are made possible through the partnership between MTO Trails and yourself.
                            <br>
                            <br> Going our ethos of community spirit and generosity and buy your trail permit to enjoy the trails.
                        </p> -->
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="site" [routerLink]="['/location',site.name,site.id, 'trails']" class="action-container container">
                <span class="action">
                    <span class="material-icons">arrow_circle_right</span>
                <h4>view all trails</h4>
                </span>
            </div> -->
        </div>
    </div>
</div>