import { Pipe, PipeTransform } from '@angular/core';
import { ActivityType } from '../../utilities/enums/global-enums';

@Pipe({
  name: 'activityToFriendly'
})
export class ActivityFriendlyStringPipe implements PipeTransform {

  transform(value: ActivityType, args?: any): any {
    switch (value) {
      case ActivityType.Hiking:
        return "Hiking";
      case ActivityType.MTB:
        return "Mountain Biking";
      case ActivityType.Running:
        return "Trail Runnning";
      case ActivityType.Accomodation:
        return "Accomodation";
      case ActivityType.Birding:
        return "Birding";
      case ActivityType.Fishing:
        return "Fishing";
      case ActivityType.Events:
        return "Events";
      case ActivityType.Swimming:
        return "Swimming";
    }
  }

}
