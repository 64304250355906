<section class="splash-section section">
    <merchandise-splash></merchandise-splash>
</section>
<section class="merchandise-section">
    <div class="coming-soon" style="min-height: 60vh; width: 100%; display: grid; place-items: center;">
        <h1>COMING SOON</h1>
    </div>
    <!-- <merchandise-items></merchandise-items> -->
</section>
<div class="stay-in-touch-section section">
    <stay-in-touch></stay-in-touch>
</div>
<section class="sponsors-section section">
    <sponsors></sponsors>
</section>