import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { Activities } from 'src/app/domains/activities';
import { StateService } from 'src/app/state/state.service';
import { Observable, of, combineLatest } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PermitType } from 'src/app/domains/activities/permit-type';
import * as moment from 'moment';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { CartService } from 'src/app/library/services/payments_and_orders/cart.service';
import { EcentricService } from 'src/app/library/services/payments_and_orders/ecentric.service';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';
import { decrement, increment } from 'src/app/library/utilities/forms/form';



@Component({
  selector: 'app-permit-checkout',
  templateUrl: './permit-checkout.component.html',
  styleUrls: ['./permit-checkout.component.scss']
})
export class PermitCheckoutComponent implements OnInit, AfterViewChecked {


  public checkoutActivity$: Observable<Activities>;
  public permitCheckoutForm: FormGroup;
  public submitted = false;
  public minPermitDate;
  public permitTotal = 0;
  public dateDisabled = false;
  selectedPermit : any;
  site ;

  

  defaultPermitId : any;
  sitePermitsTypes : any;

  constructor(public stateService: StateService, protected ecoService : EcoService, private route: ActivatedRoute, private formBuilder: FormBuilder,
    public cartService: CartService, private cdRef: ChangeDetectorRef, private alertService: UialertsService, private router: Router,
    public ecentricService: EcentricService) {
    this.defaultPermitId = this.route.snapshot.paramMap.get('permitTypeId');
 



    console.log(this.defaultPermitId);
    
  
    console.log();
    
    // this.checkoutActivity$ = this.ecoService.getActivity(activityId);
  }
  

  async ngOnInit() {
    this.permitCheckoutForm = this.formBuilder.group({
      selectedPermit: new FormControl(Validators.required),
      selectedDate: new FormControl(null, Validators.required),
      selectedQuantity: new FormControl(0, [Validators.required, Validators.min(1)]),
    });
    await this.loadAllPermitTypes();
    this.getSite(this.route.snapshot.paramMap.get('siteId'));
    this.loadDefaultValues();
    this.formChangesListener();
  }
  

  private async loadAllPermitTypes(){
    try {
      this.sitePermitsTypes = (await this.ecoService.getPermitTypes(this.route.snapshot.paramMap.get('siteId'))).value;

      console.log(this.sitePermitsTypes);
      
    } catch (error) {
      
    }
  }

  private async getSite(id : any) { 
    try {
      this.site = await this.ecoService.getSite(id);
      // console.log(this.site);
    
    } catch (error) {
      
    }
  }

  locationBack() {
    // let locationStore = this.stateService.locationStore;
    // if (locationStore) {
    //   if (locationStore.previous.includes('account')) {
    //     this.router.navigateByUrl('/permits/'+this.site.name+'/'+this.site.id);
    //   } else {
    //     this.router.navigateByUrl('/permits/'+this.site.name+'/'+this.site.id);
    //   }
    // } else {
    //   this.router.navigateByUrl('/permits/'+this.site.name+'/'+this.site.id);
    // }
  }

  ngAfterViewChecked(): void {
    //set min date to tomorrow
    this.minPermitDate = new Date();
    this.minPermitDate.setDate(this.minPermitDate.getDate() + 1);
    this.cdRef.detectChanges();
  }

  formChangesListener() {
    this.permitCheckoutForm.get('selectedPermit').valueChanges.subscribe((data) => {
      this.selectedPermit = data;
      this.dateDisabled = data.daysValid > 360 ? true : false;
      this.permitCheckoutForm.patchValue({
        selectedDate: this.dateDisabled ? moment().format("YYYY-MM-DD") : null
      });
    });

    this.permitCheckoutForm.valueChanges.subscribe((data) => {
      let permit: PermitType = data.selectedPermit;
      let quantity: number = data.selectedQuantity;
      this.permitTotal = this.calculatePermitsPrice(quantity)//permit.baseCost * quantity;
    });
  }


  async loadDefaultValues() {
    try {
      if (this.sitePermitsTypes) {
        if(this.defaultPermitId){
          this.dateDisabled = (this.sitePermitsTypes.filter(permitType => permitType.id == this.defaultPermitId))[0].daysValid > 360 ? true : false;
          this.permitCheckoutForm.patchValue({
            selectedPermit: (this.sitePermitsTypes.filter(permitType => permitType.id == this.defaultPermitId))[0],
            selectedDate: this.dateDisabled ? moment().format("YYYY-MM-DD") : null
          });
        }
      }
      this.selectedPermit = (this.sitePermitsTypes.filter(permitType => permitType.id == this.defaultPermitId))[0];

    } catch (error) {
      
    }

  }

  private calculatePermitsPrice(count: number) {
    let totalCost = 0;
    let selectedPermit = this.permitCheckoutForm.value.selectedPermit as PermitType;
    let baseCost = selectedPermit.baseCost;
    if (count > selectedPermit.baseUsers) {
      if (selectedPermit.extraCost) {
        totalCost += baseCost;
        totalCost += (count - selectedPermit.baseUsers) * selectedPermit.extraCost;
      } else {
        totalCost += selectedPermit.baseUsers * baseCost;
        totalCost += (count - selectedPermit.baseUsers) * baseCost;
      }
    } else {
      if (count >= 1) {
        totalCost += baseCost;
      }
    }
    return totalCost;
  }


  async ecentricPayment() {
    this.submitted = true;
    if (this.dateDisabled) {
      this.permitCheckoutForm.controls['selectedDate'].setErrors(null);
    }
    if (this.permitCheckoutForm.invalid) {
      this.permitCheckoutForm.markAllAsTouched();
      return;
    }
    this.stateService.user$.subscribe(user => {
      if (user && user.uid) {
        this.router.navigate(['/cart'])
          .then(() => {
            this.alertService.openSnackBar({ duration: 5, message: "Adding permits to cart and contacting payment portal...", mode: UIAlertType.info });
            this.cartService.addPermitToCart({
              startDate: this.selectedDate,
              count: this.selectedQuantity,
              permitTypeId: this.selectedPermitType.id,
              adventurerUid: user.uid
            })
              .then(() => {
                this.ecentricService.openPaymentWindow();
              }).catch(() => {
                this.alertService.openSnackBar({ duration: 10, message: "Error Addiing items to cart, Please Try Again", mode: UIAlertType.error });
              })
          });
      }
    });
  }

  async addPermitToCart() {
    this.submitted = true;
    if (this.dateDisabled) {
      this.permitCheckoutForm.controls['selectedDate'].setErrors(null);
    }
    if (this.permitCheckoutForm.invalid) {
      this.permitCheckoutForm.markAllAsTouched();
      return;
    }
    this.stateService.user$.subscribe(user => {
      if (user && user.uid) {
        this.cartService.addPermitToCart({
          startDate: this.selectedDate,
          count: this.selectedQuantity,
          permitTypeId: this.selectedPermitType.id,
          adventurerUid: user.uid
        }).then(() => {
            this.alertService.openSnackBar({ duration: 5, message: "Successfully Added Permit To Cart", mode: UIAlertType.success });
          }).catch(() => {
            this.alertService.openSnackBar({ duration: 10, message: "Error Adding Permit To Cart, Please Try Again", mode: UIAlertType.error });
          })
        this.locationBack();
      }
    });
  }

  incrementQuantity() {
    this.permitCheckoutForm.patchValue({
      selectedQuantity: increment(this.selectedQuantity, 1)
    });
  }
  decrementQuantity() {
    this.permitCheckoutForm.patchValue({
      selectedQuantity: decrement(this.selectedQuantity, 1)
    });
  }

  get permitCheckoutFormControls() {
    return this.permitCheckoutForm.controls;
  }
  get selectedPermitType(): PermitType {
    return this.permitCheckoutForm.get('selectedPermit').value;
  }
  get selectedDate(): string {
    return this.permitCheckoutForm.get('selectedDate').value;
  }
  get selectedQuantity(): number {
    return this.permitCheckoutForm.get('selectedQuantity').value;
  }
}
