import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/library/services/authentication/authentication.service';
import { MustMatch } from 'src/app/library/utilities/validators/matchValidator';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';

@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {

  public hide: boolean = true;
  public submitted: boolean = false;
  public form: FormGroup;
  public savingForm = false;

  constructor(private formBuilder: FormBuilder, private authService : AuthenticationService, private alertService: UialertsService, private router: Router) { }

  public async ngOnInit() {
    await this.createForm();
  }

  public async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.savingForm = true;
    this.authService.register(this.form.value)
      .then(() => {
        this.alertService.openSnackBar({ duration: 10, message: `Email Verification has been sent to: ${this.form.get('emailAddress').value}`, mode: UIAlertType.success });
        this.router.navigate(['/account/profile']);
      })
      .catch((error)=>{
        this.alertService.openSnackBar({ duration: 10, message: error.message, mode: UIAlertType.error });
      })
      .finally(() => {
          this.savingForm = false;
      });
  }

  private async createForm() {
    this.form = this.formBuilder.group({
      emailAddress: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
      termsConfirm: new FormControl('', Validators.requiredTrue),
      marketingOptIn: new FormControl('')
    }, { validators: MustMatch('password', 'confirmPassword')
   });
  }

  get fc() { return this.form.controls; }
}
