<div *ngIf="!loadingState; else loading" class="main-container">
    <div class="welcome-section section-correction section">
        <location-welcome></location-welcome>
    </div>
    <div id="events" class="news-section section">
        <location-news></location-news>
    </div>
    <div id="accommodation" class="accommodation-section section">
        <location-accommodation></location-accommodation>
    </div>
    <div class="testimonial-section section">
        <testimonial [site]="theSite"></testimonial>
    </div>
    <div class="stay-in-touch-section section">
        <stay-in-touch></stay-in-touch>
    </div>
    <div class="sponsors-section section">
        <sponsors></sponsors>
    </div>
</div>

<ng-template #loading>
    <div class="loading-container">
        <global-loader></global-loader>
    </div>
</ng-template>