import { Component, OnInit, Sanitizer, SecurityContext, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { Router, Route, ActivatedRoute } from '@angular/router';
import { Observable, of, Subject, BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import { StateService } from 'src/app/state/state.service';
import { Activities } from 'src/app/domains/activities';
import { Site, Media } from 'src/app/domains/site';
import { Weather } from 'src/app/domains/weather';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { EcoService } from "src/app/library/services/resources/ecotourism/eco.service";
import { ImageDialogComponent } from "src/app/components/general/image-dialog/image-dialog.component";

@Component({
  selector: "app-hiking-section-trails",
  templateUrl: "./trails.component.html",
  styleUrls: ["./trails.component.scss"]
})

export class HikingTrailsSectionComponent implements OnInit {

  public site = new BehaviorSubject<Site>({} as any);
  public activities = new BehaviorSubject<Array<Activities>>(new Array<Activities>());

  public sixDays = new Array<Activities>();

  public selectedActivity: any;
  public siteWeather$: Observable<Weather[]>;
  public selectedCarousalImage$: Observable<{ media: Media, index: number }>;


  public activityType: string;
  public activityId: string;
  constructor(private route: ActivatedRoute, public ecoService: EcoService, public dialogService: MatDialog, public stateService: StateService

  ) {
  }

  ngOnInit() {
    this.initializeData().then(() => {
    });
  }


  private async getSite(id : any) { 
    try {
      this.site.next(await this.ecoService.getSite(id));
    } catch (error) {
      
    }
  }

  async initializeData() {
    const siteId = 3;
    this.getSite(siteId);
    this.activityType = this.route.snapshot.paramMap.get('aCategory');

        this.ecoService.hikingTrails$.subscribe(trailParks => {
          this.sixDays = trailParks.filter(trail => trail.days == 6);
          this.selectedActivity = this.sixDays[0];
          // this.activities.next(trailParks.filter(e => e.site.id == siteId));
        });
        
        // this.selectedActivity = this.sixDays[0];
        // this.activities$.subscribe((all)=>{
        //   this.selectedActivity = all[0];
        // });

  }

  selectActivity(activity){
    console.log(activity);
    this.selectedActivity = activity;
  }

  get site$() {
    return this.site.asObservable();
  }
  get activities$() {
    return this.activities.asObservable();
  }
}
