<div class="splash-section section">
    <cape-nature-splash></cape-nature-splash>
</div>
<div class="divider-cape-up">
    <divider [direction]="'up'" [underNavigation]="'no'"></divider>
</div>
<div class="overview-section section">
    <cape-nature-overview></cape-nature-overview>
</div>
<div class="overview">
    <app-trails></app-trails>
</div>
<cape-more-info></cape-more-info>

<div class="reservation-section">
    <section class="more-info container">
        <div class="heading">
            <i class="material-icons">circle</i>
            <p class="">Reservation Details</p>
        </div>

        <div class="text-container">
            <div class="_text">
                <p>
                    <b>Hiking Trail Information</b>
                    <br> Please note that parking is limited and is available on a first come, first serve, basis and a maximum of 20 vehicles will be permitted into the park. Various short walks are available on the reserve. Alternatively, the reserve
                    offers four, more challenging, hikes ranging between 5.3km and 18km.
                </p>
                <p>
                    <b>Emergency Contact Information </b>
                    <br> Jonkershoek Office (Monday to Friday during office hours) – 087 087 4118. Emergency Tel (Fire and Rescue) – 082 467 0405. Winelands Emergency Tel – 021 887 4446.
                    <br>
                    <br>
                </p>
                <p>
                    <b>Tariffs</b>
                    <br> A reservation can be made through our online booking service or via the MyMTO mobile app. Alternatively day passes may be purchased upon arrival at the reserve entrance.
                    <br>Adults R50
                    <br> Children R30

                </p>
            </div>
            <div class="_text">
                <!-- <p> -->

                <b>Opening Times</b>
                <p>Winter: (1 May &ndash; 31 August): 08:00 &ndash; 16:00 (Everyone is expected to have exited by 18:00).</p>
                <p>Summer: (1 September &ndash; 30 April): 07:00 &ndash; 16:00 (Everyone is expected to have exited by 18:00).</p>
                <p>&nbsp;</p>
                <p>Hiking trails have daily cut-off times to ensure that clients can access, safely hike, and exit by 18:00.</p>
                <p>&nbsp;</p>
                <p>Waterval 1 and 2: No access after 14:00</p>
                <p>Panorama Circuit: No access after 10:00</p>
                <p>Swartboskloof to Sosyskloof: No access after 12:00</p>
                <p>Swartboskloof Trail: No access after 10:00</p>
                <!-- </p> -->
            </div>
        </div>
    </section>
</div>
<div class="divider-accommodation-up">
    <divider [direction]="'up'" [underNavigation]="'no'"></divider>
</div>
<div class="overview-section section wild-card">
    <div class="container container_ text-para">
        <div class="left-section">

        </div>

        <div class="right-section">
            <p style="text-align:justify"><span style="font-size:25px"><strong><span style="font-family:Calibri,sans-serif">About the Wild Card Programme</span></strong>
                </span>
            </p>
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">The Wild Card is a loyalty programme that makes tourism in national parks and nature reserves in Southern Africa more accessible and rewarding than ever before.</span></span>
            </p>

            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">With a Wild Card membership, you receive a year&rsquo;s unlimited access to the most beautiful nature reserves and parks with superb picnic spots, natural swimming pools, plunging waterfalls, invigorating day hikes, mountain biking and all the clear air, fantastic birdwatching and colourful fynbos you can handle.</span></span>
            </p>

            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">The Wild Card is a loyalty programme that makes tourism in national parks and nature reserves in Southern Africa more accessible and rewarding than ever before.</span></span>
            </p>

            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">With a Wild Card membership, you receive a year&rsquo;s unlimited access to the most beautiful nature reserves and parks with superb picnic spots, natural swimming pools, plunging waterfalls, invigorating day hikes, mountain biking and all the clear air, fantastic birdwatching and colourful fynbos you can handle.</span></span>
            </p>

            <div class="bottom-containerx">
                <button mat-button (click)="openLink('https://www.capenature.co.za/uploads/files/Resources/Wild-Card-cluster-type-and-fees-2021_2022.pdf', true)">Current Rates</button>
                <button class="application-button" mat-button (click)="openLink('https://www.capenature.co.za/uploads/files/Resources/Wild-Card-Application-form_1-November-2021_31-October-2022.pdf', true)">Application</button>
                <button class="application-button" mat-button (click)="openLink('https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/Wild%20Card%20FAQs.pdf?alt=media&token=ae38767c-1697-4973-b65e-9f901138c3d9', true)">FAQS</button>
            </div>
        </div>
    </div>
</div>
<div class="sponsor-section section">
    <sponsors></sponsors>
</div>