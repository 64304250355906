import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { MyResponse } from "src/app/domains/adventurer/myResponse";
import { Feedback } from "src/app/domains/general/feedback";
import { User } from "src/app/domains/user";
import { StateService } from "src/app/state/state.service";
import { GlobalConfig } from "../../configuration/global.config";
import { HttpService } from "../networking/http.service";

@Injectable()

export class AccountService { 

    constructor(
        private HttpService             : HttpService,
        private stateService            : StateService){ }

    public initialize() : void {
        this.listenForProfileChanges();
    }
    
    private listenForProfileChanges() : void {
        try {

            this.stateService.authenticated$
            .subscribe(async (auth)=>{
                if(auth){
                    let results = await this.HttpService.get(`${GlobalConfig.APIS.OLDAPI}eco/adventurers/${auth.uid}`);

                    if(results['uid'] == null && results['id'] == 0){ 
                        await this.updateUser({
                            uid : auth.uid,
                            email : auth.email
                        });

                        this.stateService.user = {
                            email : auth.email,
                            uid   : auth.uid
                        };
                    } else {
                        results['email'] = results.emailAddress;   /* This is done to mitigate for email property difference in core and api.mto.group */
                        delete results.emailAddress;
                        this.stateService.user = results;
                    }

                    this.getAllPurchases(auth.uid);
                }
            });
        } catch (error) {
            console.error(error);
        } 
    }

    public async updateUser(user : User) { 
        await this.HttpService.put(`${GlobalConfig.APIS.OLDAPI}eco/adventurers/${user.uid}`,user);
    }

    public async getAllPurchases(uid: string) {
        this.stateService.purchases = await this.HttpService.get(`${GlobalConfig.APIS.OLDAPI}eco/activities/my?uid=${uid}`);
    }


    public async submitFeedback(feedback : Feedback) {
         return this.HttpService.post(`${GlobalConfig.APIS.OLDAPI}feedback`, feedback);
    }

}