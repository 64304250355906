<form [formGroup]="form" (ngSubmit)="submit()" class="standard-form">
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Email Address</mat-label>
        <input matInput class="form-control" formControlName="emailAddress">
        <mat-error *ngIf="submitted && fc.emailAddress.errors">
            <span *ngIf="fc.email.errors.required">Email is required</span>
            <span *ngIf="fc.email.errors.email">Valid email required</span>
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput class="form-control" [type]="hide ? 'password' : 'text'" formControlName="password">
        <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
        <mat-error *ngIf="submitted && fc.password.errors">
            <span *ngIf="fc.password.errors.required">Password is required</span>
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input matInput class="form-control" [type]="hide ? 'password' : 'text'" formControlName="confirmPassword">
        <mat-error *ngIf="submitted && fc.confirmPassword.errors">
            <span *ngIf="fc.confirmPassword.errors.required">Confirming your password is required</span>
            <span *ngIf="fc.confirmPassword.errors.mustMatch">Passwords do not match</span>
        </mat-error>
    </mat-form-field>
    <mat-checkbox class="privacy" formControlName="termsConfirm">I agree to MTO Group's Terms and Privacy Policy.
    </mat-checkbox>

    <mat-error *ngIf="submitted && fc.termsConfirm.errors" class="mat-checkbox-error">
        <span *ngIf="fc.termsConfirm.errors.required">Agreeing to MTO Group's Terms and Privacy Policy is
      required</span>
    </mat-error>
    <mat-checkbox formControlName="marketingOptIn">Please send me news letters and product promotions via email.
    </mat-checkbox>
    <button mat-button class="standard-blue-btn sign-in-btn" [class.spinner]="savingForm" [disabled]="savingForm || form.invalid">Create account</button>
</form>