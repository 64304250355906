import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Request } from 'express';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(Request) private request: Request
  ) { }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      if (this.request) {
        this.request.res.status(404);
      }
    }
  }

}
