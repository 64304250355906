import { Coordinates } from './../../../../library/interfaces/resources/general/coordinates';
import { Title } from '@angular/platform-browser';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {

  @Input()
  info:ContactInfo;

  constructor() { }

  ngOnInit(): void {
  
  }

}

export interface ContactInfo{
  title:string;
  number:string;
  email:string;
  coordinates:string;
  actionLink:string;
}