import { Injectable, NgZone } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';
import { StateService } from 'src/app/state/state.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable()
export class AuthenticatedGuard implements CanActivate {

  constructor(private router: Router, private stateService : StateService, private route: ActivatedRoute, private ngZone: NgZone) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let url = state.url;
    return new Promise<boolean>(async (resolve) => {
      this.stateService.authenticated$.subscribe(val => {
        if (val != null) {
          resolve(true);
        } else if (val == null) {
          this.ngZone.run(() => {
            this.router.navigateByUrl(`account/login?redirectTo=${url}`);
          });
          resolve(false);
        }
      });
    });
  }
}
