import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/state/state.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['../account.component.scss']
})
export class HistoryComponent implements OnInit {

 
  constructor(private router: Router, public stateService: StateService) {
  }

  ngOnInit() {
  }


}
