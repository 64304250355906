<div class="top-container">
    <div class="wrapper container">
        <div class="inner">
            <h1 class="section-heading">Overview</h1>
            <div class="c">
                <p>{{localSite?.trailsCopy}}</p>
            </div>
            <div class="main-container">
                <div class="img-container" id="map"></div>
                <br>
                <div [hidden]="!showKMLContent" id="capture"></div>
            </div>
        </div>
    </div>
</div>