import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Wordpress } from 'src/app/domains/content/wordpress';

@Component({
  selector: 'news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.scss']
})
export class NewsArticleComponent implements OnInit {

  @Input() public article: Wordpress;
  constructor() { }

  ngOnInit(): void {
    
  }

}
