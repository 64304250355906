<div class="wrapper" *ngIf="sponsors.length > 0">
    <div class="inner container">
        <div class="heading section-heading">
            <i class="material-icons">circle</i>
            <p class="">Partners</p>
        </div>
        <mat-grid-list class="sponsors-cards" [cols]="gridColSpan" (window:resize)="resiseGrid()" [gutterSize]="'20px'">
            <mat-grid-tile class="card" *ngFor="let sponsorImage of sponsors">
                <img [src]="sponsorImage" />
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>