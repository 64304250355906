const iconsLocation =  "../../../assets/images/icons/new/"
export const Activities_Static = [
    {
        id: 6,
        name: "MTB",
        icon: iconsLocation + "MTB.svg",
    },
    {
        id: 1,
        name: "Hiking",
        icon: iconsLocation + "Hiking.svg",
    },
    {
        id: 8,
        name: "Trail Running",
        icon: iconsLocation + "Activities.svg",
    },
    {
        id: 7,
        name: "Accommodation",
        icon: iconsLocation + "Accommodation.svg",
    },
    {
        id: 5,
        name: "Events",
        icon: iconsLocation + "Events.svg",
    },
    {
        id: 3,
        name: "Horse Riding",
        icon: iconsLocation + "Horse Riding.svg",
    },
    {
        id: 4,
        name: "Bird Watching",
        icon: iconsLocation + "Bird Watching.svg",
    },
    {
        id: 2,
        name: "Fishing",
        icon: iconsLocation + "Fishing.svg",
    }

]