<div class="wrapper">
    <div class="inner">
        <div class="trails-select container">
            <a class="trail-option" [class.active]="location === 'jonkershoek'" (click)="activate('jonkershoek')">jonkershoek trail</a>
            <a class="trail-option" [class.active]="location === 'garden-route'" (click)="activate('garden-route')">garden route trail</a>
            <a class="trail-option" [class.active]="location === 'lowveld'" (click)="activate('lowveld')">lowveld trail</a>
            <a class="trail-option" [class.active]="location === 'all'" (click)="activate('all')">all trails</a>
        </div>
        <div class="img-list">
            <gallery-images-grid [images]="images"></gallery-images-grid>
        </div>
    </div>
</div>