import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})
export class GlobalLoaderComponent implements OnInit {
  options: AnimationOptions = {
    path: 'assets/animations/lottie/mountain.json',
    loop : true,
    autoplay : true
  };
      
  constructor() { }

  ngOnInit(): void {
  }
}
