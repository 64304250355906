<div class="splash-container">
    <div class="splash-img-container" >
    </div>
    <div class="splash-content">
        <div class="content-container">
            <div class="content">
                <h1 class="text-xl-h">MTO Events
                </h1>
                <!-- <p class="text-xl-p">MTO's wide-spread plantations offer a world-class network of trails for outdoor adventure, mountain biking and trail running enthusiasts.</p> -->
                <div class="content-action d-flex justify-content-start align-items-center cursor-pointer">
                    <!-- <div class="material-icons down-arrow anim-bounce">
                        arrow_circle_down
                    </div> -->
                    <!-- <p class="text-xl-p" class="sm-action-text p-1">
                        start your adventure
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</div>