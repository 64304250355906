<div class="wrapper">
    <div class="inner container">
        <div class="point-info container">
            <div class="info-point">
                <img height="100" src="../../../../../assets/images/icons//new/Duration.svg" alt="">
                <div class="point-action">
                    <h2>6 Days</h2>
                </div>
            </div>
            <div class="info-point">
                <img height="100" src="../../../../../assets/images/icons//new/Distance.svg" alt="">
                <div class="point-action">
                    <h2>62.3 Km</h2>
                </div>
            </div>
            <div class="info-point">
                <img height="100" src="../../../../../assets/images/icons//new/Hiking.svg" alt="">
                <div class="point-action">
                    <h2>Portage</h2>
                </div>
            </div>
            <!--<div routerLink="/checkout/79/booking" class="info-point">
                                                    <img height="100" src="../../../../../assets/images/icons//new/Events.svg" alt="">
                                                    <div class="point-action">
                                                        <h2><u>Book now</u></h2>
                                                    </div>
                                                </div>-->
            <div (click)="openLink()" class="info-point">
                <img height="100" src="../../../../../assets/images/icons//new/Events.svg" alt="">
                <div class="point-action">
                    <h2><u>Book now</u></h2>
                </div>
            </div>
            <div (click)="openLink_2()" class="info-point">
                <img height="100" src="../../../../../assets/images/icons/customize.svg" alt="">
                <div class="point-action">
                    <h2>Customize Hike</h2>
                </div>
            </div>
        </div>
        <div class="section-heading">
            <i class="material-icons">circle</i>
            <p>Full 6 Day Hike Overview</p>
        </div>
        <div class="img-container">
            <div class="img-container" id="map"></div>
            <div id="capture"></div>
        </div>
    </div>
</div>