import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType } from '../../utilities/enums/global-enums';

@Pipe({
  name: 'PaymentTypeToFriendlyPipe'
})
export class PaymentTypeToFriendlyPipe implements PipeTransform {
  transform(value: PaymentType, args?: any): any {
    return PaymentType[value];
  }
}
