<div class="article-container">
    <div class="img-container">
        <img src="{{article?.featured_media_url}}" alt="">
    </div>
    <div class="content">
        <h1 class="date">{{article?.date | date:'dd LLLL yyyy' }}</h1>
        <h1 class="title">{{article?.title.rendered}}</h1>
        <div [innerHtml]="article?.content.rendered" style="width:100%">

        </div>
    </div>
</div>