import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { ParseFirebaseError } from 'src/app/library/utilities/parsers/firebase-error';
import { FirebaseError } from 'firebase/app';
import { AuthenticationService } from 'src/app/library/services/authentication/authentication.service';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';
import { ErrorException } from 'src/app/library/interfaces/general/error-exception';

@Component({
  selector: 'forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {

  public form: FormGroup;
  public submitted: boolean = false;
  public savingForm = false;
  constructor(private formBuilder: FormBuilder, private alertService: UialertsService, private authService: AuthenticationService) { }

  async ngOnInit() {
    await this.createForm();
  }

  public async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.savingForm = true;
    this.authService.resetPassword(this.form.value.email)
      .then(() => {
        this.alertService.openSnackBar({ duration: 10, message: `Password reset email has beent sent`, mode: UIAlertType.success });
      }).catch((ex: ErrorException) => {
        console.log(ex);
        this.alertService.openSnackBar({ duration: 10, message: `Failed to send password reset email`, mode: UIAlertType.error });
      })
      .finally(() => {
        this.savingForm = false;
      });
  }

  private async createForm() {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  get fc() { return this.form.controls; }
}
