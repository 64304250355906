<div class="wrapper">
    <div class="inner">
        <div class="splash-container">
            <div class="splash-img-container">
            </div>
            <div class="splash-content">
                <div class="content-container container">
                    <div class="content">
                        <h1 class="text-xl-h">
                            Customize your Hike
                        </h1>
                        <!-- <p class="message">
                            The Tsitsikamma trail is a Southern Cape Hiking Paradise, Journeying though Extensive indigenous Afromontane forest, mountain fynbos and through Ancient river gorges. Beginning in Nature’s Valley and ending 62.3km further at either the Storms River Bridge
                            or Village, this six day, unidirectional trail takes hikers through the heart of the Tsitsikamma Mountains. Shorter variations (2,3,4 or 5 days) are possible as each of the five overnight huts has its own unique access route
                            with safe parking at the start.
                            </p> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>