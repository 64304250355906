<ng-container *ngVar="{authed: stateService.authenticated$ | async } as data">
    <nav #navBar class="nav-bar  container" id="nav-bar">
        <div class="nav-container">
            <input type="checkbox" id="drop-down-cbox" />
            <label for="drop-down-cbox">
            <span></span>
            <span></span>
            <span></span>
            </label>

            <div class="nav-logo" [routerLink]="['/']"><img src="../../../../assets/images/logo/logo.svg" /></div>
            <ul class="main-nav small-caps">
                <li><button [routerLink]="['/']" routerLinkActive="active-nav" [routerLinkActiveOptions]="{ exact: true }" mat-button class="nav-item">Home</button></li>

                <li><button [matMenuTriggerFor]="locations" routerLinkActive="active-nav" mat-button class="nav-item">Activities</button></li>
                <mat-menu class="locations-menu main" #locations="matMenu">
                    <button mat-menu-item [routerLink]="['/location','Jonkershoek',1]">JONKERSHOEK</button>
                    <button mat-menu-item [routerLink]="['/location','Garden Route',5]">GARDEN ROUTE</button>
                    <button mat-menu-item [routerLink]="['/location','lowveld',2]">LOWVELD</button>
                </mat-menu>



                <li><button [routerLink]="['/merchandise']" routerLinkActive="active-nav" mat-button class="nav-item">Merchandise</button></li>
                <li><button [routerLink]="['/news']" routerLinkActive="active-nav" mat-button class="nav-item">News</button>
                    <li><button [routerLink]="['/gallery']" routerLinkActive="active-nav" mat-button class="nav-item">Gallery</button>
                    </li>
                    <li><button [routerLink]="['/about']" routerLinkActive="active-nav" mat-button class="nav-item">About</button>
                    </li>
                    <li><button [routerLink]="['/contact-us']" routerLinkActive="active-nav" mat-button class="nav-item">Contact</button></li>
                    <h3 class="tag-line">Creating Exceptional Experiences</h3>
                    <p class="tag-description">
                        <small>                    Purchase permits and book some of the best mountain biking trails, accommodation and events in the country. View maps, weather and a media gallery for each location straight from the myMTO App.
                    </small>
                    </p>

                    <div class="logos">
                        <img src="../../../../assets/images/appstore.svg" alt="">
                        <img src="../../../../assets/images/playstore.svg" alt="">
                    </div>
                    <ng-container>
                        <li *ngIf="data.authed">
                            <button mat-button class="nav-item nav-drop-item " [matMenuTriggerFor]="profileMenu">
                        <span class="sign-in-item">Account 
                            <span class="material-icons drop-arrow">
                                 expand_more
                            </span>
                        </span>
                    </button>
                            <mat-menu #profileMenu="matMenu" yPosition="below" class="profile-menu profile-menu-desktop">
                                <button mat-menu-item [routerLink]="['/account/profile']" routerLinkActive="active-nav">
                                <mat-icon>person</mat-icon><span>Manage Profile</span>
                            </button>
                                <button mat-menu-item (click)="logout()">
                                <mat-icon>logout</mat-icon><span>Logout</span>
                            </button>
                            </mat-menu>
                        </li>
                        <li class="sign-up-button" *ngIf="!data.authed"><button mat-button [routerLink]="['/account/login']" class="nav-item"><span
                class="sign-in-item">Login / Sign
                up</span></button></li>
                    </ng-container>

                    <span *ngIf="data.authed">
                    <button *ngIf="cart;else loaderCart" [routerLink]="['/cart']" mat-button class="nav-item">
                        <span class="material-icons cart-icon">shopping_cart</span>
                    <span class="item-count">{{cart?.orderDetails?.length}}</span>
                    </button>
                    </span>

            </ul>

            <div class="buttons-container">
                <button mat-button class="nav-item nav-drop-item mobile-button" [matMenuTriggerFor]="profileMenu">
                    <span class="material-icons-outlined person-icon">
                        person
                    </span>
                </button>

                <span *ngIf="data.authed">
                    <button *ngIf="cart;else loaderCart" [routerLink]="['/cart']" mat-button class="nav-item nav-drop-item mobile-button mb2">
                        <span class=" material-icons-outlined person-icon ">
                            shopping_cart
                        </span>
                </button>
                </span>


                <mat-menu #profileMenu="matMenu" yPosition="below" class="profile-menu mobile-menu">
                    <button *ngIf="data.authed" mat-menu-item [routerLink]="['/account/profile']" routerLinkActive="active-nav">
                        <mat-icon>person</mat-icon><span>Manage Profile</span>
                    </button>
                    <button *ngIf="data.authed" mat-menu-item (click)="logout()">
                        <mat-icon>logout</mat-icon><span>Logout</span>
                    </button>
                    <button *ngIf="!data.authed" mat-menu-item [routerLink]="['/account/login']">Login</button>
                </mat-menu>

            </div>
        </div>
    </nav>
</ng-container>

<ng-template #loaderCart>
    <div class="cart-loader ">
        <loader></loader>
    </div>
</ng-template>