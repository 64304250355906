import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable} from "rxjs";
import { User } from "../domains/user";
import { Credentials } from "../domains/credentials";
import { Activities, ActivityInfo, ActivityDifficulty, ActivityCategory } from "../domains/activities";
// import { ApplicationStatus } from "../domains/application-status";
import { Site } from '../domains/site';
import { Order } from '../domains/cart/order';
import { MyResponse } from '../domains/adventurer/myResponse';
import { Wordpress } from '../domains/content/wordpress';
import { FireFileMetaData } from '../domains/firebase/fireFile';
import { Authed } from "../domains/authentication/authed.domain";

@Injectable()
export class StateService {
  
    
    //application core
    private readonly _authenticated = new BehaviorSubject<Authed>(null);


    //account & authentication
    private readonly _user = new BehaviorSubject<User>(null as User);
    private readonly _authToken = new BehaviorSubject<string>(null);
    
    //application state
    private readonly _appLoading = new BehaviorSubject<boolean>(true);

    //purchases
    private readonly _cart = new BehaviorSubject<Order>(null as Order);
    private readonly _purchases = new BehaviorSubject<MyResponse>(null as MyResponse);

    //resources & other
    private readonly _news = new BehaviorSubject<Wordpress[]>(null);
    private readonly _sites = new BehaviorSubject<Site[]>(new Array<Site>());
    private readonly _activity_types = new BehaviorSubject<ActivityInfo[]>({} as ActivityInfo[]);
    private readonly _difficulty_types = new BehaviorSubject<ActivityDifficulty[]>({} as ActivityDifficulty[]);
    private readonly _activity_category_types = new BehaviorSubject<ActivityCategory[]>({} as ActivityCategory[]);
    private readonly _activities = new BehaviorSubject<Array<Activities>>(new Array<Activities>());


    set purchases(data: MyResponse) {
        this._purchases.next(data);
    }

    get purchases$() {
        return this._purchases.asObservable();
    }

    get cart$(): Observable<Order> {
        return this._cart.asObservable();
    }
    set cart(order: Order) {
        this._cart.next(order);
    }

    get appLoading$(): Observable<boolean> {
        return this._appLoading.asObservable();
    }
    set appLoading(value: boolean) {
        this._appLoading.next(value);
    }

    get sites$(): Observable<Site[]> {
        return this._sites.asObservable();
    }
    set sites(sites: Site[]) {
        this._sites.next(sites)
    }
    get sites(): Site[] {
        return this._sites.getValue();
    }

    get news$(): Observable<Wordpress[]> {
        return this._news.asObservable();
    }
    set news(news: Wordpress[]) {
        this._news.next(news);
    }
    get news() {
        return this._news.getValue();
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get user(): User {
        return this._user.getValue();
    }
    set user(user: User) {
        this._user.next(user);
    }

    
    get token$(): Observable<string> {
        return this._authToken.asObservable();
    }

    get token(): string {
        return this._authToken.getValue();
    }
    set token(user: string) {
        this._authToken.next(user);
    }

    get authenticated$(): Observable<Authed> {
        return this._authenticated.asObservable();
    }
    get authenticated(): Authed {
        return this._authenticated.getValue();
    }
    set authenticated(authenticated: Authed) {
        this._authenticated.next(authenticated);
    }
    get activities$(): Observable<Array<Activities>> {
        return this._activities.asObservable();
    }
    get activities(): Array<Activities> {
        return this._activities.getValue();
    }
    set activities(trailsItem: Array<Activities>) {
        this._activities.next(trailsItem);
    }

    get activityTypes$(): Observable<ActivityInfo[]> {
        return this._activity_types.asObservable();
    }
    set activityTypes(activityTypes: Array<ActivityInfo>) {
        this._activity_types.next(activityTypes);
    }

    get activityDifficulties$(): Observable<Array<ActivityDifficulty>> {
        return this._difficulty_types.asObservable();
    }
    set activityDifficulties(activityDifficulties: Array<ActivityDifficulty>) {
        this._difficulty_types.next(activityDifficulties);
    }
    get activityCategories$(): Observable<ActivityCategory[]> {
        return this._activity_category_types.asObservable();
    }
    set activityCategories(activityCategories: ActivityCategory[]) {
        this._activity_category_types.next(activityCategories);
    }
}
