import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-checkout',
  templateUrl: './base-checkout.component.html',
  styleUrls: ['./base-checkout.component.scss']
})
export class BaseCheckoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var bodyTag = document.querySelector("body");
    bodyTag.classList.remove("is-sticky");
  }

}
