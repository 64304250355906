<div class="wrapper">
    <div class="inner container">
        <div class="point-info container">
            <div class="info-point">
                <img height="90" src="../../../../../assets/images/sites/CapeNature/location_icons/activities.svg" alt="">
                <div class="point-action">
                    <h2>Hiking Trails</h2>
                </div>
            </div>
            <div class="info-point">
                <img height="90" src="../../../../../assets/images/sites/CapeNature/location_icons/heritage.svg" alt="">
                <div class="point-action">
                    <h2>World Heritage Site</h2>
                </div>
            </div>
            <div class="info-point">
                <img height="100" src="../../../../../assets/images/sites/CapeNature/location_icons/Map.svg" alt="">
                <div class="point-action">
                    <h2 (click)="openLink('https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/Map.PNG?alt=media&token=20bc1283-fbff-478a-b4dd-19c8e12bc47d', true)"><u>Download Map</u></h2>
                </div>
            </div>
        </div>
        <div class="section-heading">
            <i class="material-icons">circle</i>
            <p>Overview</p>
        </div>
        <div class="img-container">
            <div class="img-container" id="map"></div>
            <div id="capture"></div>
        </div>
    </div>
</div>