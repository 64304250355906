<div class="wrapper">
    <div class="inner container">
        <div class="info">
            <div class="heading">
                <span class="material-icons">circle</span>
                <h1>Reservation Details</h1>
            </div>
            <h4>Tarrifs</h4>
            <ul class="list">
                <li>Overnight: R200 per person per day at any hut.</li>
                <li>Day Hike: R50 per person (no accommodation)</li>
                <li>Porterage: R825 per hut for the first 5 people. Thereafter R165 per person.</li>
                <li>(Maximum number of hikers utilizing porterage per hut per day is 24).</li>
            </ul>
            <h4>Special rates</h4>
            <p>Contact us for special rates for group and school bookings during off-peak times. <br>
                Please read our Deposit and Cancellation Policies below.
            </p>
            <div class="action">
                <span class="material-icons-outlined">arrow_circle_right</span>
                <p>read more</p>
            </div>
            <h4>Location - Start & end Points</h4>
            <p>
                The trail starts in the Devasselot Restcamp of Tsitsikamma National Park in Nature’s Valley. 
            </p>
            <h4>Directions</h4>
            <ul class="list">
                <li>
                    Travelling on the N2, turn down the R102 towards Nature’s Valley. From there follow the signposts to the Devasselot Restcamp.
                </li>
                <li>
                    From the west (Plettenberg Bay): the Nature’s Valley turn-off is on your right soon after the Kurland Estate entrance in the Crags area
                </li>
                <li>
                    From the east (Storms River Village): the Nature’s valley turn-off will be to the left immediately after the N2 toll-gate. 
                </li>
                <li>
                    The Devasselot Restcamp is adjacent to the R102 and is clearly marked with large green and yellow signage.
                </li>
                <li>
                    Directions to any of the other four trail starting points can be easily obtained through contacting the MTO Ecotourism office in Tsitsikamma.
                </li>
            </ul>
        </div>
        <div class="map">
            dadf
        </div>
    </div>
</div>