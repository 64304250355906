import {HttpEvent,HttpInterceptor,HttpHandler,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UialertsService } from '../services/ui/uialerts.service';
import { UIAlertType } from '../utilities/enums/global-enums';

@Injectable({
    providedIn: 'root'
  })
  
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private alertService: UialertsService){

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    // client-side error
                    this.alertService.openSnackBar({ message: "Something Went wrong!", duration: 4, mode: UIAlertType.error });
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    // server-side error
                    if(!(error.error) || error.error.Type != 'ErrorException'){
                        this.alertService.openSnackBar({ message: "Something Went wrong!", duration: 4, mode: UIAlertType.error });
                        errorMessage = `Error: ${error.error.message}`;
                    }else{
                        this.alertService.openSnackBar({ message: error.error.Message, duration: 4, mode: UIAlertType.error });
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
                    }
                }
                return throwError(errorMessage);
            })
        )
    }
}