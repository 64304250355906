declare const TARGET_ENVIRONMENT;
declare const devHpp;
declare const hpp;


export namespace GlobalConfig {
    export const PRODUCTION = false;
    export namespace Firebase {
        export const firebaseEnvironment = {
            apiKey: "AIzaSyAB-ZWQf-muYe2sijb3PmwOSEArb6Cpum8",
            authDomain: "mymto-9a978.firebaseapp.com",
            databaseURL: "https://mymto-9a978.firebaseio.com",
            projectId: "mymto-9a978",
            storageBucket: "mymto-9a978.appspot.com",
            messagingSenderId: "442460590517",
            appId: "1:442460590517:web:35fd14b75a96d1ee0e89fc"
        }
        export const userRef = "users";
        export const profileImageRef = "pictures/profile";
        export const publicDownloadsRef = "publicDownloads/";
    }
    export namespace APIS {
        export const CORE = 'https://core.mto.group/';
        export const OLDAPI = 'https://api.mto.group/';
        export const REPORTS = 'https://localhost:44388';
        export const RESOURCES = 'https://localhost:44305';
        export const ORDER_PAYMENTS = 'https://localhost:44306';
        export const KEY = '7b011e0e-77db-40db-9422-11e58c547812';
    }
    
    export namespace ecentric {
        export const _hpp =  false ? hpp : devHpp; //prod / dev 
        // import { uuidv4 } from './uuidv4';
        export const merchantGuid =
            false
                    ? '93A3401D-1E3A-469A-9F21-07FD81CF6DB1'
                    : '53A7FD99-B076-48E0-80F3-B19976EA63D2';
        export const merchantKey =
              false
                    ? '81099CF0-1042-4739-A47F-7DDB80831ECA'
                    : 'D05C7949-8077-4CA0-A37C-7FA7274D1171'; //prod / dev
        export const transactionType = 'Payment';
        export const currency = 'ZAR';
        // export const paymentReference = uuidv4();
  }

    export namespace Payments {
        export namespace Ecentric {
            export const merchantGuid = "53A7FD99-B076-48E0-80F3-B19976EA63D2";
            export const transactionType = "Payment";
            export const currency = 'ZAR'
        }
    }

    export namespace Wordpress {
        export const mto = 'https://cms.mto.group/';
    }

    export const CUSTOM_SITE_NAME = "Tsitsikamma Trail";
}