<div class="splash-container">
    <div class="splash-img-container">
    </div>
    <div class="splash-content">
        <div class="content-container">
            <div class="content">
                <h1 class="text-xl-h">
                    Gallery
                </h1>
                <p class="text-xl-p">
                    MTO Trails is situated in some of the most beautiful regions for the country. 
                    Here are some pictures showing all the fun and beauty to be experienced.
                </p>
            </div>
        </div>
        <divider [direction]="'up'" [underNavigation]="'no'"></divider>
    </div>
</div>