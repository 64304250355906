import { Pipe, PipeTransform } from '@angular/core';
import { StatusType } from '../../utilities/enums/global-enums';

@Pipe({
  name: 'StatusToFriendlyPipe'
})
export class StatusToFriendlyPipe implements PipeTransform {
  transform(value: StatusType, args?: any): any {
    return StatusType[value];
  }
}
