<div class="contact-section section">
  <div class="wrapper">
    <div class="inner container">
      <div class="content">
          <div class="form-area">
            <h1 class="title">Get in touch</h1>
            <p class="par">
              We want to hear from you, be it a query regarding your permit or you've spotted an issue on a trail. 
              Email or call us directly for assistance.
            </p>
            <div class="form">
              <contact-form></contact-form>
            </div>
          </div>
          <div  class="contact-cards">
            <div *ngFor="let card of contactCards" >
              <contact-card [info]="card" ></contact-card>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
<div class="discover-section section">
  <discover-splash></discover-splash>  
</div>
<div class="stay-in-touch-section section">
  <stay-in-touch></stay-in-touch>
</div>
<div class="contact-section section">
  <sponsors></sponsors>
</div>