<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-form-field appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput class="form-control" formControlName="email">
    <mat-error *ngIf="submitted && fc.email.errors">
      <span *ngIf="fc.email.errors.required">Please input your email address</span>
      <span *ngIf="fc.email.errors.email">Please input a valid email address</span>
    </mat-error>
  </mat-form-field>
  <button mat-button type="submit" class="standard-blue-btn sign-in-btn" [class.spinner]="savingForm" [disabled]="savingForm">Reset Password</button>
</form>