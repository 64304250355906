import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { StateService } from 'src/app/state/state.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BookingAvailibility } from 'src/app/domains/activities/booking-availibility';
import { PackageType } from 'src/app/domains/activities/package-type';
import { Activities } from 'src/app/domains/activities';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { EcoService } from 'src/app/library/services/resources/ecotourism/eco.service';
import { EcentricService } from 'src/app/library/services/payments_and_orders/ecentric.service';
import { CartService } from 'src/app/library/services/payments_and_orders/cart.service';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';

@Component({
  selector: 'app-accomodation-checkoutapp-accomodation-checkout',
  templateUrl: './accomodation-checkout.component.html',
  styleUrls: ['./accomodation-checkout.component.scss']
})
export class AccomodationCheckoutComponent implements OnInit {

  public bookingCheckoutForm: FormGroup;
  public submitted = false;
  public checkoutActivity$: Observable<Activities>;
  public minBookingDate;
  public accomodationPackage: PackageType;
  public validSessions: BookingAvailibility[];

  selectedPackage;

  public activityId;
  public dateSelectionLoading = false;


  public dateFilter = (date: Date): boolean => { return true; }

  //totals
  public _packageTotal = new BehaviorSubject(0 as number);
  public _bookingTotal = new BehaviorSubject(0 as number);

  constructor(public stateService: StateService, private route: ActivatedRoute, public ecoService: EcoService, private formBuilder: FormBuilder,
    private router: Router, public ecentricService: EcentricService,
    public cartService: CartService, private cdRef: ChangeDetectorRef, private alertService: UialertsService) {
    this.activityId = this.route.snapshot.paramMap.get('activityId');
    this.checkoutActivity$ = this.ecoService.getActivity(this.activityId);
  }

  ngOnInit() {
    this.bookingCheckoutForm = this.formBuilder.group({
      selectedDate: new FormControl(null, Validators.required),
    });
    this.setMinDate();
    this.getAvailableDates();
    this.loadDefaultValues();
    this.formChangeListeners();

  }
  formChangeListeners() {
    this.bookingCheckoutForm.get('selectedDate').valueChanges.subscribe(data => {
      this.calculatePackageTotal(this.selectedDuration);
    });
  }


  locationBack() {
    // let locationStore = this.stateService.locationStore;
    // if (locationStore) {
    //   if (locationStore.previous.includes('account')) {
    //     this.router.navigateByUrl('/eco-tourism');
    //   } else {
    //     this.router.navigate(['/eco-tourism']);
    //   }
    // } else {
    //   this.router.navigate(['/eco-tourism']);
    // }
  }

  private calculatePackageTotal(nights: number) {
    this.bookingTotal = nights > 1 ? (this.accomodationPackage.baseCost * 1 + (this.accomodationPackage.extraCost * (nights - 1))) : (this.accomodationPackage.baseCost * 1);
  }

  private async getAvailableDates() {
    this.dateSelectionLoading = true;
    let startD = moment(this.minBookingDate).format('YYYY-MM-DD');
    let endD = moment(this.minBookingDate).add(1, 'year').format('YYYY-MM-DD');
    await this.ecoService.getAvailibility(this.activityId, 1, startD, endD)
      .then(data => {
        this.validSessions = data;
        this.dateFilter = (date: Date): boolean => {
          let receivedD = moment(date).format('YYYY-MM-DD');
          return data.some(e => moment(e.date).format('YYYY-MM-DD') == receivedD);
        };
        this.dateSelectionLoading = false;
      });
  }

  async loadDefaultValues() {
    this.checkoutActivity$.subscribe(data => {
      if (data && data.packageTypes) {
        this.accomodationPackage = data.packageTypes.find(e => e.core);
      }
    });
  }
  ecentricPayment() {
    this.submitted = true;
    if (this.bookingCheckoutForm.invalid) {
      this.bookingCheckoutForm.markAllAsTouched();
      return;
    }
    this.stateService.user$.subscribe(async user => {
      if (user && user.uid) {
        this.router.navigate(['/cart']).then(async () => {
          this.alertService.openSnackBar({ duration: 5, message: "Adding booking to cart and contacting payment portal...", mode: UIAlertType.info });
          //get session
          let selectedStartDate = moment(this.selectedDate.begin).format('YYYY-MM-DD');
          let validDate = this.validSessions.find(e => moment(e.date).format('YYYY-MM-DD') == selectedStartDate);
          let validSession = validDate.sessions[0];
          //add core package booking
          await this.cartService.addPackageItemToCart({
            startDate: selectedStartDate,
            bookingId: null,
            pax: this.selectedDuration,
            packageTypeId: this.accomodationPackage.id,
            sessionTypeId: validSession.id,
            adventurerId: null,
            adventurerUid: user.uid
          }).then(async bookingId => {
            await this.cartService.loadActiveCart(user.uid);
            this.ecentricService.openPaymentWindow();
          }).catch(() => {
            this.alertService.openSnackBar({ duration: 10, message: "Error Adding Booking To Cart, Please Try Again", mode: UIAlertType.error });
          });
        });
      }
    });
  }

  async addBookingToCart() {
    this.submitted = true;
    if (this.bookingCheckoutForm.invalid) {
      this.bookingCheckoutForm.markAllAsTouched();
      return;
    }
    this.stateService.user$.subscribe(async user => {
      if (user && user.uid) {
        //get session
        let selectedStartDate = moment(this.selectedDate.begin).format('YYYY-MM-DD');
        let validDate = this.validSessions.find(e => moment(e.date).format('YYYY-MM-DD') == selectedStartDate);
        let validSession = validDate.sessions[0];
        //add core package booking
        await this.cartService.addPackageItemToCart({
          startDate: selectedStartDate,
          bookingId: null,
          pax: this.selectedDuration,
          packageTypeId: this.accomodationPackage.id,
          sessionTypeId: validSession.id,
          adventurerId: null,
          adventurerUid: user.uid
        }).then(bookingId => {
          this.cartService.loadActiveCart(user.uid);
          this.alertService.openSnackBar({ duration: 5, message: "Successfully Added Booking To Cart", mode: UIAlertType.success });
        }).catch(() => {
          this.alertService.openSnackBar({ duration: 10, message: "Error Adding Booking To Cart, Please Try Again", mode: UIAlertType.error });
        });

        this.router.navigate(['/eco-tourism']);
      }
    });
  }


  setMinDate() {
    let tomorrow = moment().add(1, 'days');
    this.minBookingDate = tomorrow.toDate();
  }

  get bookingCheckoutFormControls() {
    return this.bookingCheckoutForm.controls;
  }


  get selectedDuration() {
    return this.selectedDate ? this.selectedDate.end.diff(this.selectedDate.begin, 'days') : 0;
  }
  get selectedDate(): { begin: moment.Moment, end: moment.Moment } {
    return this.bookingCheckoutForm.get('selectedDate').value;
  }

  set bookingTotal(val: number) {
    this._bookingTotal.next(val);
  }
  get bookingTotal$() {
    return this._bookingTotal.asObservable();
  }
}

