import { GalleryImagesSource } from "../temporary/gallery-images-source";

const iconLocation =  "../../../assets/images/icons/new/"
const motBackgroundImgLocation = "../../../assets/images/backgrounds/"

export const SITES_STATIC = [
    {
        id   : 1,
        name : "Jonkershoek",
        seoName : "mto-jonkershoek",
        image : "../../../../../assets/images/landing/landing-eco_site-location-jonkershoek.png",
        categories : ['about','permits','events','gallery' ],
        location : { 
            long: 10.1111,
            lat : -13.6666
        },
        mapKml : '',
        locationLink : [
            {
                name : 'MTB',
                id    : 'location/jonkershoek/1/trails',
                route : true,
                icon  : iconLocation + "MTB.svg"
            },
            {
                name : 'Trail Running',
                id    : 'location/jonkershoek/1/trails',
                route : true,
                icon  : iconLocation + "Running.svg"
            },
            {
                name : 'Hiking',
                id    : 'location/jonkershoek/1/trails',
                route : true,
                icon  : iconLocation + "Hiking.svg"
            },
            {
                name : 'Permits',
                id    : 'permits/jonkershoek/1',
                route : true,
                icon  : iconLocation + "Permit.svg"
            },
            {
                name : 'Cape Nature',
                id    : 'location/_Jonkershoek_CapeNature/8/cape-nature',
                route : true,
                icon  : iconLocation + "Cape.svg"
            },
            {
                name : 'Accommodation',
                id    : 'events',
                route : false,
                icon  : iconLocation + "Accommodation.svg"
            },
            {
                name : 'Events',
                id    : 'events',
                route : false,
                icon  : iconLocation + "Events.svg"
            }
        ],
        kmls : [
            'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMZs%2Fjonkershoek%2FMTO%20Trails%20Jonkershoek.kmz?alt=media&token=3c02ebca-3b31-47ad-8ca4-b95b31b59d93'
        ],
        gallery : GalleryImagesSource.jonkersHoek,
        motQoute:{
            qoute:'"It is the unknown around the corner that turns my wheels"',
            auther:"Heinz Stucke",
            backgroundImgUrl: motBackgroundImgLocation + "jomkershoek-motivational-qoute-bg.JPG",
        },
        socialLinks:{
            facebook:"https://www.facebook.com/mtotrailsjonkershoek",
            instagram:"https://www.instagram.com/mtotrails_jonkershoek/",
        }
    },
    {
        id   : 5,
        name : "Garden Route",
        seoName : "garden-route",
        image : "../../../../../assets/images/landing/landing-eco_site-location-garden-route.png",
        categories : ['about','permits','events','gallery'],
        trailsCopy : "The Trails of the garden route are situated in various regions there are two main trailheads so far for single track users. This first one, situated in Knysna, is located at the entrance of Simola Golf Estate. From here you will be able to enter the region called Concordia contours. Here there is a large plantation that has multiple single tracks perfectly placed for all types of riding. The second Trailhead is situated at Cairnbrogie Mountain bike trails. The trails are accessed through the Cairnbrogie trails. The MTO Portion is the Kranshoek section and will lead you in an array of Singletrack trails through the pine plantation. The remainder of Plantations stretches from The crags through to Witelsbos in the Tsitsikamma. There are multiple entrances to all the coastal forestry roads. be sure to have your access permit or annual permit board on hand. ",
        location : { 
            long: 10.1111,
            lat : -13.6666
        },
        locationLink : [
            {
                name : 'MTB',
                id    : 'location/garden route/5/trails',
                route : true,
                icon  : iconLocation + "MTB.svg"
            },
            {
                name : 'Trail Running',
                id    : 'location/garden route/5/trails',
                route : true,
                icon  : iconLocation + "Running.svg"
            },
            {
                name : 'Tsisikamma Hiking Trail',
                id    : 'location/garden route/5/tsitsikamma',
                route : true,
                icon  : iconLocation + "Hiking.svg"
            },
            {
                name : 'Permits',
                id    : 'permits/gardenroute/5',
                route : true,
                icon  : iconLocation + "Permit.svg"
            },
            {
                name : 'Events',
                id    : 'events',
                route : false,
                icon  : iconLocation + "Events.svg"
            }
        ],
        kmls : [
            'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMZs%2Fgarden%20route%2FMTO%20Trails.%20Kranshoek%20Plettenberg%20Bay.kmz?alt=media&token=2c8730f4-7599-4ed3-83c8-65e5107aa72d',
        ],
        gallery : GalleryImagesSource.gardenRoute,
        motQoute:{
            qoute:'"Life\'s too short not to go big."',
            auther:"Josh Bender",
            backgroundImgUrl: motBackgroundImgLocation + "gardenroute-motivation-qoute-bg.jpg",
        },
        socialLinks:{
            facebook:"https://www.facebook.com/mtotrailsgardenroute/",
            instagram:"https://www.instagram.com/mtotrails_gardenroute/",
        }
    },
    {
        id   : 2,
        name : "White River",
        seoName : "mto-white-river",
        image : "../../../../../assets/images/landing/landing-location-eco_site-lowveld.png",
        categories : ['about','permits','events','accommodation','gallery' ],
        location : { 
            long: 10.1111,
            lat : -13.6666
        },
        locationLink : [
            {
                name : 'MTB',
                id    : 'location/white river/2/trails',
                route : true,
                icon  : iconLocation + "MTB.svg"
            },
            {
                name : 'Permits',
                id    : 'permits/lowveld/2',
                route : true,
                icon  : iconLocation + "Permit.svg"
            },
            {
                name : 'Trail Running',
                id    : 'location/white river/2/trails',
                route : true,
                icon  : iconLocation + "Running.svg"
            },
            {
                name : 'Hiking',
                id    : 'location/white river/2/trails',
                route : true,
                icon  : iconLocation + "Hiking.svg"
            },
            {
                name : 'Horse Riding',
                id    : 'location/white river/2/trails',
                route : true,
                icon  : iconLocation + "Horse Riding.svg"

            },
            {
                name : 'Accommodation',
                id    : 'events',
                route : false,
                icon  : iconLocation + "Accommodation.svg"
            },
            {
                name : 'Events',
                id    : 'events',
                route : false,
                icon  : iconLocation + "Events.svg"
            }
        ],
        kmls : [
            'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMZs%2Flowveld%2FLowveld%20Trails%20Loops.kmz?alt=media&token=5174095a-ae5a-4ee1-8064-b25699de26c4',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fafter-storm-valley.kml?alt=media&token=9eef7564-1c79-4015-aa8d-295b4e0325c6',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fairstrip-downhill.kml?alt=media&token=4a35c4a4-91b1-449c-9f4b-4101623f5a79',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fairstrip-downhill.kml?alt=media&token=4a35c4a4-91b1-449c-9f4b-4101623f5a79',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fangazi.kml?alt=media&token=b8567551-010e-46bf-b98e-d1819ef13bdb',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fbackbone-3rd-part.kml?alt=media&token=c6038ba7-994a-4574-9583-c504cc40b65d',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fbackbone-bottom-part.kml?alt=media&token=b9a33770-c625-4614-9238-ebabad048b12',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fbackbone-small-gums.kml?alt=media&token=d4970d12-5977-4294-ae36-e0e43582a507',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fbedpan.kml?alt=media&token=68a07535-98d6-4322-8cf3-87694045d934',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fboulders.kml?alt=media&token=058c96c5-0f7a-41e6-aa6d-057361c146ec',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fbrightside.kml?alt=media&token=acdd7c0a-5079-4655-a36e-ecd711466879',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fbyrons-bush-tunnel.kml?alt=media&token=a9734a9f-2fe7-40e0-a643-1a03d66f3cd1',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fclimb-to-byrons-and-komatiland.kml?alt=media&token=cd9b0de4-5018-4424-95db-216b2c08db61',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fcovid.kml?alt=media&token=f3660752-24ae-45bb-9ce0-bb5df67c3b76',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fkomatiland-bush-tunnel.kml?alt=media&token=4f9f94fb-43d7-4f74-98f7-b1ab35d91388',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fkuka-and-kuka-pines.kml?alt=media&token=e9230588-17e7-41ad-abfe-6dc7241993b8',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fleonards-longdrop.kml?alt=media&token=caae7340-d1de-4588-b065-f2c09a37bb79',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Flockdown.kml?alt=media&token=3ce33869-a7aa-4bff-b926-14909da81111',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fparadise-pass-263252.kml?alt=media&token=80c05d56-eac2-47e7-92b6-9f0788cc98ca',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fpythons-crush.kml?alt=media&token=0a80eea0-1b3b-4806-819c-e9463ff5a6c8',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fspeed-wobble.kml?alt=media&token=e1440e80-77bd-4301-9cee-404c4a8f4471',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fspeed-wobble.kml?alt=media&token=e1440e80-77bd-4301-9cee-404c4a8f4471',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fspring-farm-alley.kml?alt=media&token=579053d9-8195-4ec4-90da-5f4e29a4083f',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fstorm-valley-bush-tunnel.kml?alt=media&token=de36bd5d-c4f4-4ccb-b3bb-8ea84cd51884',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Ftims-g-spot.kml?alt=media&token=b2c246e8-1c4e-46ff-b70b-e4586df98e9e',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fto-storm-valley.kml?alt=media&token=4c59ed69-1985-40cc-a45d-776eff68caa8',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fvaughans-revenge.kml?alt=media&token=9e140883-0c15-4a2d-bdbf-cb074a7fb960',
            // 'https://firebasestorage.googleapis.com/v0/b/mymto-9a978.appspot.com/o/KMLS%2Flowveld%2Fzig-zag-to-hell.kml?alt=media&token=86bfad0a-db59-40e5-bb5a-d6c437074005',
        ],
        gallery : GalleryImagesSource.lowveld,
        motQoute:{
            qoute:'"It is the unknown around the corner that turns my wheels."',
            auther:"Heinz Stucke",
            backgroundImgUrl: motBackgroundImgLocation + "lowveld-motivational-qoute-bg.jpg",
        },
        socialLinks:{
            facebook:"https://www.facebook.com/mtotrailslowveld/",
            instagram:"https://www.instagram.com/mtotrails_lowveld/",
        }
    },
]