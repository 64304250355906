import { Injectable } from "@angular/core";
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase, onValue, ref } from "firebase/database";
import { GlobalConfig } from "../configuration/global.config";


@Injectable({
  providedIn: 'root'
})

export class FirebaseService {

    defaultApp: FirebaseApp;
    database  : Database;

    public initializeApp() : void { 
        this.defaultApp = initializeApp(GlobalConfig.Firebase.firebaseEnvironment);
        this.database = getDatabase(this.defaultApp);
    }

    get app() { 
        return this.defaultApp;
    }

    public async getValue(path) : Promise<{path : string, data : any}> {
        return new Promise<any>((resolve,reject)=>{
            onValue(ref(this.database,path), (data)=>{
                resolve({
                    path : path,
                    data : data.val()
                })
            });
        });
  
    }

}
