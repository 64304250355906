import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NewsDataToFriendly'
})
export class NewsDataToFriendlyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value.replace(/<figure>(.*?)<\/figure>/g, '');// replace any figure
    return value.replace(/<.*?>/g, ''); // replace tags
  }
}
