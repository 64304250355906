import { Component, Input, OnInit } from '@angular/core';
import { Wordpress } from 'src/app/domains/content/wordpress';
import { NewsService } from 'src/app/library/services/resources/news/news.service';
import { WordpressService } from 'src/app/library/services/resources/wordpress/wordpress.service';
import { StateService } from 'src/app/state/state.service';

@Component({
  selector: 'news-latest',
  templateUrl: './news-latest.component.html',
  styleUrls: ['./news-latest.component.scss']
})
export class NewsLatestComponent implements OnInit {

  @Input() NoArticleToShow:number = 3
  public articles: Wordpress[];
  
  constructor(public newsService: NewsService, public stateService: StateService, public wordpressService: WordpressService) 
  { 

  }

  ngOnInit() {
    this.fetchNews();
  }


  public async getFeaturedImage(id: number) {
    return await this.wordpressService.getMediaItem(id);
  }

  async fetchNews() {

      this.stateService.news$.subscribe(data => {
        if (data) {
          data = data.map(e => {
            if (e.featured_media) {
              this.getFeaturedImage(e.featured_media)
                .then(data => {
                  e.featured_media_url = data.source_url;
                });
            }
            return e;
          });

          this.articles = data.slice(0, this.NoArticleToShow);
          console.log(data)

        }
      });
  };

}
