import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PipesModule } from './pipes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialModule } from './material.module';
import { NumberOnly } from '../../directives/number-only';
import { NgVarDirective } from '../../directives/ng-var.directive';
import { FirebaseModule } from './firebase.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
    return player;
  }

  

@NgModule({
    imports: [
        PipesModule,
        MatProgressSpinnerModule,
        SlickCarouselModule,
        CommonModule,
        MaterialModule,
        FirebaseModule,
        [LottieModule.forRoot({ player: playerFactory })],
    ],
    declarations: [
        NumberOnly,
        NgVarDirective,
    ],
    exports: [
        
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzPopoverModule,
        PipesModule,
        SlickCarouselModule,
        MaterialModule,
        FirebaseModule,
        NumberOnly,
        NgVarDirective,
        [LottieModule.forRoot({ player: playerFactory })]

    ]
})
export class SharedModule { }