import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Site } from 'src/app/library/interfaces/resources/sites/site';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
  }

  public openLink(link: string, newTab: boolean = false) {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      this.router.navigateByUrl(link);
    }
  }
}
