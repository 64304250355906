<div class="splash-section section">
    <hiking-trails-splash></hiking-trails-splash>
</div>
<div class="overview-section section">
    <hiking-trails-overview></hiking-trails-overview>
</div>
<div class="overview-section section">
    <app-trails-beak-down></app-trails-beak-down>
</div>
<div class="reservation-section">
    <section class="more-info container">
        <div class="heading">
            <i class="material-icons">circle</i>
            <p class="">Reservation Details</p>
        </div>

        <div class="text-container">
            <div class="_text">
                <p>
                    The <b>Tsitiskamma hiking trail</b> is a moderately difficult trail with varying landscapes and is is designed to showcase all the beauty of the region.
                    <br> The trail crosses the Tsitsikamma Mountain range to and from 5 perfectly placed huts , each with a distintive settings. Being it on the beachfront, an ancient river gorges or nestled in the Afromontane forest each hut allows for
                    a unique relaxing atmosphere.
                    <br> All Hut locations are fitted with undercover cooking facilities , showers, toilets, running water and various sleeping options in a bunk bed format. Each hut is stocked with firewood (except Kalander hut) for cooking and heating
                    water.
                    <br>
                    <br>
                    <b>Book now to enjoy to start your adventure.</b>
                    <br> Make a reservation through our online booking service or contact our booking office here. Shorter variations (2,3,4 or 5 days) are possible as each of the five overnight huts has its own unique access route with safe parking at
                    the start. Optional equipment porterage (slackpacking) now provides hikers with the chance of having their equipment transported between overnight huts (30kg per hiker per day),allowing the luxury of carrying only daypacks on each
                    day’s journey. This makes the trail accessible to a wider range of people and provides all with more time for swimming, photography, birding and other activities usually limited by time or weight constraints. Field guides and binoculars
                    are definitely recommended along this exciting and unpredictable journey. Many of the forest giants are labelled with their tree names and hikers can have much fun with guessing and identification games along the route.
                    <br>
                </p>
            </div>
            <div class="_text">
                <p>
                    <b> Book now to enjoy to start your adventure.</b> A reservation can be made through our online booking service or contact our booking office on 0871582110 or email us to info@mtoecotourism.co.za here.
                    <br>
                    <br>
                    <b>Tariffs</b>
                    <br> Overnight: From R295 per person per day. A maximum of 24 people per night with shared accomodation.
                    <br> DAY HIKE: R50,00 per person (no accomodation)
                    <br> Porterage: From R220 per person per trip for the first 5 pax after which it is R205 per person per trip.
                    <br>
                    <br>
                    <b>Deposits for eft payments</b> : (when booking via email)
                    <br> • A hiker deposit of 50% of the total cost of the reservation must be made within 24 hours of receiving the booking confirmation and the remainder to be paid within 2 weeks.
                    <br> • Reservations made on short notice will require the full amount to be paid within 24 hours of receiving the booking confirmation.
                </p>
            </div>
        </div>
    </section>
    <section class="two-section">
        <div class="section_1 section_c">
            <div class="content_container">
                <h1 class="title">Customize your hike</h1>
                <p>Lacking the time for the full hike, or looking to extend your day? We’ve got you. Customise the hike to suit your needs.</p>
            </div>
            <div [routerLink]="['/customize']" class="button_bottom">Customize your hike</div>
        </div>
        <div class="section_2 section_c">
            <div class="content_container">
                <h1 class="title">Trail info</h1>
                <p>
                    All the information you need, be it about our wonderful slackpacking option, hike tips or maps, have a look here.
                </p>
            </div>
            <div [routerLink]="['/trailinfo']" class="button_bottom">View</div>
        </div>
    </section>
</div>
<div class="sponsor-section section">
    <sponsors></sponsors>
</div>