<div class="wrapper container">
    <div class="inner">
        <div class="section-heading heading">
            <i class="material-icons">circle</i>
            <p class="">Upcoming Events</p>
        </div>
        <mat-grid-list class="events-container" [cols]="eventGridColSpan" (window:resize)="resiseGrid()" gutter="0">
            <mat-grid-tile *ngFor="let event of events">
                <div class="event-wrapper" >
                    <div class="img-wrapper">
                        <div *ngIf="event.featuredImage else optionTwo " class="event-img-bg" [ngStyle]="{'background-image':'url('+ event.featuredImage+')'}">
            
                        </div>
                        <ng-template #optionTwo>
                            <div *ngIf="event.media[0] else pholder " class="event-img-bg" [ngStyle]="{'background-image':'url('+ event.media[0].url +')'}">
                        
                            </div>
                        </ng-template>        
                        <ng-template #pholder>
                            <div class="event-img-bg" style="background-image: url('../../../../../assets/images/mto-trails-kogo.png');">
                
                            </div>
                        </ng-template>
                    </div>
                    <div class="info-wrapper">
                        <div class="event-info">
                            <p class="date">{{event.startDate | date:'dd LLLL yyyy'}}</p>
                            <p class="title">{{event.name}}</p>
                            <p class="price">{{event.site.name}}</p>
                            <p class="action" [routerLink]="['/event', event.id]">read more</p>
                        </div>
                    </div>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>