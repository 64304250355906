import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/library/services/authentication/authentication.service';
import { UialertsService } from 'src/app/library/services/ui/uialerts.service';
import { UIAlertType } from 'src/app/library/utilities/enums/global-enums';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService, private alertService: UialertsService, private ngZone: NgZone,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  public async googleLogin() {
    await this.authenticationService.googleLogin()
      .then((authed) => {
        if (authed) {
          let redirectTo = this.route.snapshot.queryParams.redirectTo;
          let url = redirectTo ? redirectTo : '';
          this.router.navigate([url]);
          this.alertService.openSnackBar({ duration: 5, message: "Successfully Logged In", mode: UIAlertType.success });
        } else {
          this.alertService.openSnackBar({ duration: 5, message: "Something went wrong!", mode: UIAlertType.error });
        }
      }).catch((error) => {
        console.log(error);
        this.alertService.openSnackBar({ duration: 5, message: "Something went wrong!", mode: UIAlertType.error });
      });
  }

  public async facebookLogin() {
    await this.authenticationService.facebookLogin()
      .then((authed) => {
        if (authed) {
          let redirectTo = this.route.snapshot.queryParams.redirectTo;
          let url = redirectTo ? redirectTo : '';
          this.router.navigate([url]);
          this.alertService.openSnackBar({ duration: 5, message: "Successfully Logged In", mode: UIAlertType.success });
        } else {
          this.alertService.openSnackBar({ duration: 5, message: "Something went wrong!", mode: UIAlertType.error });
        }
      }).catch((error) => {
        console.log(error);
        this.alertService.openSnackBar({ duration: 5, message: "Something went wrong!", mode: UIAlertType.error });
      });
  }

}
