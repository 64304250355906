<div class="main-landing">
    <landing-splash></landing-splash>
</div>
<!-- About -->
<div class="about-section section">
    <landing-about></landing-about>
</div>
<!-- Site Locations -->
<div id='locations-container' class="eco_sites-section section">
    <lacations-splash></lacations-splash>
</div>
<!-- MTO App -->
<div class="discover-section section">
    <divider [direction]="'down'" [underNavigation]="'no'"></divider>
    <discover-splash></discover-splash>
</div>
<!-- Events -->
<div class="events-section section">
    <events-splash></events-splash>
</div>
<!-- News -->
<div class="news-section section">
    <news-latest></news-latest>
</div>
<!-- Stay-in-touch -->
<div class="stay-in-touch-section section">
    <stay-in-touch></stay-in-touch>
</div>
<!-- Sponsors -->
<div class="sponsors-section section">
    <sponsors pageName="Home"></sponsors>
</div>