<div class="splash-section section">
    <mountain-biking-trails-splash></mountain-biking-trails-splash>
</div>

<section class="div-cool">

</section>

<div class="overview">
    <mountain-biking-trails-overview [site]="this.route.snapshot.paramMap.get('id')"></mountain-biking-trails-overview>
</div>

<div class="overview">
    <app-trails></app-trails>
</div>
<div class="gallery-section section">
    <div class="container">
        <div class="heading section-heading" routerLink="/gallery">
            <i class="material-icons">circle</i>
            <p class="">{{name}} Gallery</p>
        </div>
    </div>
    <gallery-images-grid [images]="images"></gallery-images-grid>
</div>
<div class="stay-in-touch-section section">
    <stay-in-touch></stay-in-touch>
</div>
<div class="sponsors-section section">
    <sponsors></sponsors>
</div>