<div class="splash-section section">
    <customize-splash></customize-splash>
</div>
<divider class="divider" [direction]="'up'" [underNavigation]="'no'"></divider>
<div class="container">
    <div class="para">
        <span>
            Don’t have time to complete the hike in one go? Or can’t get availability over the period you were looking for. 
            Don’t worry, you can adjust the hike to suit your itinerary. 
            We have created varying options for the hiking trail to accommodate you so that you can complete the hike in your own time. 
            For further variations, you can contact us <a routerLink="/contact-us" style="color: #37928c !important; text-decoration: none;">here</a> 
        </span>
    </div>
    <div class="content-customize">
        <div class="left-section" >
            <div id="map"></div>
            <div id="capture"></div>
        </div>
        <div class="right-section">
            <div class="container">
                <p>How many days would you like to hike?</p>
                <div class="full-width">
                    <mat-form-field appearance="outline">
                        <mat-label>Days</mat-label>
                        <input [formControl]="days" type="number" min="2" max="6" matInput>
                    </mat-form-field>
                    <p class="guide-text">Enter minimum of 2 days. and maximum of 6 days.</p>
                </div>
                <mat-tab-group animationDuration="100ms" mat-align-tabs="start">
                    <mat-tab *ngFor="let trail of trails" [label]="trail.days + ' Day'">
                        <div class="tab-content" (click)="viewTrailOnMap(trail)">
                                <br>
                                <br>
                            <h4 class="mat-title" ><strong>{{trail.name}}</strong></h4>
                            <div>
                                <div class="info-card">
                                    <div class="small-info">
                                        <img class="tile-icon" src="../../../../../assets/images/icons/new/Distance.svg">
                                        <div class="info">
                                            <p><strong>Duration</strong></p>
                                            <div class="value">{{trail.days}} Days</div>
                                        </div>
                                    </div>
                                    <div class="small-info end-info">
                                        <img class="tile-icon" src="../../../../../assets/images/icons/new/Duration.svg">
                                        <div class="info">
                                            <p><strong>Difficulty</strong></p>
                                            <div class="value">{{trail.difficulty.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="trail-disc" [innerHtml]="trail.description"  [ngClass]="{'limitTextHeight': limitRead}">
                                
                            </div>
                            <!-- <code>
                                {{trail.description}}
                            </code> -->
                            <br>
                            <div style="width: 100%; display: flex; justify-content: end;">
                                <button mat-flat-buttontype="button" (click)="showText()" style="background-color: #979e9d !important; margin-right: 1em;">
                                    {{ limitRead ? 'Read More': 'Read Less' }}
                                </button>
                                <button mat-flat-button (click)="viewTrailOnMap(trail)" style="background-color: #6ecac5 !important; margin-right: 1em;" [disabled]="!trail" class="std-btn">VIEW TRAIL</button>
                                <!--<button mat-flat-button [routerLink]="['/checkout',trail.id,'booking']" [disabled]="!trail" class="std-btn">BOOK NOW</button>-->
                                <button mat-flat-button (click)="bookNow(trail)" [disabled]="!trail" class="std-btn">BOOK NOW</button>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                </div>
            </div>
    </div>
</div>
