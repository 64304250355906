import { Component, Input, OnInit } from '@angular/core';
import { SITES_STATIC } from 'src/app/library/static/sites';

declare const google; 


@Component({
  selector: 'mountain-biking-trails-overview',
  templateUrl: './mountain-biking-trails-overviewh.component.html',
  styleUrls: ['./mountain-biking-trails-overviewh.component.scss']
})



export class MountainBikingTrailsOverviewhComponent implements OnInit {

  @Input() site;
  localSite;

  map;
  showKMLContent : boolean = false;

  constructor() { 


  }


  initMap() {

    this.map = new google.maps.Map(document.getElementById('map'), {
      center: {lat:-27.384622, lng: 26.648436},
      zoom: 8,
      mapTypeId: 'hybrid',
      gestureHandling: "cooperative"
    });


    
    this.localSite.kmls.forEach((kml)=>{
      var kmlLayer = new google.maps.KmlLayer(kml, {
        // suppressInfoWindows: true,
        // preserveViewport: false,
        map: this.map
      });

      kmlLayer.addListener('click', function(event) {
        // alert('kml clicked');
        this.showKMLContent = true;
        var content = event.featureData.infoWindowHtml;
        var testimonial = document.getElementById('capture');
        testimonial.innerHTML = content;
      });

    })



  }


  ngOnInit(): void {
    if(this.site){
      this.localSite = (SITES_STATIC.filter(x => x.id == this.site))[0];
    }
    this.initMap();
  }

}
