import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hiking-trails',
  templateUrl: './hiking-trails.component.html',
  styleUrls: ['./hiking-trails.component.scss']
})
export class HikingTrailsComponent implements OnInit {

  site:string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  
  }

}
