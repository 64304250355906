import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hiking-trails-faqs',
  templateUrl: './hiking-trails-faqs.component.html',
  styleUrls: ['./hiking-trails-faqs.component.scss']
})
export class HikingTrailsFaqsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
