<div class="splash-container">
    <div [ngStyle]="{'background-image': 'url(' + staticSite.image + ')'}" class="splash-img-container">
    </div>
    <div class="splash-content">
        <div class="content-container">
            <div class="content">
                <h1 class="text-xl-h">
                    {{site?.name}}
                </h1>
                <p class="text-xl-p">
                    {{site?.description}}
                </p>
            </div>
        </div>
        <div class="divider">
            <divider [direction]="'up'" [underNavigation]="'no'"></divider>
        </div>
    </div>
</div>