<div class="wrapper">
    <div class="inner container">
        <h5>Filters</h5>
        <div class="category-select">
            <a class="option">MTB</a>
            <a class="option active">Trail Running</a>
            <a class="option">Hiking</a>
            <a class="option">Accessories</a>
        </div>
        <div class="merch-list">
            <merchandise-item class="item"></merchandise-item>
        </div>
    </div>
    <ng-template #loader>
        <loader></loader>
    </ng-template>
</div>